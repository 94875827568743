/*
 *
 */

import reduxStoreConfigSlice from "./slice";

const _actions = reduxStoreConfigSlice.actions;

/**
 * Sets the config information of the application.
 *
 * @param {InAppModels.Redux.Config} info
 */
export const setConfigRedux = (info) => (dispatch) => dispatch(_actions.setConfig(info));
