/*
 *
 */

import React, {useLayoutEffect, useMemo, useState} from "react";
import {VideoCardTypes} from "../../../../core/constants/enums";
import useIsMounted from "../../../hooks/use-is-mounted";
import AppRoutes from "../../../../core/models/static/routes/app-routes";
import MediumVideoCard from "./types/medium";
import SmallVideoCard from "./types/small";
import {BizLearnApi} from "../../../../core/services/api";

const VideoCard = ({
                       loading,
                       type = VideoCardTypes.medium,
                       data: dataProps,
                       className = '',
                       onIsSavedToggled,
                       pure = false
                   }) => {
    const [data, setData] = useState({});
    const isMounted = useIsMounted();
    const link = useMemo(() => AppRoutes.generateRoute(
            data?.courseId
                ? AppRoutes.private.courses.single.video
                : AppRoutes.private.videos.single,
            {
                id: data?.id ?? '',
                ...(
                    data?.courseId
                        ? {courseId: data?.courseId ?? ''}
                        : {}
                )
            }
        ),
        [data])

    /**
     * With each change in dataProps:
     * - injects a saving property to the inner state only if the new dataProp does not have the same id.
     */
    useLayoutEffect(() => {
        if (data?.id === dataProps?.id && !pure) {
            return;
        }
        setData({
            ...(dataProps ?? []),
            saving: false,
        })
    }, [dataProps])

    /**
     * Toggles the saved state of this video.
     *
     * @param {Event} e the event associated with the click
     * @return {Promise<void>}
     */
    const toggleSaved = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        /**@type {BizLearnApiRequestModels.Video.ToggleIsSaved}*/
        const forApi = {
            videoId: data?.id,
        }
        const forApiExtra = {
            showSuccessToast: true,
        }
        setData(prevState => ({...prevState, saving: true}));
        const response = await BizLearnApi.toggleVideosIsSaved(forApi, forApiExtra);
        if (!isMounted()) return;
        if (response?.resultFlag) {
            setData(prevState => ({...prevState, isSaved: !prevState.isSaved}));
            if (onIsSavedToggled) onIsSavedToggled({...data, isSaved: !data.isSaved});
        }
        if (!isMounted()) return;
        setData(prevState => ({...prevState, saving: false}));
    }

    /**
     * Renders the content based on the type.
     * @return {JSX.Element}
     */
    const renderCard = () => {
        switch (type) {
            case VideoCardTypes.small:
                return <SmallVideoCard
                    className={className}
                    loading={loading}
                    data={data}
                    to={link}
                    toggleSaved={toggleSaved}
                />
            case VideoCardTypes.medium:
                return <MediumVideoCard
                    className={className}
                    loading={loading}
                    data={data}
                    to={link}
                    toggleSaved={toggleSaved}
                />
            default:
                return <></>;
        }
    }

    return (
        <>
            {renderCard()}
        </>
    );
}

export default VideoCard;
