/*
 *
 */

import {createSlice} from "@reduxjs/toolkit";
import ReduxStoreRoutes from "../../../core/models/static/routes/redux-store-routes";
import CacheService from "../../../core/services/cache/cache-service";

/**
 * Initial state of the user slice.
 * @type {InAppModels.UserInfo}
 */
export const reduxStoreUserInfoInitialState = {
    firstName: null,
    lastName: null,
    email: null,
    profileImageUrl: null,
}

/**
 * user info slice that exists in the redux store.
 * this reducer is used throughout the application to update and contain the logged-in user information.
 */
const reduxStoreUserInfoSlice = createSlice({
    name: ReduxStoreRoutes.userInfo,
    initialState: reduxStoreUserInfoInitialState,
    reducers: {
        /**
         * Sets information of the user info slice.
         *
         * - updates the cached user information as well.
         * @param state
         * @param action
         */
        setInfo: (state, action) => {
            const {cache = false, info = {}} = action.payload ?? {};
            state = {
                ...state,
                ...Object
                    .entries(info)
                    .map(([key, value]) => {
                        if (Array.isArray(value)) {
                            return [key, value];
                        }
                        if (typeof value === 'object') {
                            return [key, {...value, ...(state[key] ?? {})}]
                        }
                        return [key, value];
                    })
                    .reduce((p, [key, value]) => ({...p, [key]: value}), {}),
            }
            if (cache) {
                CacheService.setUserInformation(state);
            }
            return state;
        },
        /**
         * Removes the information of the user slice.
         *
         * - removes the user information from the cache as well.
         * @param state
         * @param action
         */
        removeInfo: (state, action) => {
            state = reduxStoreUserInfoInitialState;
            CacheService.removeUserInformation();
            return state
        },
    }
})

export default reduxStoreUserInfoSlice;
