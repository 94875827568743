/*
 *
 */

import React from "react";
import CreateVideoNoteForm, {CreateVideoNoteFormKeys} from "../../../../../../components/forms/create-video-note";
import useIsMounted from "../../../../../../hooks/use-is-mounted";
import {Fade, IconButton, Tooltip} from "@mui/material";
import {ReactComponent as ExportIcon} from "../../../../../../../assets/images/export.svg";
import {Col, Row} from "react-bootstrap";
import TryAgain from "../../../../../../components/app-specific/try-again";
import {CreateVideoNoteInputElementId} from "../../../../../../../core/constants/ids";
import VideoNoteCard from "../../../../../../components/app-specific/video-note-card";
import Utils from "../../../../../../../core/services/utils";
import moment from "moment";
import {BizLearnApi} from "../../../../../../../core/services/api";

const VideoInformationViewNotebookSection = ({data, videoPlayer, onNoteCreated, onNoteRemoved, onNoteUpdated}) => {
    const isMounted = useIsMounted();

    /**
     * Creates a note in the server for this video in the server.
     *
     * * if the api call was successful, adds the created note to the data state, otherwise returns the errors to
     * the caller.
     * @param  {Record<string, any>} values
     * @return {Promise<void | Record<string, any>>}
     */
    const createNote = async (values) => {
        /**@type {BizLearnApiRequestModels.Notebook.Create}*/
        const forApi = {
            videoId: data?.id,
            content: values[CreateVideoNoteFormKeys.content],
            videoMark: Utils.toTimestamp(videoPlayer?.current?.timestamp() ?? 0),
        }
        const forApiExtra = {
            showSuccessToast: true,
        }
        const response = await BizLearnApi.createVideoNote(forApi, forApiExtra);
        if (!isMounted()) return;
        if (!response?.resultFlag) {
            return;
        }
        onNoteCreated(response.data);
    }

    /**
     * Updates the given updated note' content for this video in the server.
     *
     * * if the api call was successful, updates the note in the data state as well, otherwise returns the errors to
     * the caller.
     * @param {BizLearnApiResponseModels.Notebook.Note} note
     * @return {Promise<void | Record<string, any>>}
     */
    const updateNote = async (note) => {
        /**@type {BizLearnApiResponseModels.Notebook.Note}*/
        const forApi = note
        const forApiExtra = {
            showSuccessToast: true,
        }
        const response = await BizLearnApi.updateVideoNote(forApi, forApiExtra);
        if (!isMounted()) return;
        if (!response?.resultFlag) {
            return;
        }
        onNoteUpdated(note);
    }

    /**
     * Removes the given note from the video in the server.
     *
     * * if the api call was successful, remove the note from the data state as well
     * @param {BizLearnApiResponseModels.Notebook.Note} note
     * @return {Promise<void>}
     */
    const removeNote = async (note) => {
        const forApiExtra = {
            showSuccessToast: true,
        }
        const response = await BizLearnApi.removeVideoNote(note.id, forApiExtra);
        if (!isMounted()) return;
        if (response?.resultFlag) {
            onNoteRemoved(note);
        }
    }

    /**
     * Exports the notes in a csv format to users' machine.
     */
    const exportNotes = () => {
        const exportTitle = `Notes in ${data.title} @ ${moment(Date.now()).format('DD/MM/yyy hh:mm A')}`
        const toBeExported = data?.notes?.map(note => {
            return {
                'Video Mark': note.videoMark ?? '',
                'Content': note.content ?? '',
                'Submitted at': note.submitDateTime ? moment(note.submitDateTime).format('DD/MM/yyy hh:mm A') : '',
                'Modified at': note.modifiedDateTime ? moment(note.modifiedDateTime).format('DD/MM/yyy hh:mm A') : '',
            }
        });
        Utils.exportCSVFile(Object.keys(toBeExported[0]), toBeExported, exportTitle);
    }

    /**
     * Focuses the input element of a new note and places it in the center of the viewport.
     */
    const bringFormIntoView = () => {
        const inputElement = document.getElementById(CreateVideoNoteInputElementId);
        inputElement.focus();
        inputElement.scrollIntoView({
            block: "center"
        });
    }

    /**
     * Seeks the current video to the specified timestamp.
     * @param {number} timestamp timestamp in seconds.
     */
    const seekVideoToTimestamp = (timestamp) => {
        window.scrollTo(0, 0);
        videoPlayer?.current?.play();
        videoPlayer?.current?.seek(timestamp);
    }

    return (
        <>
            <div className={'notebook'}>
                <CreateVideoNoteForm submit={createNote}/>
                <div className={'notes-title'}>
                    <p>
                        Your Notes
                    </p>
                    <Fade in={!!data?.notes?.length}>
                        <Tooltip title={'Export your notes'}>
                            <IconButton
                                onClick={exportNotes}
                                className={'icon-button'}>
                                <ExportIcon className={'custom'}/>
                            </IconButton>
                        </Tooltip>
                    </Fade>
                </div>
                <Row className={'notes-container'}>
                    {
                        !data?.notes?.length
                            ? <TryAgain
                                text={"This video current does not have a note."}
                                buttonText={'Create a new note'}
                                onClick={bringFormIntoView}
                            />
                            : data?.notes?.map(note => (
                                <Col key={note.id} xs={12} className={'item'}>
                                    <VideoNoteCard
                                        data={note}
                                        update={updateNote}
                                        remove={removeNote}
                                        seekVideoToTimestamp={seekVideoToTimestamp}
                                    />
                                </Col>
                            ))
                    }
                </Row>
            </div>
        </>
    )
}

export default VideoInformationViewNotebookSection;
