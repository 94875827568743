/*
 *
 */

import reduxStoreDialogsSlice from "./slice";

const _actions = reduxStoreDialogsSlice.actions;

/**
 * Sets the state of the chosen dialog in the application.
 *
 * @param {string} type the type of the dialog to set their state of. This prop must be a member of
 * ReduxStoreRoutes.dialogs enum.
 * @param {Partial<
 * InAppModels.Redux.ConfirmationDialogState
 * >} state the new state of the dialog.
 */
export const setReduxDialogState = (type, state) => (dispatch) => dispatch(_actions.setDialogState({type, state}));
