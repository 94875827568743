/*
 *
 */

import React, {forwardRef, useMemo, useState} from "react";
import {TextField as FormTextField} from "mui-rff";
import {IconButton, TextField as MuiTextField} from "@mui/material";
import classnames from "classnames";
import {ReactComponent as EyeIcon} from '../../../../assets/images/password-input/eye.svg'
import {ReactComponent as EyeInvisibleIcon} from '../../../../assets/images/password-input/eye-invisible.svg'
import {InputTypes} from "../../../../core/constants/enums";


/**
 * @param {boolean} form whether this input is a form input or not.
 * @param {boolean} fullWidth
 * @param {string} classNameProp
 * @param {string} typeProp
 * @param {Partial<import("mui-rff").TextFieldProps> & Partial<import("@mui/material").OutlinedTextFieldProps>} props
 * @param {any} ref
 */
const _Input = ({form = false, fullWidth = true, className: classNameProp, type: typeProp, ...props}, ref) => {
    const [show, setShow] = useState(false);
    const isTypePassword = typeProp === InputTypes.password;
    const type = !isTypePassword ? typeProp : !show ? 'password' : 'text';
    const className = classnames(classNameProp, 'input');
    const size = 'medium';

    /**
     * Toggles the password visibility.
     */
    const toggleShow = () => {
        setShow(prevState => !prevState);
    }

    const passwordProps = useMemo(() => ({
        ...(isTypePassword
                ? {
                    InputProps: {
                        endAdornment: (
                            <IconButton className={'password-icon'} onClick={toggleShow}>
                                {!show
                                    ? <EyeIcon/>
                                    : <EyeInvisibleIcon/>
                                }
                            </IconButton>
                        )
                    }
                }
                : {}
        )
    }), [isTypePassword, show])


    return (
        <>
            {
                form
                    ? <FormTextField
                        ref={ref}
                        size={size}
                        className={className}
                        fullWidth={fullWidth}
                        type={type}
                        {...passwordProps}
                        {...props}
                    />
                    : <MuiTextField
                        ref={ref}
                        size={size}
                        className={className}
                        fullWidth={fullWidth}
                        type={type}
                        {...passwordProps}
                        {...props}
                    />
            }
        </>
    )
}

const Input = forwardRef(_Input)

export default Input;
