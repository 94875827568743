/*
 *
 */

import React from "react";
import AuthenticationMiddleware from "./authentication";

/**
 * The aggregator of all the application level middlewares.
 *
 * The middlewares responsibility is to inject behaviour
 * into the views or the entire application without any visible ui components.
 */
const AppMiddlewares = () => {

    return (
        <>
            <AuthenticationMiddleware/>
        </>
    )
}

export default AppMiddlewares;
