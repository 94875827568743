/*
 *
 */

import React, {useEffect, useMemo, useState} from "react";
import ContentSearchSection from "../../../components/search-sections/content";
import useIsMounted from "../../../hooks/use-is-mounted";
import useRouter from "../../../hooks/use-router";
import {ContentQueryParams} from "../../../../core/constants/query-params";
import Utils from "../../../../core/services/utils";
import CourseCard from "../../../components/app-specific/course-card";
import {ContentSearchSectionTypes, CourseCardTypes, VideoCardTypes} from "../../../../core/constants/enums";
import VideoCard from "../../../components/app-specific/video-card";
import {Col, Container, Row} from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroller";
import useWindowViewportWidth from "../../../hooks/use_window-viewport-width";
import TryAgain from "../../../components/app-specific/try-again";
import {BizLearnApi} from "../../../../core/services/api";

const initialPaginationInfo = {
    videosPageSize: 4,
    coursesPageSize: 2,
    currentVideosPage: 0,
    currentCoursesPage: 0,
    totalVideos: 0,
    totalCourses: 0,
}

const SearchView = () => {
    const {query, setQuery} = useRouter();
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState({});
    const [paginationInfo, setPaginationInfo] = useState(initialPaginationInfo);
    const [loading, setLoading] = useState(true);
    const isMounted = useIsMounted();
    const viewportWidth = useWindowViewportWidth();

    const canLoadMore = !loading && (
        (paginationInfo?.coursesPageSize ?? 0) * (paginationInfo?.currentCoursesPage ?? 0) < (paginationInfo?.totalCourses ?? 0) ||
        (paginationInfo?.videosPageSize ?? 0) * (paginationInfo?.currentVideosPage ?? 0) < (paginationInfo?.totalVideos ?? 0)
    )

    const videoCardType = useMemo(() => VideoCardTypes.medium, []);
    const courseCardType = useMemo(() => {
        if (['xs', 'sm', 'md'].includes(viewportWidth)) {
            return CourseCardTypes.medium;
        }
        return CourseCardTypes.large;
    }, [viewportWidth]);

    const courseBreakpoints = useMemo(() => {
        if (courseCardType === CourseCardTypes.large) {
            return {
                xs: 12,
            };
        }
        return {
            xs: 12,
            md: 6,
        }
    }, [courseCardType])
    const videoBreakpoints = useMemo(() => ({
        xs: 12,
        lg: 6,
    }), [])


    /**
     * With each change in query:
     * - checks any changes in the filters, and if there are changes, resets the pagination and data so that the new
     * search fills the data again
     */
    useEffect(() => {
        const filters = hasFiltersChanged();
        if (filters) {
            setPaginationInfo(initialPaginationInfo);
            setData([]);
        }
    }, [query])

    /**
     * With each change in paginationInfo's currentPage:
     * - if currentPage is 0, it indicates that there was a reset in paginationInfo, or we are in the initial
     * render, so we just change the currentPage to 1
     * - otherwise we search
     */
    useEffect(() => {
        if (!paginationInfo?.currentVideosPage || !paginationInfo?.currentCoursesPage) {
            return setPaginationInfo(prevState => ({
                ...prevState,
                currentVideosPage: 1,
                currentCoursesPage: 1
            }));
        }
        search().then();
    }, [paginationInfo?.currentVideosPage, paginationInfo?.currentCoursesPage])

    /**
     * Determines if the filters in the query are different from the saved filters in the state.
     *
     * * in case of any difference, the state is updated and the updated values are then returned.
     * @return {{}}
     */
    const hasFiltersChanged = () => {
        const newFilters = {
            [ContentQueryParams.keywords]: query[ContentQueryParams.keywords],
            [ContentQueryParams.platform]: query[ContentQueryParams.platform],
            [ContentQueryParams.type]: query[ContentQueryParams.type],
        }
        if (!Utils.deepEqual(filters, newFilters)) {
            setFilters(newFilters);
            return newFilters;
        }
    }

    /**
     * Searches among all the content of this system with the provided filters and paginationInfo.
     *
     * - if the result of the api is successful, sorts the retrieved data based on their date, and appends them to
     * the state list. Also updates the total contents count for pagination purposes.
     */
    const search = async () => {
        /** @type {BizLearnApiRequestModels.MainPage.Search} */
        const forApi = {
            keyword: filters[ContentQueryParams.keywords],
            applicationId: filters[ContentQueryParams.platform],
            type: filters[ContentQueryParams.type],
            pageIndex: Math.max(paginationInfo.currentCoursesPage, paginationInfo.currentVideosPage),
            // TODO: add multiple page sizes in production
            pageSize: paginationInfo.videosPageSize,
        }
        setLoading(true);
        const response = await BizLearnApi.searchContent(forApi);
        if (!isMounted()) return;
        setLoading(false);
        if (response?.resultFlag) {
            setPaginationInfo(prevState => ({
                ...prevState,
                totalCourses: response?.data?.courses?.totalCount ?? 0,
                totalVideos: response?.data?.videos?.totalCount ?? 0,
            }))
            const data = [
                ...(response?.data?.videos?.items?.map((video) => ({
                    Component: VideoCard,
                    date: video?.modifiedDateTime ?? Date.now(),
                    key: `video-${video.id}`,
                    type: 'v',
                    props: {
                        data: {
                            ...video,
                            coverImageUrl: (response?.configuration?.videoCoverBaseURL ?? "") + (video.coverImageUrl ?? "")
                        },
                    },

                })) ?? []),
                ...(response?.data?.courses?.items?.map(course => ({
                    Component: CourseCard,
                    date: course.modifiedDateTime,
                    key: `course-${course.id}`,
                    type: 'c',
                    props: {
                        data: {
                            ...course,
                            coverImageUrl: (response?.configuration?.courseCoverBaseURL ?? "") + (course.coverImageUrl ?? "")
                        },
                    },
                })) ?? [])
            ].sort((a, b) => Utils.dateComparator(b.date, a.date))
            setData(prevState => [...prevState, ...data,]);
        }
    }

    /**
     * Increases the paginationInfo's current page to trigger the search function and to load more data.
     */
    const loadMore = () => {
        if (loading) return;
        setLoading(true);
        setPaginationInfo(prevState => {
            const videosPage = (prevState?.videosPageSize ?? 0) * (prevState?.currentVideosPage ?? 0) < (prevState?.totalVideos ?? 0)
                ? prevState.currentVideosPage + 1
                : prevState.currentVideosPage;
            const coursesPage = (prevState?.coursesPageSize ?? 0) * (prevState?.currentCoursesPage ?? 0) < (prevState?.totalCourses ?? 0)
                ? prevState.currentCoursesPage + 1
                : prevState.currentCoursesPage;
            return {
                ...prevState,
                currentVideosPage: videosPage,
                currentCoursesPage: coursesPage,
            }
        });
    }

    /**
     * Resets the paginationInfo and data so that the search is triggered again.
     */
    const searchAgain = () => {
        setPaginationInfo(initialPaginationInfo);
        setData([]);
    }

    /**
     * Removes the filters from the url queries. This ultimately triggers the search function as well.
     */
    const removeFilters = () => {
        setQuery({}, {replace: true});
    }

    /** @type {ReactNode} */
    const loadingItems = useMemo(() => {
        if (!loading) {
            return <></>;
        }
        return (
            <>
                {Array(initialPaginationInfo.coursesPageSize).fill(null).map((_, e) => (
                    <Col key={`loading-course-${e}`} {...courseBreakpoints}>
                        <CourseCard loading type={courseCardType}/>
                    </Col>
                ))}
                {Array(initialPaginationInfo.videosPageSize).fill(null).map((_, e) => (
                    <Col key={`loading-video-${e}`} {...videoBreakpoints}>
                        <VideoCard loading type={videoCardType}/>
                    </Col>
                ))}
                <div className={'mt-5'}/>
            </>
        );
    }, [loading, courseCardType, videoCardType, courseBreakpoints, videoBreakpoints])

    /** @type {ReactNode} */
    const items = useMemo(() => {
        return (
            <>
                {data?.map(item => (
                    <Col key={item.key}
                         className={'item'}
                         {...(item.type === 'v'
                                 ? videoBreakpoints
                                 : courseBreakpoints
                         )}>
                        <item.Component
                            type={item.type === 'v'
                                ? videoCardType
                                : courseCardType
                            }
                            {...item.props}
                        />
                    </Col>
                ))}
            </>
        );
    }, [data, courseCardType, videoCardType, videoBreakpoints, courseBreakpoints])

    return (
        <>
            <div className={'search-view'}>
                <ContentSearchSection type={ContentSearchSectionTypes.all} filters={filters}/>
                <div className={'spacer'}/>
                <Container>
                    {
                        !loading && !data?.length ?
                            (
                                !Object.keys(query).length
                                    ? <TryAgain
                                        text={'There seems to be no content available'}
                                        buttonText={'Search Again'}
                                        onClick={searchAgain}
                                    />
                                    : <TryAgain
                                        text={'There are no results with the given search filters.'}
                                        buttonText={'Remove Filters'}
                                        onClick={removeFilters}
                                    />
                            )
                            : <>
                                <InfiniteScroll
                                    className='row'
                                    pageStart={1}
                                    loadMore={loadMore}
                                    hasMore={canLoadMore}
                                    useWindow
                                    threshhold={400}>
                                    {items}
                                </InfiniteScroll>
                                <Row>
                                    {loadingItems}
                                </Row>
                            </>
                    }
                </Container>
                <div className={'spacer'}/>
            </div>
        </>
    )
}

export default SearchView;
