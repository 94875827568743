/*
 *
 */

import React, {useEffect} from "react";
import {Navigate, NavigationType, Outlet, Route, Routes} from "react-router-dom";
import AppRoutes from "../../core/models/static/routes/app-routes";
import AccessDeniedErrorView from "./public/error/access-denied";
import NotFoundErrorView from "./public/error/not-found";
import ServerErrorErrorView from "./public/error/server-error";
import ErrorViews from "./public/error";
import AuthViews from "./public/auth";
import PrivateViews from "./private";
import AppFooter from "../components/app-specific/app-footer";
import {AppElementId} from "../../core/constants/ids";
import AuthLoginView from "./public/auth/login";
import AuthRedirectView from "./public/auth/redirect";
import HomeView from "./private/home";
import SearchView from "./private/search";
import useRouter from "../hooks/use-router";
import CacheService from "../../core/services/cache/cache-service";
import CoursesListView from "./private/courses/list";
import VideosListView from "./private/videos/list";
import CourseInformationView from "./private/courses/single";
import VideoInformationView from "./private/videos/single";
import ConfirmationDialog from "../components/dialogs/confirmation-dialog";
import EnvService from "../../core/services/env-service";
import AuthLogoutView from "./public/auth/logout";

const AppViews = () => {
    const {location, navigationType} = useRouter();

    /**
     * As soon as the component mounts:
     * - attaches an unload event listener to window to watch when the user closes the app.
     */
    useEffect(() => {
        window.addEventListener('unload', cacheUrlBeforeExit);
        return () => window.removeEventListener('unload', cacheUrlBeforeExit);
    }, [])

    /**
     * With each change in the location:
     * - if the type of the navigation is Push, then scrolls the window to the top.
     */
    useEffect(() => {
        if (navigationType === NavigationType.Push) {
            window.scrollTo({left: 0, top: 0, behavior: 'auto'});
        }
    }, [location])

    /**
     * Caches the url of the current location so the users can be redirected to it after they login to the app again.
     * @return {Location}
     */
    const cacheUrlBeforeExit = () => {
        CacheService.cacheUrl(location);
    }

    return (
        <>
            <div className={'app-container'}>
                <div id={AppElementId} className={'app'}>
                    <Routes>
                        {/* Public Views */}
                        <Route
                            path={AppRoutes.public.error._base}
                            element={<ErrorViews/>}>
                            <Route
                                path={AppRoutes.public.error._relative.notFound}
                                element={<NotFoundErrorView/>}
                            />
                            <Route
                                path={AppRoutes.public.error._relative.accessDenied}
                                element={<AccessDeniedErrorView/>}
                            />
                            <Route
                                path={AppRoutes.public.error._relative.serverError}
                                element={<ServerErrorErrorView/>}
                            />
                            <Route
                                index
                                path={'*'}
                                element={<Navigate to={AppRoutes.public.error.notFound} replace/>}
                            />
                        </Route>
                        <Route
                            path={AppRoutes.public.auth._base}
                            element={<AuthViews/>}>
                            <Route
                                path={AppRoutes.public.auth._relative.login}
                                element={<AuthLoginView/>}
                            />
                            <Route
                                path={AppRoutes.public.auth._relative.logout}
                                element={
                                    !EnvService.showBiz
                                        ? <AuthRedirectView/>
                                        : <AuthLogoutView/>
                                }
                            />
                            <Route
                                path={AppRoutes.public.auth._relative.redirect}
                                element={<AuthRedirectView/>}
                            />
                            <Route
                                index
                                path={'*'}
                                element={<Navigate to={AppRoutes.public.error._base} replace/>}
                            />
                        </Route>
                        <Route
                            path={AppRoutes.private.landing}
                            element={<></>}
                        />
                        {/* Private Views */}
                        <Route
                            path={AppRoutes.private._base}
                            element={<PrivateViews/>}>
                            <Route
                                path={AppRoutes.private._relative.home}
                                element={<HomeView/>}
                            />
                            <Route
                                path={AppRoutes.private._relative.search}
                                element={<SearchView/>}
                            />
                            <Route
                                path={AppRoutes.private._relative.courses}
                                element={<Outlet/>}>
                                <Route
                                    path={AppRoutes.private.courses._relative.list}
                                    element={<CoursesListView/>}
                                />
                                <Route
                                    path={AppRoutes.private.courses._relative.single.course}
                                    element={<CourseInformationView/>}
                                />
                                <Route
                                    path={AppRoutes.private.courses._relative.single.video}
                                    element={<VideoInformationView/>}
                                />
                            </Route>
                            <Route
                                path={AppRoutes.private._relative.videos}
                                element={<Outlet/>}>
                                <Route
                                    path={AppRoutes.private.videos._relative.list}
                                    element={<VideosListView/>}
                                />
                                <Route
                                    path={AppRoutes.private.videos._relative.single}
                                    element={<VideoInformationView/>}
                                />
                            </Route>
                        </Route>
                        {/* Redirect */}
                        <Route
                            index
                            path={'*'}
                            element={<Navigate to={AppRoutes.public.error._base} replace/>}
                        />
                    </Routes>
                </div>
                <AppFooter/>

                {/* Dialogs  */}
                <ConfirmationDialog/>

            </div>
        </>
    )
}

export default AppViews;
