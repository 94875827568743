/*
 *
 */

import React, {useMemo} from "react";
import {CourseCardTypes} from "../../../../core/constants/enums";
import AppRoutes from "../../../../core/models/static/routes/app-routes";
import MediumCourseCard from "./types/medium";
import LargeCourseCard from "./types/large";

const CourseCard = ({loading, type = CourseCardTypes.medium, data}) => {
    const link = useMemo(() => AppRoutes.generateRoute(
            AppRoutes.private.courses.single.base,
            {courseId: data?.id ?? ''}
        ),
        [data])

    /**
     * Renders the card based on the type.
     * @return {JSX.Element}
     */
    const renderCard = () => {
        switch (type) {
            case CourseCardTypes.medium:
                return <MediumCourseCard
                    to={link}
                    loading={loading}
                    data={data}
                />
            case CourseCardTypes.large:
                return <LargeCourseCard
                    to={link}
                    loading={loading}
                    data={data}
                />
            default:
                return <></>;
        }
    }

    return (
        <>
            {renderCard()}
        </>
    );
}


export default CourseCard;
