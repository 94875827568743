/*
 *
 */

/**
 * ID of the div with the classname 'app'.
 */
export const AppElementId = 'app'

/**
 * ID of the input that is responsible for creating a new note for a video.
 */
export const CreateVideoNoteInputElementId = 'create-video-note-input';
