/*
 *
 */

import React, {useEffect, useMemo, useState} from "react";
import * as Yup from 'yup';
import ValidateMessages from "../../../../core/models/static/validate-messages";
import {makeRequired, makeValidate} from "mui-rff";
import {ContentSearchSectionTypes} from "../../../../core/constants/enums";
import {ContentQueryParams, CoursesQueryParams, VideosQueryParams} from "../../../../core/constants/query-params";
import {Col, Container, Row} from "react-bootstrap";
import useIsMounted from "../../../hooks/use-is-mounted";
import useRouter from "../../../hooks/use-router";
import AppRoutes from "../../../../core/models/static/routes/app-routes";
import useWindowViewportWidth from "../../../hooks/use_window-viewport-width";
import {ReactComponent as SearchEitherRightSvg} from "../../../../assets/images/art-like/search-either-right.svg";
import {ReactComponent as SearchAllRightSvg} from "../../../../assets/images/art-like/search-all-right.svg";
import ContentSearchSectionAllForm from "../../forms/content-search-section";
import ContentSearchSectionCoursesForm from "../../forms/courses-search-section";
import ContentSearchSectionVideosForm from "../../forms/videos-search-section";
import classnames from "classnames";
import {BizLearnApi} from "../../../../core/services/api";

/**
 * All the available form keys for content search section.
 */
export const ContentSearchFormKeys = {
    inProgressOnly: 'in-progress-only',
    savedOnly: 'saved-only',
    keywords: 'keywords',
    platform: 'platform',
    type: 'type',
}

const schema = Yup.object().shape({
    [ContentSearchFormKeys.keywords]: Yup.string().when(ContentSearchFormKeys.keywords, {
        is: (value) => !value,
        then: Yup.string().nullable(),
        otherwise: Yup.string().nullable().min(3, ValidateMessages.min('3')),
    }),
    [ContentSearchFormKeys.inProgressOnly]: Yup.boolean().nullable().default(false),
    [ContentSearchFormKeys.savedOnly]: Yup.boolean().nullable().default(false),
    [ContentSearchFormKeys.type]: Yup.string().nullable().typeError(ValidateMessages.incorrectType('type')),
    [ContentSearchFormKeys.platform]: Yup.string().nullable().typeError(ValidateMessages.incorrectType('platform')),
}, [ContentSearchFormKeys.keywords, ContentSearchFormKeys.keywords])

const validate = makeValidate(schema);
const required = makeRequired(schema);

const ContentSearchSection = ({filters, type}) => {
    const {navigate} = useRouter();
    const [formInitialValues, setFormInitialValues] = useState();
    const [platforms, setPlatforms] = useState([]);
    const isMounted = useIsMounted();
    const viewportWidth = useWindowViewportWidth();

    const showRightSvg = !['xs', 'sm', 'md'].includes(viewportWidth);

    /**
     * As soon as the component mounts:
     * - fetches the available platforms from the server.
     */
    useEffect(() => {
        getFormData().then();
    }, [])

    /**
     * With each change in type or filters:
     * - sets the initial values of the form based on the current type and the given filters.
     */
    useEffect(() => {
        switch (type) {
            case ContentSearchSectionTypes.courses:
                setFormInitialValues({
                    [ContentSearchFormKeys.keywords]: filters[CoursesQueryParams.keywords],
                    [ContentSearchFormKeys.platform]: filters[CoursesQueryParams.platform],
                });
                break;
            case ContentSearchSectionTypes.videos:
                setFormInitialValues({
                    [ContentSearchFormKeys.keywords]: filters[VideosQueryParams.keywords],
                    [ContentSearchFormKeys.platform]: filters[VideosQueryParams.platform],
                    [ContentSearchFormKeys.inProgressOnly]: filters[VideosQueryParams.inProgressOnly],
                    [ContentSearchFormKeys.savedOnly]: filters[VideosQueryParams.savedOnly],
                });
                break;
            case ContentSearchSectionTypes.all:
            default:
                setFormInitialValues({
                    [ContentSearchFormKeys.keywords]: filters[ContentQueryParams.keywords],
                    [ContentSearchFormKeys.platform]: filters[ContentQueryParams.platform],
                    [ContentSearchFormKeys.type]: filters[ContentQueryParams.type],
                });
                break;
        }
    }, [filters, type])

    /**
     * Fetches the data needed for the forms to use from the server.
     *
     * * the data are the available types and platforms in the system.
     * * if the result of the api is successful, saves the data into their respective inner states.
     */
    const getFormData = async () => {
        const response = await BizLearnApi.getApplications();
        if (!isMounted()) return;
        if (response?.resultFlag) {
            setPlatforms(response?.list ?? []);
        }
    }

    /**
     * Submits the given query in the url.
     *
     * * the query is created by converting the form values to search param values for the specific form type.
     * @param {Record<string, any>} query
     */
    const submitForm = (query) => {
        navigate(getNavigationRoute(), undefined, query);
    }

    /**
     * Fetches the navigation route for the form submission based on the type.
     * @return {string}
     */
    const getNavigationRoute = () => {
        switch (type) {
            case ContentSearchSectionTypes.videos:
                return AppRoutes.private.videos.list;
            case ContentSearchSectionTypes.courses:
                return AppRoutes.private.courses.list;
            case ContentSearchSectionTypes.all:
            default:
                return AppRoutes.private.search;
        }
    }

    /**
     * renders the appropriate search section form based on the type.
     * @return {JSX.Element}
     */
    const renderSearchSectionForm = () => {
        switch (type) {
            case ContentSearchSectionTypes.videos:
                return <ContentSearchSectionVideosForm
                    validate={validate}
                    required={required}
                    initialValues={formInitialValues}
                    platforms={platforms}
                    submit={submitForm}
                />
            case ContentSearchSectionTypes.courses:
                return <ContentSearchSectionCoursesForm
                    validate={validate}
                    required={required}
                    initialValues={formInitialValues}
                    platforms={platforms}
                    submit={submitForm}
                />
            case ContentSearchSectionTypes.all:
            default:
                return <ContentSearchSectionAllForm
                    validate={validate}
                    required={required}
                    initialValues={formInitialValues}
                    platforms={platforms}
                    submit={submitForm}
                />
        }
    }

    /**@type {ReactNode} */
    const title = useMemo(() => {
        switch (type) {
            case ContentSearchSectionTypes.videos:
                if (filters[VideosQueryParams.savedOnly]) {
                    return "Saved Videos";
                }
                if (filters[VideosQueryParams.inProgressOnly]) {
                    return "In Progress Videos";
                }
                return "Search Videos";
            case ContentSearchSectionTypes.courses:
                return "Search Courses";
            case ContentSearchSectionTypes.all:
            default:
                return "Search Results";
        }
    }, [type, filters])
    /**@type {ReactNode} */
    const svg = useMemo(() => {
        switch (type) {
            case ContentSearchSectionTypes.videos:
            case ContentSearchSectionTypes.courses:
                return <SearchEitherRightSvg className={'right-svg'}/>;
            case ContentSearchSectionTypes.all:
            default:
                return <SearchAllRightSvg className={'right-svg'}/>;
        }
    }, [type])

    return (
        <>
            <div className={'content-search-section'}>
                <Container>
                    <Row>
                        <Col xs={12} lg={8} xl={7}>
                            <p className={'title'}>
                                {title}
                            </p>
                            {renderSearchSectionForm()}
                            <div className={'spacer'}/>
                        </Col>
                        {
                            showRightSvg &&
                            <Col lg={4} xl={5} className={classnames('svg-container', {
                                'all': type === ContentSearchSectionTypes.all
                            })}>
                                {svg}
                            </Col>
                        }
                    </Row>
                </Container>
            </div>
        </>
    )
}


export default ContentSearchSection;
