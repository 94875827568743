/*
 *
 */

import React from "react";
import {Col, Row} from "react-bootstrap";
import Input from "../../base/input";
import {InputTypes} from "../../../../core/constants/enums";
import {LoadingButton} from "@mui/lab";
import Form from "../../base/form";
import * as Yup from "yup";
import ValidateMessages from "../../../../core/models/static/validate-messages";
import {makeRequired, makeValidate} from "mui-rff";
import {CreateVideoNoteInputElementId} from "../../../../core/constants/ids";

/**
 * The form keys for submitting a new video.
 * @type {{content: string}}
 */
export const CreateVideoNoteFormKeys = {
    content: 'content',
}

const schema = Yup.object().shape({
    [CreateVideoNoteFormKeys.content]: Yup.string().nullable().required(ValidateMessages.required).min(3, ValidateMessages.min('3')),
})

const validate = makeValidate(schema);
const required = makeRequired(schema);

const CreateVideoNoteForm = ({submit}) => {

    /**
     * Invokes the "submit" callback with the given values.
     *
     * @param {Record<string, any>} values
     * @param {formApi} form
     * @return {Promise<Record<string, any>> | void}
     */
    const onSubmit = async (values, form) => {
        if (!values[CreateVideoNoteFormKeys.content].trim()) {
            return Promise.resolve({
                [CreateVideoNoteFormKeys.content]: ValidateMessages.required,
            })
        }
        const errors = await submit(values);
        if (!errors) {
            form.restart();
            return Promise.resolve();
        }
        return Promise.resolve(errors)
    }

    return (
        <>
            <Form
                onSubmit={onSubmit}
                className={'create-video-note-form'}
                validate={validate}
                initialValues={{}}>
                {({submitting}) => (
                    <Row>
                        <Col xs={12}>
                            <Input
                                form
                                name={CreateVideoNoteFormKeys.content}
                                type={InputTypes.text}
                                multiline
                                minRows={5}
                                required={required[CreateVideoNoteFormKeys.content]}
                                label={"New Note"}
                                id={CreateVideoNoteInputElementId}
                            />
                        </Col>
                        <Col xs={12} className={'mt-2 d-flex flex-column align-items-end'}>
                            <LoadingButton
                                loading={submitting}
                                color={'secondary'}
                                type={'submit'}
                                className={'button'}
                                loadingIndicator={submitting ? "Creating..." : undefined}>
                                Create
                            </LoadingButton>
                        </Col>
                    </Row>
                )}
            </Form>
        </>
    )
}

export default CreateVideoNoteForm;
