/*
 *
 */

import ApiPoolExecutor from "../../../helpers/pool-executor";
import BizLearnApiExecutor from "../executor";
import BaseApiMixin from "../../base/mixins/base";

/**
 * This interface provides the decomposed and componentLike apis that are available in the BizLearn server.
 */
class BizLearnBaseApiMixin extends BaseApiMixin {
    /**
     * The Teacher Assessment PoolExecutor that runs the api calls for this mixin.
     * @protected
     */
    protected static readonly poolExecutor = ApiPoolExecutor.of(new BizLearnApiExecutor());
}

export default BizLearnBaseApiMixin;
