/*
 *
 */

import React, {useLayoutEffect, useState} from "react";
import {ReactComponent as PrimaryLogo} from '../../../../assets/images/logo/primary.svg'
import {ReactComponent as SecondaryLogo} from '../../../../assets/images/logo/seconary.svg'
import {LogoTypes} from "../../../../core/constants/enums";
import classnames from "classnames";
import {Fade} from "@mui/material";
import useIsMounted from "../../../hooks/use-is-mounted";
import useColorTheme from "../../../hooks/use-color-theme";

const AppLogo = ({type, className, animate = false, animationDuration = 0}) => {
    const [show, setShow] = useState(!animate);
    const [theme] = useColorTheme();
    const isMounted = useIsMounted();

    /**
     * As soon as the component renders:
     * - sets a timer for the animation duration, at the end of which, the icon becomes visible.
     */
    useLayoutEffect(() => {
        const timer = setTimeout(() => {
            if (!isMounted()) return;
            setShow(true)
        }, animationDuration)
        return () => clearTimeout(timer);
    }, [])

    /**
     * Renders the Logo based on its type and theme
     * @return {JSX.Element}
     */
    const renderLogo = () => {
        switch (type) {
            case LogoTypes.primary:
            default:
                return <PrimaryLogo/>;
            case LogoTypes.secondary:
                return <SecondaryLogo/>;

        }
    }

    return (
        <>
            <div className={classnames('logo', theme, className)}>
                <Fade in={!!show} mountOnEnter unmountOnExit>
                    <div>
                        {renderLogo()}
                    </div>
                </Fade>
            </div>
        </>
    );
}

export default AppLogo;
