/*
 *
 */

/**
 * The interface that is used to keep the variables used in the .env file or any env related functionality.
 */
class EnvService {

    /**
     * The Public Url of the application.
     */
    static PublicUrl: string = process.env.PUBLIC_URL ?? "";

    /**
     * The Public token for authentication.
     */
    static PublicToken: string = process.env.REACT_APP_PUBLIC_TOKEN ?? "";

    /**
     * The url used to navigate users to the admin login url page.
     */
    static AdminLoginUrl: string = process.env.REACT_APP_ADMIN_LOGIN_URL ?? "";

    /**
     *  The base url of our api
     */
    static BizLearnApiUrl: string = process.env.REACT_APP_BIZ_LEARN_API_URL ?? "";

    /**
     * Determines if the current environment is development.
     */
    static isDevelopment: boolean = process.env.NODE_ENV === 'development';

    /**
     * Whether to output generated scss variables from the color theme service for usages in the app.
     * This feature is meant to be used only in development.
     */
    static OutputScssVariables: boolean = this.isDevelopment &&
        (process.env.REACT_APP_OUTPUT_SCSS_VARIABLES === 'true' ?? false);

    /**
     * Whether to show the application as BizLearn or ULearn
     */
    static showBiz: boolean = process.env.REACT_APP_SHOW_BIZ === 'true' ?? false;

    /**
     * Title of the application
     */
    static title: string = (
        this.showBiz
            ? process.env.REACT_APP_WEBSITE_BIZ_NAME
            : process.env.REACT_APP_WEBSITE_U_NAME
    ) ?? ''

    /**
     * Description of the application
     */
    static description: string = (
        this.showBiz
            ? process.env.REACT_APP_WEBSITE_BIZ_DESCRIPTION
            : process.env.REACT_APP_WEBSITE_U_DESCRIPTION
    ) ?? ''
}

export default EnvService;
