/*
 *
 */

import LocalStorageService from "./local-storage-service";
import moment from "moment";
import InAppModels from "../../models/in-app";
import {ColorThemes} from "../../constants/enums";
import appRoutes from "../../models/static/routes/app-routes";
import {Location, matchRoutes} from 'react-router-dom';

/**
 * This interface is responsible for caching and retrieving important information.
 * - works directly with LocalStorageService if the caching is done through localStorage.
 */
class CacheService {
    protected static keys = LocalStorageService.keys;
    protected static setLocalStorage = LocalStorageService.set;
    protected static getLocalStorage = LocalStorageService.get;
    protected static localStorageRemove = LocalStorageService.remove;
    protected static localStorageRemoveAll = LocalStorageService.removeAll;

    // ################################         AUTH         ################################

    /**
     * Sets the login information to the local storage of the window.
     *
     * @param {string} token the auth token.
     * @param {number} tokenExpiration the expiration date in form of minutes from current time.
     * @param {InAppModels.UserInfo} userInfo the user information
     * @param {boolean} dispatchStorageEvent whether to dispatch the storage event so that the auth middleware
     * intercepts the event.
     */
    static setLoggedInUserInfo(token: string, tokenExpiration: number, userInfo?: InAppModels.UserInfo, dispatchStorageEvent?: boolean): void {
        this.setLocalStorage(this.keys.token, token);
        this.setLocalStorage(this.keys.tokenExpiration, moment().add(tokenExpiration, 'minutes').toISOString())
        if (userInfo) {
            this.setUserInformation(userInfo);
        }
        if (dispatchStorageEvent) {
            window.dispatchEvent(new Event('storage'))
        }
    }

    /**
     * Checks to see if the user has a token and their expiry time has not passed.
     * @return {boolean}
     */
    static isLoggedIn(): boolean {
        return !!this.retrieveTokenAndExpiration();
    }

    /**
     * Retrieves the token and expiry time in minutes form the local storage.
     * @return {InAppModels.AuthStatus | undefined}
     */
    static retrieveTokenAndExpiration(): InAppModels.AuthStatus | undefined {
        let token = this.getLocalStorage(this.keys.token);
        let expDateString = this.getLocalStorage(this.keys.tokenExpiration);
        if (!expDateString) {
            return undefined;
        }
        let expDate = expDateString && moment(expDateString);
        let currentDate = moment();
        if (!token || !expDate || currentDate >= expDate) {
            return undefined;
        }
        return {token, tokenLifespanInMinutes: moment.duration(expDate.diff(currentDate)).asMinutes()};
    };

    /**
     * Fetches the user information form the cache and returns it.
     * @return {InAppModels.UserInfo} the retrieved user information.
     */
    static getUserInformation(): InAppModels.UserInfo | undefined {
        const userInfo = this.getLocalStorage(this.keys.userInfo);
        if (userInfo) {
            return JSON.parse(userInfo);
        }
        return userInfo;
    }

    /**
     * Saves the user information by caching the given userInfo.
     *
     * @param {InAppModels.UserInfo} userInfo the user info to be saved.
     * @param {boolean} dispatchStorageEvent whether to dispatch the storage event so that the auth middleware
     * intercepts the event.
     */
    static setUserInformation(userInfo: InAppModels.UserInfo, dispatchStorageEvent = false) {
        this.setLocalStorage(this.keys.userInfo, JSON.stringify(userInfo))
        if (dispatchStorageEvent) {
            window.dispatchEvent(new Event('storage'))
        }
    }

    /**
     * Removes the user information by deleting the relevant cached info.
     *
     * @param {boolean} dispatchStorageEvent whether to dispatch the storage event so that the auth middleware
     * intercepts the event.
     */
    static removeUserInformation(dispatchStorageEvent = false): void {
        this.localStorageRemove(this.keys.token);
        this.localStorageRemove(this.keys.tokenExpiration);
        this.localStorageRemove(this.keys.userInfo);
        if (dispatchStorageEvent) {
            window.dispatchEvent(new Event('storage'))
        }
    }

    // ################################         COLOR THEME         ################################

    /**
     * Fetches the selected color theme of the application from the localstorage.
     *
     * if the currently saved theme does not have a correct value returns the default theme.
     * @return {string} the selected color theme or the default color theme.
     */
    static getColorTheme(): string {
        const theme = LocalStorageService.get(LocalStorageService.keys.theme);
        if (!theme || !Object.values(ColorThemes).includes(theme)) {
            return this._getDefaultColorTheme();
        }
        return theme;
    }

    /**
     * Fetches the default color theme for the application.
     *
     * this method lookups the prefers-color-scheme property of the window to determine the default color
     * @return {string} default color theme.
     * @private
     */
    private static _getDefaultColorTheme(): string {
        // TODO: to enable possibility of dark theme as a default theme, uncomment to code below.
        return ColorThemes.light;
        // const userPrefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
        // return userPrefersDarkMode ? ColorThemes.dark : ColorThemes.light;
    }

    /**
     * Sets the selected color theme of the application in the localstorage.
     *
     * if the given theme does not have a correct value uses the getColorTheme.
     * @param {string} theme the theme to be set as the application theme.
     * @return {string} the theme that was set.
     */
    static setColorTheme(theme: string): string {
        // @ts-ignore
        if (!theme || !Object.values(ColorThemes).includes(theme)) {
            theme = this.getColorTheme();
        }
        LocalStorageService.set(LocalStorageService.keys.theme, theme);
        return theme;
    }

    // ################################         CACHED URL        ################################

    /**
     * Saves the given locations' information.
     * @param {Location} location
     */
    static cacheUrl(location?: Location): void {
        if (!location) return;
        const unCacheableRoutes = [
            appRoutes.public.error._base,
            appRoutes.public.auth._base,
        ];
        if (!!matchRoutes(unCacheableRoutes.map(e => ({path: e})), location)) {
            return;
        }
        this.setLocalStorage(this.keys.cachedUrl, JSON.stringify(location));
    }

    /**
     * Fetches the cached location information and returns it.
     *
     * @param {boolean} remove whether to remove the cached url as well.
     * @return {Location | undefined}
     */
    static getCachedUrl(remove: boolean = false): Location | undefined {
        const cachedUrl = this.getLocalStorage(this.keys.cachedUrl);
        if (cachedUrl) {
            return JSON.parse(cachedUrl);
        }
        if (remove) {
            this.localStorageRemove(this.keys.cachedUrl);
        }
        return cachedUrl;
    }

}

export default CacheService;
