/*
 *
 */

import React, {useLayoutEffect} from "react";
import {matchRoutes, Outlet} from 'react-router-dom'
import AppHeader from "../../components/app-specific/app-header";
import useRouter from "../../hooks/use-router";
import {useDispatch} from "react-redux";
import {setHeaderBackgroundColorRedux} from "../../../redux/slices/header/actions";
import AppRoutes from "../../../core/models/static/routes/app-routes";

const PrivateViews = () => {
    const {location} = useRouter();
    const dispatch = useDispatch();

    /**
     * with each change in location:
     * - sets the header background color based on the current route.
     */
    useLayoutEffect(() => {
        setHeaderBackgroundColor();
    }, [location])

    /**
     * Sets the header background color based on the current route of the application.
     *
     * - the color is the default background color on default.
     * - if the route is one of the search routes, the color will be search background color.
     */
    const setHeaderBackgroundColor = () => {
        let color = "defaultBackgroundColor";
        const searchRoutes = [
            AppRoutes.private.search,
            AppRoutes.private.videos.list,
            AppRoutes.private.courses.list,
        ]
        const isInSearchRoutes = !!matchRoutes(searchRoutes.map(e => ({path: e})), location);
        if (isInSearchRoutes) {
            color = 'searchBackgroundColor'
        }
        dispatch(setHeaderBackgroundColorRedux(color))
    }

    return (
        <>
            <div className={'private-view'}>
                <AppHeader/>
                <Outlet/>
            </div>
        </>
    )
}

export default PrivateViews;
