/*
 *
 */
import Sizes from "../../core/models/static/sizes";
import ColorThemeService from "../../core/services/color-theme-service";
import {buttonClasses, darken, formControlLabelClasses, ThemeOptions, tooltipClasses} from "@mui/material";
// noinspection ES6UnusedImports
import {} from '@mui/lab/themeAugmentation';

/**
 * The general classes used as advanced selectors.
 */
const generalClasses = {
    disabled: 'Mui-disabled',
    selected: 'Mui-selected',
    error: 'Mui-error',
}

/**
 * The Theme that is to be used by material ui components.
 *
 * @param {"dark" | "light"} mode
 * @return {Partial<ThemeOptions || {}>}
 */
const createMuiTheme = (mode: "dark" | "light"): Partial<ThemeOptions> => {
    // Note: please do not inline this variable as the ColorThemeService is a Proxy and its getters would only be
    // evaluated when it is fully initialized. Thus theme must first be put into memory and then returned.
    // noinspection UnnecessaryLocalVariableJS
    const theme: Partial<ThemeOptions> = {
        breakpoints: {
            values: {
                xs: Sizes.xs,
                sm: Sizes.sm,
                md: Sizes.md,
                lg: Sizes.lg,
                xl: Sizes.xl,
            },
        },
        palette: {
            primary: {
                main: ColorThemeService.primaryColor,
            },
            secondary: {
                main: ColorThemeService.secondaryColor,
            },
            error: {
                main: ColorThemeService.red,
            },
            warning: {
                main: ColorThemeService.yellow,
            },
            success: {
                main: ColorThemeService.green,
            },
            mode: mode ?? ColorThemeService.theme,
        },
        typography: {
            fontFamily: 'Roboto',
            fontSize: 13,
        },
        shape: {
            borderRadius: 5,
        },
        components: {
            MuiTab: {
                defaultProps: {
                    disableRipple: true,
                },
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                        padding: "1rem 0 0",
                        [`&.${generalClasses.selected}`]: {
                            color: ColorThemeService.primaryTextColor,
                        },
                        [`&:hover`]: {
                            color: ColorThemeService.primaryTextColor,
                        },
                    },
                },
            },
            MuiTabs: {
                styleOverrides: {
                    indicator: {
                        height: '3px',
                    },
                },
            },
            MuiCircularProgress: {
                defaultProps: {
                    size: 50,
                },
            },
            MuiList: {
                styleOverrides: {
                    root: {
                        padding: '0.6rem',
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        borderRadius: 5,
                        marginBottom: 5,
                        '&:hover': {},
                    },
                },
            },
            MuiSelect: {
                defaultProps: {
                    MenuProps: {
                        TransitionProps: {
                            onEnter: () => {
                                setTimeout(() => {
                                    if (document.activeElement) {
                                        // @ts-ignore
                                        document.activeElement?.blur();
                                    }
                                }, 500);
                            },
                        },
                        PaperProps: {
                            elevation: 4,
                            style: {
                                transform: 'translate3d(0, 5px, 0)'
                            }
                        },
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        },
                        color: "primary",
                    },
                },
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        [`& + .${formControlLabelClasses.label}`]: {
                            fontSize: '0.8rem',
                            fontWeight: 500,
                        },
                        [`.${generalClasses.error} > &, .${generalClasses.error} > & + .${formControlLabelClasses.label}`]: {
                            color: ColorThemeService.red,
                        },
                    },
                },
            },
            MuiTooltip: {
                defaultProps: {
                    placement: 'bottom',
                    arrow: true,
                },
                styleOverrides: {
                    tooltip: {
                        fontWeight: 600,
                        borderRadius: 2,
                        [`&.${tooltipClasses.tooltipPlacementBottom}`]: {
                            marginTop: '5px !important'
                        },
                    },
                },
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        marginLeft: 0,
                    },
                },
            },
            MuiButton: {
                defaultProps: {
                    disableElevation: true,
                    variant: 'contained',
                    className: 'button',
                    type: 'button',
                    color: 'primary',
                },
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                    },
                    containedPrimary: {
                        backgroundColor: ColorThemeService.buttonPrimaryBackgroundColor,
                        color: ColorThemeService.buttonPrimaryColor,
                        [`&:hover`]: {
                            backgroundColor: darken(ColorThemeService.buttonPrimaryBackgroundColor, 0.05),
                            color: ColorThemeService.buttonPrimaryColor,
                        }
                    },
                    containedSecondary: {
                        backgroundColor: ColorThemeService.buttonSecondaryBackgroundColor,
                        color: ColorThemeService.buttonSecondaryColor,
                        [`&:hover`]: {
                            backgroundColor: darken(ColorThemeService.buttonSecondaryBackgroundColor, 0.05),
                            color: ColorThemeService.buttonSecondaryColor,
                        }
                    },
                },
            },
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                        [`&.${generalClasses.disabled}:not(&.${buttonClasses.text})`]: {
                            backgroundColor: ColorThemeService.disabledButtonBackgroundColor,
                        },
                        [`&.${generalClasses.disabled}`]: {
                            color: ColorThemeService.disabledTextColor,
                        },
                        [`.MuiLoadingButton-loadingIndicator`]: {
                            color: ColorThemeService.disabledTextColor,
                        },
                    },

                },
            },
            MuiLoadingButton: {
                defaultProps: {
                    loadingPosition: 'center',
                    variant: 'contained',
                    className: 'button',
                    type: 'button',
                },
            },
        },
    }
    return theme;
};

export default createMuiTheme;
