/*
 *
 */

import CacheService from "../../../cache/cache-service";
import ApiResponseModels from "../base/models/responses";
import ApiResponseUtils from "../../helpers/api-response-utils";
import AppRoutes from '../../../../models/static/routes/app-routes'
import {AxiosError, AxiosResponse} from "axios";
import BaseApiExecutor from "../base/executor";
import ApiRequestModels from "../base/models/requests";
import BizLearnApiResponseModels from "./models/responses";
import InAppModels, {CrudResponse} from "../../../../models/in-app";
import {toast} from "react-toastify";
import {ApiHeaderNames} from "../../../../constants/enums";

/**
 * BizLearn specific api executor.
 *
 * This Api Executor will be the main executor in this application for communicating with the backend.
 * this interface uses CrudResponse as the innerResponse DS and flags any status codes in this internal response
 * instead of the responses' status code.
 */
class BizLearnApiExecutor extends BaseApiExecutor {

    /**
     * Assigns the effects side specific that will be invoked after each api call.
     */
    constructor() {
        super();
        this.effects = [
            BizLearnApiExecutor.showToast
        ];
    }

    // ################################         PRE API CALL         ################################

    /**
     * Injects the OS and the auth token to the api call's headers.
     * @return {Record<string, any>} the created header object
     */
    protected _injectHeaders(): Record<string, any> {
        const {token: authToken} = (CacheService.retrieveTokenAndExpiration() ?? {});
        return {
            [ApiHeaderNames.authorization]: authToken ?? '',
        }
    }

    /**
     * Checks whether the authentication token of the user has been expired or not.
     *
     * if expired, invokes the _onUnauthorizedResponse method.
     * @param {ApiRequestModels.ApiRequest} request
     * @return {ApiResponseModels.ApiResponse | undefined} undefined if valid, else an ApiResponse filled with the
     * error status / message
     */
    protected validateRequest<R, D>(request: ApiRequestModels.ApiRequest<D>): ApiResponseModels.ApiResponse<R> | undefined {
        if (request.enforceAuth && !CacheService.isLoggedIn()) {
            this._onUnauthorizedResponse();
            return this._createApiResponse(request.orderIndex, ApiResponseUtils.unauthorized.code);
        }
    }

    // ################################         POST API CALL         ################################

    /**
     * Creates the response object that is embedded in the ApiResponse object. This inner response exists due to
     * possibility of having different executors from different servers.
     *
     * @param {number} statusCode the status code of the response. Refers to a server statusCode.
     * @return {any} the created response.
     */
    protected _createPlaceholderInternalResponse<R>(statusCode: number): R {
        const status = ApiResponseUtils.getEntryByCode(statusCode);
        const response: CrudResponse = {
            code: statusCode,
            resultFlag: false,
            data: null,
            list: [],
            message: status?.message ?? undefined,
            aborted: statusCode === ApiResponseUtils.aborted.code,
        };
        return response as R;
    }

    /**
     * Creates the response object that is embedded in the ApiResponse object. This inner response exists due to
     * possibility of having different executors from different servers.
     *
     * @param {BizLearnApiResponseModels.ApiResponse} response the internal response that is to be wrapped or modified
     * @param {number} statusCode the status code of the response. Refers to a server statusCode.
     * @return {CrudResponse} the created response in the specified format.
     */
    protected _createInternalResponse(response: BizLearnApiResponseModels.ApiResponse, statusCode: number): CrudResponse {
        const status = ApiResponseUtils.getEntryByCode(statusCode);
        const code = response.code ?? status?.code ?? ApiResponseUtils.apiError.code;
        const config: InAppModels.CrudResponseConfig = {
            videoBaseURL: response.configuration?.VideoBaseURL,
            videoCoverBaseURL: response.configuration?.VideoCoverImage,
            courseCoverBaseURL: response.configuration?.CourseCoverImage,
        };
        return {
            ...response,
            resultFlag: response.resultFlag ?? false,
            data: Array.isArray(response?.data) ? undefined : response?.data,
            list: Array.isArray(response?.data) ? response.data : undefined,
            code: code,
            aborted: code === ApiResponseUtils.aborted.code,
            configuration: config,
        };
    };

    /**
     * Logs the user out of the system by removing all of their information in case of a not authorized response
     * Note: the redux state's info is not removed here since the authentication middleware will do that automatically.
     * @protected
     */
    protected _onUnauthorizedResponse(): void {
        CacheService.removeUserInformation(true);
    }

    /**
     * Logs the user out of the system by removing all of their information in case of a not authorized response
     * Note: the redux state's info is not removed here since the authentication middleware will do that automatically.
     * @protected
     */
    protected _onForbiddenAccessResponse(): void {
        window.location.pathname = AppRoutes.public.error.accessDenied;
    }

    /**
     * Determines the cause of the api execution error and handles each case accordingly.
     *
     * @param {AxiosError} error the Axios error object.
     * @param {ApiRequestModels.ApiRequest} request the request that was sent with the api call.
     * @return {ApiResponseModels.ApiResponse} a generic response object that contains the information about the
     * failing of the api execution.
     */
    protected _onAxiosErrorReceived<R, D>(error: AxiosError<R, D>, request: ApiRequestModels.ApiRequest<D>)
        : ApiResponseModels.ApiResponse<R> {
        switch (error.response?.status) {
            case ApiResponseUtils.unauthorized.code:
                this._onUnauthorizedResponse();
                break;
            case ApiResponseUtils.forbiddenAccess.code:
                this._onForbiddenAccessResponse();
                break;
            default:
                break;
        }
        return this._createApiResponse(request.orderIndex, error.response?.status ?? ApiResponseUtils.serverNotResponded.code)
    }

    /**
     * Checks the validity of the axios response against this executor's constraints and returns the internal
     * response in the common ApiResponse wrapper.
     *
     * @param {ApiRequestModels.ApiRequest} request the request that was sent with the api call.
     * @param {AxiosResponse} response the axios response object.
     * @return {ApiResponseModels.ApiResponse} the same response given in the request.
     */
    protected _onResponseReceived<R, D>(request: ApiRequestModels.ApiRequest<D>, response: AxiosResponse<R, D>): ApiResponseModels.ApiResponse<R> {
        const internalResponse: BizLearnApiResponseModels.ApiResponse = response.data;
        switch (internalResponse?.code) {
            case ApiResponseUtils.unauthorized.code:
                this._onUnauthorizedResponse();
                break;
            case ApiResponseUtils.forbiddenAccess.code:
                this._onForbiddenAccessResponse();
                break;
            default:
                break;
        }
        return this._createApiResponse(
            request.orderIndex,
            internalResponse.code ?? response.status,
            internalResponse,
        );
    }

    // ################################         EFFECTS         ################################

    /**
     * Shows toasts associated with the status of the api response if the permission was granted in the request.
     * @param request
     * @param response
     * @private
     */
    private static showToast(request: ApiRequestModels.ApiRequest, response: ApiResponseModels.ApiResponse<CrudResponse>): void {
        if (response?.response?.resultFlag) {
            const message = response?.response?.message ?? '';
            if (message && request.showSuccessToast) {
                toast.success(message);
            }
            return;
        }
        if (!response?.response?.aborted && request.showErrorToast) {
            // only show the toast for non-aborted responses.
            let message = response?.response?.message;
            if (!message) {
                const status = ApiResponseUtils.getEntryByCode(response?.response?.code)
                message = status?.message ?? ApiResponseUtils.apiError.message
            }
            toast.error(message);
        }
    }
}

export default BizLearnApiExecutor;
