/*
 *
 */

import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import useRouter from "../../../../hooks/use-router";
import VideoPlayer from "../../../../components/base/video-player";
import {VideoCardTypes, VideoPlayerTypes} from "../../../../../core/constants/enums";
import {VideoInformationQueryParams, VideosQueryParams} from "../../../../../core/constants/query-params";
import useIsMounted from "../../../../hooks/use-is-mounted";
import {Col, Container, Row} from "react-bootstrap";
import VideoCard from "../../../../components/app-specific/video-card";
import {Button, CircularProgress, IconButton, Tab, Tabs, Tooltip} from "@mui/material";
import {ArrowBackOutlined} from "@mui/icons-material";
import {ReactComponent as ShareIcon} from "../../../../../assets/images/share.svg";
import copy from 'clipboard-copy';
import classnames from "classnames";
import {ReactComponent as SavedIcon} from "../../../../../assets/images/save/saved.svg";
import {ReactComponent as NotSavedIcon} from "../../../../../assets/images/save/not-saved.svg";
import Utils from "../../../../../core/services/utils";
import AppRoutes from "../../../../../core/models/static/routes/app-routes";
import {Link} from "react-router-dom";
import VideoInformationViewCaptionSection from "./sections/caption";
import VideoInformationViewDescriptionSection from "./sections/description";
import VideoInformationViewNotebookSection from "./sections/notebook";
import {BizLearnApi} from "../../../../../core/services/api";

const tabs = {
    description: {
        title: "Description",
        value: "description",
        orderIndex: 1,
    },
    notebook: {
        title: "Notebook",
        value: "notebook",
        orderIndex: 2,
    },
    caption: {
        title: "Caption",
        value: "caption",
        orderIndex: 3,
    },
}

const VideoInformationView = () => {
    const {navigate, params, query, setQuery} = useRouter();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [copied, setCopied] = useState(false);
    const [tab, setTab] = useState(tabs.description.value);
    const isMounted = useIsMounted();
    const videoPlayer = useRef();

    /**@type {boolean}*/
    const belongsToCourse = useMemo(() =>
            typeof params?.courseId !== 'undefined' &&
            !Number.isNaN(params?.courseId),
        [params.courseId])

    const courseLink = useMemo(() => belongsToCourse
            ? AppRoutes.generateRoute(AppRoutes.private.courses.single.base, {courseId: params?.courseId})
            : '',
        [belongsToCourse, params.courseId])

    /**
     * With each change in params:
     * - fetches the video information from the server.
     */
    useEffect(() => {
        getVideoInformation().then();
    }, [params?.id, params?.courseId])

    /**
     * With each change in query params of the url:
     * - if the query object does not have a tab, sets the default tab in it
     * - sets the selected tab of the view from the query params.
     */
    useEffect(() => {
        if (!Object.keys(query).includes(VideoInformationQueryParams.tab)) {
            return changeSelectedTab(undefined, tabs.description.value);
        }
        setTab(query[VideoInformationQueryParams.tab])
    }, [query])


    /**
     * Fetches the information of the video form the server.
     *
     * * if the response of the api is not successful, navigates the user to the previous page, otherwise, sets the
     * internal data state.
     */
    const getVideoInformation = async () => {
        setLoading(true);
        const response = await BizLearnApi.getVideoInformation(parseInt(params?.id));
        if (!isMounted()) return;
        if (!response?.resultFlag) {
            return navigate(-1);
        }
        setLoading(false);
        const data = {
            ...(response?.data ?? {}),
            videoUrl: (
                Utils.isYoutubeUrl(response?.data?.videoUrl ?? '')
                    ? ''
                    : (response?.configuration?.videoBaseURL ?? '')
            ) + (response?.data?.videoUrl ?? ''),
            playList: response?.data?.playList
                ?.map(video => ({
                    ...video,
                    coverImageUrl: (response?.configuration?.videoCoverBaseURL ?? "") + video.coverImageUrl ?? "",
                    isSaved: response?.data?.id === video?.id ? response?.data?.isSaved : video?.isSaved,
                    courseId: response?.data?.course?.id,
                }))
                ?.sort((a, b) => Utils.numComparator(a.playlistIndex, b.playlistIndex))
        };
        data.notes?.sort((a, b) => Utils.numComparator(Utils.toSeconds(a.videoMark), Utils.toSeconds(b.videoMark)))
        setData({
            ...data,
            saving: false,
        });
    }

    /**
     * Toggles the saved state of this video.
     * @return {Promise<void>}
     */
    const toggleSaved = async () => {
        /**@type {BizLearnApiRequestModels.Video.ToggleIsSaved}*/
        const forApi = {
            videoId: data?.id,
        }
        const forApiExtra = {
            showSuccessToast: true,
        }
        setData(prevState => ({...prevState, saving: true}));
        const response = await BizLearnApi.toggleVideosIsSaved(forApi, forApiExtra);
        if (!isMounted()) return;
        if (response?.resultFlag) {
            setData(prevState => ({...prevState, isSaved: !prevState.isSaved}));
        }
        setData(prevState => ({...prevState, saving: false}));
    }

    /**
     * Copies the link of this page to users' clipboard.
     *
     * * this method uses a timer for updating the text of the toolbox.
     * @return {(function(): void)|*}
     * @private
     */
    const _copyToClipboard = () => {
        let timer;
        return () => {
            if (timer) {
                clearTimeout(timer);
                timer = null;
            }
            setCopied(true);

            copy(window.origin + window.location.pathname).then();
            timer = setTimeout(() => {
                setCopied(false);
            }, 3000)
        }
    }

    /**
     * Copies the link of this page to users' clipboard.
     *
     * * this method uses a timer for updating the text of the toolbox.
     * @return {(function(): void)|*}
     */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    const copyToClipboard = useCallback(_copyToClipboard(), []);

    /**
     * Changes the selected tab of this view in the url query params that will ultimately changes the selected tab
     * in the internal state.
     *
     * @param {React.SyntheticEvent} event
     * @param {string} value the value of the tab.
     */
    const changeSelectedTab = (event = undefined, value) => {
        setQuery({
            ...query,
            [VideoInformationQueryParams.tab]: value
        }, {replace: true})
    }

    /**
     * Navigates the user to the videos list view with the data's platform as its selected platform's search filter.
     */
    const searchVideosWithPlatform = () => {
        navigate(AppRoutes.private.videos.list,
            undefined,
            {
                [VideosQueryParams.platform]: data?.application?.id,
            })
    }

    /**
     * Adds the created note to the notes of the data state.
     * @param {BizLearnApiResponseModels.Notebook.Note} note
     */
    const onNoteCreated = (note) => {
        setData(prevState => {
            const notes = [...(prevState?.notes ?? []), note].sort((a, b) => Utils.numComparator(Utils.toSeconds(a.videoMark), Utils.toSeconds(b.videoMark)))
            return ({
                ...prevState,
                notes: notes,
            })
        })
    }

    /**
     * Updates the content of the given note in the data state.
     * @param {BizLearnApiResponseModels.Notebook.Note} note
     */
    const onNoteUpdated = (note) => {
        setData(prevState => {
            const notes = prevState?.notes?.map(e => note.id === e.id
                ? {...e, ...note}
                : e
            ) ?? []
            notes.sort((a, b) => Utils.numComparator(Utils.toSeconds(a.videoMark), Utils.toSeconds(b.videoMark)));
            return ({
                ...prevState,
                notes: notes,
            })
        })
    }

    /**
     * Removes the given note from the data state.
     * @param {BizLearnApiResponseModels.Notebook.Note} note
     */
    const onNoteRemoved = (note) => {
        setData(prevState => ({
            ...prevState,
            notes: prevState?.notes?.filter(e => note.id !== e.id) ?? []
        }))
    }

    /**@type {ReactNode}*/
    const tabContent = useMemo(() => {
        switch (tab) {
            case tabs.notebook.value:
                return <VideoInformationViewNotebookSection
                    videoPlayer={videoPlayer}
                    data={data}
                    onNoteCreated={onNoteCreated}
                    onNoteUpdated={onNoteUpdated}
                    onNoteRemoved={onNoteRemoved}
                />;
            case tabs.caption.value:
                return <VideoInformationViewCaptionSection data={data}/>;
            case tabs.description.value:
            default:
                return <VideoInformationViewDescriptionSection data={data}/>;
        }
    }, [tab, data, videoPlayer])

    return (
        <>
            <div className={classnames('video-information-view', {loading, 'belongs-to-course': belongsToCourse})}>
                <Container>
                    {
                        loading
                            ? <>
                                <Row>
                                    <Col xs={12} className={'p-0'}>
                                        <div/>
                                    </Col>
                                    {
                                        belongsToCourse &&
                                        <Col xs={12} lg={5} className={'playlist-container order-2 order-lg-1'}>
                                            <div>
                                                <div/>
                                            </div>
                                            <Row className={'playlist'}>
                                                {
                                                    Array(8).fill(null).map((_, e) => (
                                                        <Col key={e} xs={12} className={'item'}>
                                                            <VideoCard
                                                                loading={loading}
                                                                type={VideoCardTypes.small}
                                                            />
                                                        </Col>
                                                    ))
                                                }
                                            </Row>
                                        </Col>
                                    }
                                    <Col xs={12} lg={belongsToCourse ? 7 : 12} className={'info order-1 order-lg-2'}>
                                        <div>
                                            <div/>
                                        </div>
                                        <div>
                                            <div/>
                                            <div/>
                                        </div>
                                        <div>
                                            {
                                                belongsToCourse &&
                                                <div/>
                                            }
                                            <div>
                                                <div/>
                                            </div>
                                        </div>
                                        <div className={'spacer'}/>
                                        <div className={'tabs'}>
                                            {
                                                Object.values(tabs).map((tab) => (
                                                    <div key={tab.orderIndex}>
                                                        <div/>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <section>
                                            {
                                                Array(20).fill(null).map((_, e) => (
                                                    <div key={e}>
                                                        <div/>
                                                    </div>
                                                ))
                                            }
                                        </section>
                                    </Col>
                                </Row>
                            </>
                            : <>
                                <Row>
                                    <Col xs={12} className={'p-0'}>
                                        <div className={'video-player'}>
                                            <VideoPlayer
                                                ref={videoPlayer}
                                                title={data?.title ?? ''}
                                                type={VideoPlayerTypes.large}
                                                url={data?.videoUrl}
                                            />
                                        </div>
                                    </Col>
                                    {
                                        belongsToCourse &&
                                        <Col xs={12} lg={5} className={'playlist-container order-2 order-lg-1'}>
                                            <div className={'playlist-title'}>
                                                <p>
                                                    Playlist
                                                </p>
                                            </div>
                                            <Row className={'playlist'}>
                                                {
                                                    data?.playList?.map(video => (
                                                        <Col key={video.id} xs={12} className={'item'}>
                                                            <VideoCard
                                                                className={classnames({'active': data?.id === video.id})}
                                                                data={video}
                                                                type={VideoCardTypes.small}
                                                            />
                                                        </Col>
                                                    ))
                                                }
                                            </Row>
                                        </Col>
                                    }
                                    <Col xs={12} lg={belongsToCourse ? 7 : 12} className={'info order-1 order-lg-2'}>
                                        <div>
                                            <p className={'title'}>
                                                {data?.title}
                                            </p>
                                            <div className={'actions'}>
                                                <Tooltip
                                                    {...(
                                                        copied
                                                            ? {
                                                                open: true,
                                                                leaveDelay: 2000,
                                                            }
                                                            : {}
                                                    )}
                                                    title={copied ? "Copied to Clipboard" : 'Copy Link'}>
                                                    <IconButton
                                                        onClick={copyToClipboard}
                                                        className={'icon-button'}>
                                                        <ShareIcon className={'custom'}/>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip
                                                    title={
                                                        data?.saving
                                                            ? 'saving...'
                                                            : !data?.isSaved
                                                                ? 'save'
                                                                : 'un-save'
                                                    }>
                                                    <IconButton
                                                        className={classnames('icon-button save', {'active': data?.isSaved})}
                                                        onClick={toggleSaved}>
                                                        {
                                                            data?.saving
                                                                ? <CircularProgress size={14} color={'secondary'}/>
                                                                : data?.isSaved
                                                                    ? <SavedIcon className={'custom'}/>
                                                                    : <NotSavedIcon className={'custom'}/>
                                                        }
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div>
                                            {
                                                belongsToCourse &&
                                                <Link to={courseLink} className={'course'}>
                                                    <ArrowBackOutlined fontSize={'small'}/>
                                                    <p>
                                                        {data?.course?.title ?? ""}
                                                    </p>
                                                </Link>
                                            }
                                            <div>
                                                <Button
                                                    className={'button'}
                                                    onClick={searchVideosWithPlatform}>
                                                    {data?.application?.name ?? ''}
                                                </Button>
                                            </div>
                                        </div>
                                        <div className={'spacer'}/>
                                        <div>
                                            <div className={'tabs'}>
                                                <Tabs
                                                    variant={'scrollable'}
                                                    indicatorColor={'secondary'}
                                                    value={tab}
                                                    onChange={changeSelectedTab}>
                                                    {
                                                        Object.values(tabs)
                                                            .sort((a, b) => Utils.numComparator(a.orderIndex, b.orderIndex))
                                                            .map((tab) => (
                                                                <Tab
                                                                    key={tab.orderIndex}
                                                                    label={tab.title}
                                                                    value={tab.value}
                                                                />
                                                            ))
                                                    }
                                                </Tabs>
                                            </div>
                                        </div>
                                        <section>
                                            {tabContent}
                                        </section>
                                    </Col>
                                </Row>
                            </>
                    }
                </Container>
            </div>
        </>
    )
}

export default VideoInformationView;
