/*
 *
 */

/**
 * Shared content search section search params.
 */
const _sharedContentQueryParams = {
    keywords: 'keywords' as const,
    platform: 'platform' as const,
}

/**
 * search view search section params.
 */
export const ContentQueryParams = {
    ..._sharedContentQueryParams,
    type: 'type' as const,
}

/**
 * Videos list view search section params.
 */
export const VideosQueryParams = {
    ..._sharedContentQueryParams,
    savedOnly: 'saved-only' as const,
    inProgressOnly: 'in-progress-only' as const,
}

/**
 * courses list view search section params.
 */
export const CoursesQueryParams = {
    ..._sharedContentQueryParams,
}

/**
 * Video information view's query params
 */
export const VideoInformationQueryParams = {
    tab: 'tab',
}
