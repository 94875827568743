/*
 *
 */

import React, {useLayoutEffect, useRef} from "react";
import {Outlet} from "react-router-dom";
import {AppElementId} from "../../../../core/constants/ids";
import {FlashlightOn} from "@mui/icons-material";

const ErrorViews = () => {
    /**@type {React.MutableRefObject<SVGSVGElement>}*/
    const flashlightRef = useRef();

    /**
     * As soon as the component mounts:
     * - adds the centered className to the app element and remove it after unmount.
     */
    useLayoutEffect(() => {
        const appElement = document.getElementById(AppElementId);
        if (!appElement) return;
        appElement.classList.add('centered', 'error-cursor');
        return () => {
            appElement.classList.remove('centered', 'error-cursor')
        };
    }, [])

    /**
     * As soon as the component is ready to point:
     * - moves the element as the mouse position changes in the window.
     */
    useLayoutEffect(() => {
        const element = document.getElementById('fg');
        const handler = (e) => moveForeground(e, element);
        document.addEventListener('mousemove', handler);
        return () => {
            document.removeEventListener('mousemove', handler);
        }
    }, [])

    /**
     * Moves the element as the mouse position changes in the window.
     * @param {MouseEvent} e the mouse event.
     * @param {HTMLDivElement} element the foreground element to be transformed.
     */
    const moveForeground = (e, element) => {
        element.style.transform = `translate(${e.clientX}px, ${e.clientY}px)`;
        flashlightRef.current.style.top = `${e.clientY}px`;
        flashlightRef.current.style.left = `${e.clientX}px`;
    }

    return (
        <>
            <div className={'error-view'}>
                <div id={'fg'} className={'foreground'}/>
                <FlashlightOn className={'flashlight'} ref={flashlightRef}/>
                <Outlet/>
            </div>
        </>
    )
}

export default ErrorViews;
