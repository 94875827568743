/*
 *
 */

import {combineReducers} from "redux";
import reduxStoreUserInfoSlice from "./slices/user-info/slice";
import reduxStoreConfigSlice from "./slices/config/slice";
import reduxStoreHeaderSlice from "./slices/header/slice";
import reduxStoreDialogsSlice from "./slices/dialogs/slice";

/**
 * The combined reducers of the entire redux state.
 */
const reducers = combineReducers({
    [reduxStoreUserInfoSlice.name]: reduxStoreUserInfoSlice.reducer,
    [reduxStoreConfigSlice.name]: reduxStoreConfigSlice.reducer,
    [reduxStoreHeaderSlice.name]: reduxStoreHeaderSlice.reducer,
    [reduxStoreDialogsSlice.name]: reduxStoreDialogsSlice.reducer,
});

export default reducers;
