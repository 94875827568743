import React, {useEffect} from "react";
import EnvService from "../../../../../core/services/env-service";
import useRouter from "../../../../hooks/use-router";
import {useSelector} from "react-redux";
import ReduxStoreRoutes from "../../../../../core/models/static/routes/redux-store-routes";
import Utils from "../../../../../core/services/utils";
import AppRoutes from "../../../../../core/models/static/routes/app-routes";

const AuthLogoutView = () => {
    const {navigate} = useRouter();
    /** @type {InAppModels.Redux.Config}*/
    const config = useSelector(state => state[ReduxStoreRoutes.config])

    /**
     * As soon as the component mounts:
     * - if logout is not filled, redirects the user to the error view, otherwise logs the user out of the system by
     * opening a hidden iframe with the logout url.
     */
    useEffect(() => {
        if (!config.logoutUrl) {
            return navigate(AppRoutes.public.error.serverError, {replace: true});
        }
        logout().then();
    }, [])

    /**
     * Redirects the user to the login view specified in the loginUrl.
     * @return {Promise<void>}
     */
    const logout = async () => {
        await Utils.wait(4000);
        window.location.replace(`${config.logoutUrl}/${encodeURIComponent(window.location.origin + AppRoutes.public.auth.login.base)}`);
    }

    return (
        <>
            <p>
                Thanks for using <span>{EnvService.title}</span>
            </p>
            <p>
                Logging out of the system
            </p>
        </>
    )
}

export default AuthLogoutView;
