/*
 *
 */

// CSS IMPORTS
import './assets/fonts/index.scss';
import './assets/css/index.scss';
import './assets/css/responsive.scss';
import './assets/css/loading.scss';
import './assets/css/safari-only.scss';
import "react-toastify/dist/ReactToastify.css";
import 'react-lazy-load-image-component/src/effects/blur.css';
// Imports
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import reportWebVitals from './report-web-vitals';
import ColorThemeService from "./core/services/color-theme-service";
import Utils from "./core/services/utils";

ColorThemeService.injectColorThemes();
Utils.setAppInfo();

ReactDOM.render(
    <App/>,
    document.getElementById('root')
);

reportWebVitals();



