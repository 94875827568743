/*
 *
 */

import BaseApiEndpoints from "../base/endpoints";
import EnvService from "../../../env-service";

/**
 * This interface's responsibility is to inject the Api url of BizLearn server's controllers into its extending
 * apiEndpoints.
 *
 * All the Endpoints that belong to the BizLearn Server must extend this class.
 */
abstract class _BizLearnApiEndpoints extends BaseApiEndpoints {

    /**
     * Constructs The BizLearn endpoints by injecting the apiUrl into its extending classes.
     * @param {string} suffix the string to be added to apiUrl as this classes' baseUrl.
     * @protected
     */
    constructor(suffix = "") {
        super(EnvService.BizLearnApiUrl, suffix);
    }
}

/**
 * Application controller.
 */
class ApplicationEndpoints extends _BizLearnApiEndpoints {
    public list: string = this.baseUrl.concat("/List");
}

/**
 * Course controller.
 */
class CourseEndpoints extends _BizLearnApiEndpoints {
    public search: string = this.baseUrl.concat("/List");
    public getById = (id: number) => this.baseUrl.concat(`/${id}`);
}

/**
 * MainPage controller.
 */
class MainPageEndpoints extends _BizLearnApiEndpoints {
    public search: string = this.baseUrl.concat("/Search");
    public get: string = this.baseUrl;
}

/**
 * Notebook controller.
 */
class NotebookEndpoints extends _BizLearnApiEndpoints {
    public create: string = this.baseUrl;
    public update: string = this.baseUrl;
    public remove = (id: number) => this.baseUrl.concat(`/${id}`);
}

/**
 * Video controller.
 */
class VideoEndpoints extends _BizLearnApiEndpoints {
    public toggleIsSaved: string = this.baseUrl.concat('/changeIsSaved');
    public searchSavedOnly: string = this.baseUrl.concat('/SavedList');
    public searchInProgressOnly: string = this.baseUrl.concat('/InProgressList');
    public search = (id: number) => this.baseUrl.concat(`/List`);
    public getById = (id: number) => this.baseUrl.concat(`/${id}`);
}

/**
 * The BizLearn Api Endpoints.
 */
export default class BizLearnApiEndpoints {
    static applicationEndpoints: ApplicationEndpoints = new ApplicationEndpoints('/Application');
    static courseEndpoints: CourseEndpoints = new CourseEndpoints('/Course');
    static mainPageEndpoints: MainPageEndpoints = new MainPageEndpoints('/MainPage');
    static notebookEndpoints: NotebookEndpoints = new NotebookEndpoints('/Notebook');
    static videoEndpoints: VideoEndpoints = new VideoEndpoints('/Video');
}
