/*
 *
 */

import React, {useEffect, useLayoutEffect, useRef} from "react";
import useRouter from "../../hooks/use-router";
import CacheService from "../../../core/services/cache/cache-service";
import AppRoutes from "../../../core/models/static/routes/app-routes";
import {matchRoutes} from "react-router-dom";
import {useDispatch} from "react-redux";
import {removeUserInfoRedux, setUserInfoRedux} from "../../../redux/slices/user-info/actions";

/**
 * Resets the time set by the inner with each consequent call to it.
 * @param {Function} inner the inner function to be debounced
 * @param {any} inputs the inputs of the inner.
 * @return {Function}
 */
const debounce = (inner, ...inputs) => {
    let timer = null;
    return () => {
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
        timer = inner(...inputs);
    };
}

const AuthenticationMiddleware = () => {
    const {navigate, location} = useRouter();
    const dispatch = useDispatch();
    const initialRender = useRef(true);


    /**
     * Listens for the changes in pathname of the url and with each chang:
     * -  determines if the user should be redirected to the landing page.
     */
    useEffect(() => {
        determineIfShouldRedirectToLanding();
    }, [location, navigate])

    /**
     * As soon as the component mounts:
     * - Attaches an event listeners for the storage and with each dispatch, determines if the user should be
     * redirected to the landing page.
     */
    useLayoutEffect(() => {
        const handler = () => determineIfShouldRedirectToLanding();
        window.addEventListener('storage', handler);
        return () => window.removeEventListener('storage', handler);
    }, [navigate, location])


    /**
     * Depending on the current authenticated state of the user:
     *  - if logged in:
     *      * sets the user info in the redux slice only for the first render of this component.
     *      * navigates them to the home view if they are in the landing view.
     *  - if logged out:
     *      * removes the user information from the redux state
     *      * navigates them to the login view if they are in nay of the private views.
     */
    const determineIfShouldRedirectToLanding = debounce(() => {
        const isLoggedIn = CacheService.isLoggedIn();
        if (isLoggedIn) {
            if (initialRender.current) {
                initialRender.current = false;
                // save user info in redux.
                const userInfo = CacheService.getUserInformation();
                if (userInfo) {
                    dispatch(setUserInfoRedux(userInfo))
                }
            }
            // save user info in redux.
            const _isInLandingView = isInLandingView();
            if (_isInLandingView) {
                navigate(AppRoutes.private.home, {replace: true});
            }
            return null;
        }
        dispatch(removeUserInfoRedux());
        // we have to wait for a bit since to navigate in V6 works with relative paths, and thus we need its state
        // to be updated before any navigation.
        return setTimeout(() => {
            const _isInNonRestrictedViews = isInNonRestrictedViews();
            if (_isInNonRestrictedViews) {
                return;
            }
            // if not logged in, redirect to the authentication view.
            navigate(AppRoutes.public.auth.redirect, {replace: true});
        }, 100)
    })

    /**
     * Determines if the user is in a non-restricted (requiring authentication) view or not.
     * @return {boolean}
     */
    const isInNonRestrictedViews = () => {
        const privateViews = [
            AppRoutes.private.landing,
            AppRoutes.private.home,
            AppRoutes.private.search,
            AppRoutes.private.courses._base,
            AppRoutes.private.videos._base,
        ]
        const isInPrivateRoutes = !!matchRoutes(privateViews.map(e => ({path: e})), location);
        return !isInPrivateRoutes;
    }

    /**
     * Determines if the user is in the landing view.
     * @return {boolean}
     */
    const isInLandingView = () => {
        return !!matchRoutes([
            AppRoutes.private.landing,
        ].map(e => ({path: e})), location);
    }

    return <></>;
}

export default AuthenticationMiddleware;
