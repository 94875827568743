/*
 *
 */

import {createSlice} from "@reduxjs/toolkit";
import ReduxStoreRoutes from "../../../core/models/static/routes/redux-store-routes";

/**
 * common initial state of all dialogs states in this slice.
 * @type {InAppModels.Redux.CommonDialogsState}
 */
const commonInitialState = {
    open: false,
    mandatory: false,
}

/**
 * Initial state of the confirmation dialog.
 * @type {InAppModels.Redux.ConfirmationDialogState}
 */
export const reduxStoreConfirmationDialogInitialState = {
    ...commonInitialState,
    callback: null,
    title: 'Confirmation',
    description: '',
    cancelText: "cancel",
    proceedText: "proceed",
}
/**
 * Initial state of the dialogs slice.
 */
const dialogsInitialState = {
    [ReduxStoreRoutes.dialogs.confirmation]: reduxStoreConfirmationDialogInitialState,
}

/**
 * Reducer that is responsible for handling shared dialogs' states of the entire app.
 */
const reduxStoreDialogsSlice = createSlice({
    name: ReduxStoreRoutes.dialogs.base,
    initialState: dialogsInitialState,
    reducers: {
        /**
         * Sets the state of the chosen dialog in the application.
         *
         * the payload given to this method must contain the following:
         * ```json
         *  {
         *      type: string
         *      state: Record<string, any>
         *  }
         * ```
         * @param state
         * @param action
         */
        setDialogState: (state, action) => {
            let initialState = {};
            const path = action.payload.type;
            switch (action.payload.type) {
                case ReduxStoreRoutes.dialogs.confirmation:
                    initialState = reduxStoreConfirmationDialogInitialState;
                    break;
                default:
                    return;
            }
            state[path] = {
                ...state[path],
                ...action.payload.state,
            }
            // only if explicitly false, push the initial state.
            if (action.payload.state.open === false) {
                state[path] = {
                    ...state[path],
                    ...initialState,
                }
            }
            return state;
        },
    }
});

export default reduxStoreDialogsSlice;
