/*
 *
 */

import {Form as FormRff} from 'react-final-form';
import React from "react";


/**
 * @typedef {import("react-final-form/typescript/index").FormApi} formApi
 */

/**
 * Default Form Subscription
 * @type {Partial<import("react-final-form/typescript/index").FormSubscription>}
 */
export const defaultFormSubscription = {
    submitting: true,
    values: true,
    initialValues: true,
    submitErrors: true,
    submitError: true,
    submitSucceeded: true,
    submitFailed: true,
    hasSubmitErrors: true,
    invalid: true,
    valid: true,
    validating: true,
    hasValidationErrors: true,
};

/**
 *
 * @param {any} subscription the subscription of the FormRff
 * @param {string} id the ID of the <form /> tag
 * @param {string} className applied to the inner <form /> tag
 * @param autoFillValues applied to the inner <form /> tag
 * @param {import("react-final-form/typescript/index").FormProps &
 *     React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @param {function(import("react-final-form/typescript/index").RenderableProps)} children
 * @return {JSX.Element}
 */
const Form = ({
                  subscription = defaultFormSubscription,
                  children,
                  autoFillValues,
                  className,
                  id,
                  ...props
              }) => {
    return (
        <FormRff
            subscription={subscription}
            {...props}
            render={({handleSubmit, ...props}) => {
                return <form
                    className={className}
                    onSubmit={handleSubmit}
                    noValidate
                    autoComplete={autoFillValues}
                    id={id}>
                    {children(props)}
                </form>
            }}

        />

    );
}

export default Form;
