/*
 *
 */

import React from "react";

const VideoInformationViewDescriptionSection = ({data}) => {

    return (
        <>
            <div className={'description'}>
                {data?.description ?? ""}
            </div>
        </>
    )
}

export default VideoInformationViewDescriptionSection;
