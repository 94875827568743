/*
 *
 */

/**
 * The wrapper service that extends the functionality of the global localStorage.
 */
class LocalStorageService {
    /**
     * The keys used in the localStorage for storage of the values.
     */
    static readonly keys = {
        token: "bt",
        tokenExpiration: "te",
        userInfo: 'liui',
        theme: 'th',
        cachedUrl: 'curl',
    }

    /**
     * Saves data into local storage
     */
    static set(name: string, value: string) {
        return localStorage.setItem(name, value);
    };

    /**
     * Extracts the data from local storage
     */
    static get(name: string | string[]): any | Array<any> {
        if (typeof name === "string") {
            return localStorage[name];
        }
        if (Array.isArray(name)) {
            return name.map(e => localStorage[e]);
        }
    };

    /**
     * Removes the key/value pair with the given key from the list associated with the object,
     * if a key/value pair with the given key exists.
     */
    static remove(name: string) {
        return localStorage.removeItem(name);
    }

    /**
     * Returns the current value associated with the given key, or null if the given key does not exist in the single
     * associated with the object.
     */
    static removeAll() {
        return localStorage.clear();
    }

    /**
     * Returns all the available data from the local storage
     * @return {Record<string, any>} all the values aggregated in an object.
     */
    static getAll(): Record<string, any> {
        const keys = LocalStorageService.keys;
        const get = LocalStorageService.get;
        let result: any = {};
        for (let key in keys) {
            result[key] = get(key);
        }
        return result;
    }
}

export default LocalStorageService;

