/*
 *
 */

import {ApiHeaderNames, apiMethods} from "../../../../constants/enums";
import ApiResponseModels from "./models/responses";
import axios, {AxiosError, AxiosResponse} from "axios";
import ApiResponseUtils from "../../helpers/api-response-utils";
import EnvService from "../../../env-service";
import ApiRequestModels from "./models/requests";

/**
 * This interface is the base for any api executor in this application.
 *
 * Any Interface that would need to handle a specific server's responses, would need to inherit from this base class
 * and implement its abstractions.
 */
abstract class BaseApiExecutor {
    protected effects!: Array<Function>;

    // ################################         PRE API CALL         ################################

    /**
     * Returns the header object that needs to be injected to the api call.
     * @return {Record<string, any>} the created header object
     */
    protected abstract _injectHeaders(): Record<string, any>;

    /**
     * Fetches the headers of the api call.
     *
     * @param {any} headers the extra headers that are sent to override the default ones.
     * @return {any} the created headers object.
     * @private
     */
    protected _createHeaders(headers: Record<string, any> = {}): {} {
        return {
            [ApiHeaderNames.contentType]: "application/json",
            [ApiHeaderNames.accessControlAllowOrigin]: "*",
            [ApiHeaderNames.accessControlAllowMethods]: "GET, PUT, POST, DELETE, OPTIONS",
            ...this._injectHeaders() ?? {},
            ...(headers ?? {}),
        };
    }

    /**
     * Assigns default values to the given request since it can be a partial version of the object.
     *
     * @param {ApiRequestModels.ApiRequest} request the request ot be filled.
     * @return {ApiRequestModels.ApiRequest} the filled request.
     * @protected
     */
    protected _fillRequest<D>(request: ApiRequestModels.ApiRequest<D>): ApiRequestModels.ApiRequest<D> {
        request.headers = this._createHeaders(request.headers ?? {});
        request.showErrorToast = request.showErrorToast ?? true;
        request.showSuccessToast = request.showSuccessToast ?? false;
        request.enforceAuth = request.enforceAuth ?? false;
        request.method = request.method ?? apiMethods.get;
        return request;
    }

    /**
     * Validates the given api request before sending any api calls.
     *
     * This method has a placeholder body of always validating. It is intended for any of the inheriting subclasses
     * to override this method for their specific validation this
     * @param {ApiRequestModels.ApiRequest} request
     * @return {ApiResponseModels.ApiResponse | undefined} undefined if valid, else an ApiResponse filled with the
     * error status / message
     */
    protected validateRequest<R, D>(request: ApiRequestModels.ApiRequest<D>)
        : ApiResponseModels.ApiResponse<R> | undefined {
        return undefined;
    }

    // ################################         API CALL         ################################

    /**
     * Executes the given request using Axios and returns an appropriate response.
     *
     * This method would always resolve the returned promise with a {ApiResponseModels.ApiResponse}.
     * @param {ApiRequestModels.ApiRequest} request
     * @return {ApiResponseModels.ApiResponse}
     */
    async execute<D, R>(request: ApiRequestModels.ApiRequest<D>): Promise<ApiResponseModels.ApiResponse<R>> {
        request = this._fillRequest<D>(request);
        const invalidRequestResponse = this.validateRequest<R, D>(request);
        if (!!invalidRequestResponse) {
            return invalidRequestResponse;
        }
        // TODO: remove in production
        let response: ApiResponseModels.ApiResponse<R>;
        try {
            response = this._onResponseReceived<R, D>(request, await axios.request<R, AxiosResponse<R, D>, D>(request));

        } catch (e: any) {
            response = this._onErrorReceived<R, D>(e, request);
        }
        return this.done<R, D>(request, response);
    }

    // ################################         POST API CALL         ################################

    /**
     * Creates the response object that is embedded in the ApiResponse object. This inner response exists due to
     * possibility of having different executors from different servers.
     *
     * @param {number} statusCode the status code of the response. Refers to a server statusCode.
     * @return {any} the created response.
     */
    protected abstract _createPlaceholderInternalResponse<R>(statusCode: number): R;

    /**
     * Creates the response object that is embedded in the ApiResponse object. This inner response exists due to
     * possibility of having different executors from different servers.
     *
     * @param {any} response the internal response that is to be wrapped or modified
     * @param {number} statusCode the status code of the response. Refers to a server statusCode.
     * @return {any} the created response which might be the argument given without any modification.
     */
    protected _createInternalResponse(response: any, statusCode: number): any {
        return response;
    };

    /**
     * Creates the api response wrapper that wraps the internal responses of child executors.
     *
     * @param {number | undefined} orderIndex the orderIndex of this response's request.
     * @param {number} statusCode the status code of the response. Refers to a server statusCode.
     * @param {any} internalResponse the optional internal response.
     * @return {ApiResponseModels.ApiResponse} the created response.
     * @protected
     */
    protected _createApiResponse<R>(
        orderIndex: number | undefined,
        statusCode: number,
        internalResponse?: R,
    ): ApiResponseModels.ApiResponse {
        return {
            statusCode: statusCode,
            orderIndex: orderIndex ?? 0,
            response: !!internalResponse
                ? this._createInternalResponse(internalResponse, statusCode)
                : this._createPlaceholderInternalResponse<R>(statusCode),
        };
    }

    /**
     * Determines the cause of the api execution error and handles each case accordingly.
     *
     * @param {AxiosError} error the Axios error object.
     * @param {ApiRequestModels.ApiRequest} request the request that was sent with the api call.
     * @return {ApiResponseModels.ApiResponse} a generic response object that contains the information about the
     * failing of the api execution.
     */
    protected abstract _onAxiosErrorReceived<R, D>(error: AxiosError<R, D>, request: ApiRequestModels.ApiRequest<D>)
        : ApiResponseModels.ApiResponse<R>;

    /**
     * Determines the cause of the api execution error and handles each case accordingly.
     *
     * @param {AxiosError} error the error object. It is possible that it is not of type AxiosError, though very
     * unlikely.
     * @param {ApiRequestModels.ApiRequest} request the request that was sent with the api call.
     * @return {ApiResponseModels.ApiResponse} a generic response object that contains the information about the
     * failing of the api execution.
     * @private
     */
    private _onErrorReceived<R, D>(error: AxiosError & {}, request: ApiRequestModels.ApiRequest<D>)
        : ApiResponseModels.ApiResponse<R> {
        if (EnvService.isDevelopment) {
            console.error('Api Error:', error);
        }
        if (error.response) {
            // Request made and server responded
            return this._onAxiosErrorReceived(error, request);
        }
        if (error.request) {
            if (error.message === 'canceled') {
                // canceled message is for abortions
                return this._createApiResponse<R>(request.orderIndex, ApiResponseUtils.aborted.code);
            }
            // The request was made but no response was received
            return this._createApiResponse<R>(request.orderIndex, ApiResponseUtils.serverNotResponded.code);
        }

        // Something happened in setting up the request that triggered an Error
        return this._createApiResponse<R>(request.orderIndex, ApiResponseUtils.requestFailed.code);
    };

    /**
     * Checks the validity of the axios response against this executor's constraints and returns the response
     * extracted from the AxiosResponse.
     *
     * @param {ApiRequestModels.ApiRequest} request the request that was sent with the api call.
     * @param {AxiosResponse} response the axios response object.
     * @return {ApiResponseModels.ApiResponse} the same response given in the request.
     */
    protected abstract _onResponseReceived<R, D>(request: ApiRequestModels.ApiRequest<D>, response: AxiosResponse<R, D>)
        : ApiResponseModels.ApiResponse<R>;

    /**
     * Returns the given response, and calls any associated side effects that are registered in this executor.
     *
     * @param {ApiRequestModels.ApiRequest} request the request that was sent with the api call.
     * @param {AxiosResponse} response the response from execution of the api
     * @return {ApiResponseModels.ApiResponse} the same object given as the response arg.
     */
    protected done<R, D>(request: ApiRequestModels.ApiRequest<D>, response: ApiResponseModels.ApiResponse<R>)
        : ApiResponseModels.ApiResponse<R> {
        for (const effect of this.effects ?? []) {
            effect(request, response);
        }
        return response;
    }
}

export default BaseApiExecutor;
