/*
 *
 */

import {useCallback, useMemo} from "react";
import {
    createSearchParams,
    useLocation,
    useNavigate,
    useNavigationType,
    useParams,
    useSearchParams
} from "react-router-dom";

/**
 * Hook that aggregates React-router-dom hooks.
 *
 * The return object is :
 *
 * ```javascript
 * {
 *      query: {([p: string]: string[]){}},
 *      setQuery: function(Record<string, any>): void,
 *      location: Location<LocationState>,
 *      navigate: NavigationFunction,
 *      params: Record<string, any>,
 *      navigationType: NavigationType,
 * }
 * ```
 */
const useRouter = () => {
    const params = useParams();
    const navigateRaw = useNavigate();
    const navigationType = useNavigationType();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    /**
     * if any of the entries of the searchParams contains null or undefined, then removes it from the result.
     *
     * @param {Record<string, any> | undefined} searchParams
     * @return {Record<string, any>}
     * @private
     */
    const _filterSearchParams = (searchParams = {}) => {
        const result = {};
        const params = Object.entries(searchParams ?? {});
        for (const [key, value] of params) {
            if (value !== null && value !== undefined) {
                result[key] = value;
            }
        }
        return result;
    }

    /**
     * Sets the query of the url with the given searchParams.
     *
     * if any of the entries of the searchParams contains null or undefined, then removes it from the result.
     * @param {Record<string, string | any>} searchParams the given search params to be set.
     * @param {({replace?: boolean | undefined, state?: any} | undefined)} navigateOptions options in NavigateFunction
     * @private
     */
    const _setQuery = (searchParams = {}, navigateOptions = undefined) => {
        setSearchParams(_filterSearchParams(searchParams), navigateOptions);
    }

    /**
     * Sets the query of the url with the given searchParams.
     *
     * if any of the entries of the searchParams contains null or undefined, then removes it from the result.
     * @param {Record<string, string | any>} searchParams the given search params to be set.
     * @param {({replace?: boolean | undefined, state?: any} | undefined)} navigateOptions options in NavigateFunction
     * @private
     */
    const setQuery = useCallback(_setQuery, [setSearchParams])

    /**
     * Wraps the navigate function by injecting query search params.
     *
     * @param {string | number | Partial<import('react-router-dom').Path>} to
     * @param {import('react-router-dom').NavigateOptions} options
     * @param {Record<string, any> | undefined} searchParams
     * @private
     */
    const _navigate = (to, options = undefined, searchParams = undefined) => {
        if (typeof to === 'number') {
            return navigateRaw(to);
        }
        searchParams = _filterSearchParams(searchParams);
        searchParams = Object.keys(searchParams ?? {})?.length
            ? {search: `?${createSearchParams(searchParams)}`,}
            : {}
        if (typeof to === 'string') {
            return navigateRaw({
                pathname: to,
                ...searchParams,
            })
        }
        if (typeof to === 'object') {
            return navigateRaw({
                ...to,
                ...searchParams,
            })
        }
    }

    /**
     * Wraps the navigate function by injecting query search params.
     *
     * @param {string | number | Partial<import('react-router-dom').Path>} to
     * @param {import('react-router-dom').NavigateOptions} options
     * @param {Record<string, any> | undefined} query
     */
    const navigate = useCallback(_navigate, [navigateRaw])

    return useMemo(() => {
        return {
            query: Object.fromEntries([...searchParams]),
            setQuery: setQuery,
            params: {...(params ?? {})},
            location,
            navigate,
            navigationType,
        };
    }, [params, navigate, location, searchParams, setQuery, navigationType]);
}

export default useRouter;
