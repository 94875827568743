/*
 *
 */

/** api methods */
export const apiMethods = {
    get: 'GET' as const,
    post: 'POST' as const,
    put: 'PUT' as const,
    delete: 'DELETE' as const,
    patch: 'PATCH' as const,
};

/**
 * The available OSs for the application.
 */
export const OSs = {
    phone: 'mobile',
    web: 'web',
}

/**
 * The header names used in the api calls of the application
 */
export const ApiHeaderNames = {
    accessControlAllowOrigin: 'Access-Control-Allow-Origin' as const,
    accessControlAllowMethods: 'Access-Control-Allow-Methods' as const,
    contentType: 'Content-Type' as const,
    authorization: 'Authorization' as const,
};

/**
 * Color theme of the application.
 * Note: it is recommended not to change the value of 'light' and 'dark' as the values are also used by:
 *      1. the browsers' system preference
 *      2. bootstrap components
 *      3. material ui theme
 */
export const ColorThemes = {
    light: 'light' as const,
    dark: 'dark' as const,
}

/**
 * the different available logo types in the app.
 */
export const LogoTypes = {
    primary: 'primary' as const,
    secondary: 'secondary' as const,
}

/**
 * The available input types in the app.
 */
export const InputTypes = {
    email: "email" as const,
    password: "password" as const,
    text: "text" as const,
    number: "number" as const,
    phone: "tel" as const,
    oneTimeCode: "one-time-code" as const,
}


/**
 * Possible values for the autocomplete property of the inputs that would help browsers autofill cached values into
 * these inputs.
 *
 * [read more]{@link https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete}
 */
export const InputAutoFillNames = {
    givenName: "given-name" as const,
    familyName: 'family-name' as const,
    email: 'email' as const,
    username: 'username' as const,
    newPassword: 'new-password' as const,
    currentPassword: 'current-password' as const,
    oneTimeCode: "one-time-code" as const,
    tel: "tel" as const,
    birthday: "bday" as const,
}

/**
 * The possible values of the keyboardEvent.key property.
 *
 * [read more]{@link https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key}
 */
export const KeyboardEventKeys = {
    enter: 'Enter' as const,
}

/**
 * The different types of home-items-container in the home view.
 */
export const HomeViewItemTypes = {
    inProgress: 'inProgress' as const,
    saved: 'saved' as const,
    videos: 'videos' as const,
    courses: 'courses' as const,
}

/**
 * Available Types of the course card.
 */
export const CourseCardTypes = {
    medium: 'medium' as const,
    large: 'large' as const,
}

/**
 * Available Types of the video card.
 */
export const VideoCardTypes = {
    small: 'small' as const,
    medium: 'medium' as const,
}

/**
 * Different types of content search section. This enum effects the rendering of the inputs in the form.
 */
export const ContentSearchSectionTypes = {
    videos: 'videos' as const,
    courses: 'courses' as const,
    all: 'all' as const,
}


/**
 * The Types of Video player uis.
 */
export const VideoPlayerTypes = {
    small: 'small' as const,
    large: 'large' as const,
}

/**
 * The content search's type dropdown values
 */
export const ContentSearchTypeValues = {
    video: "Video" as const,
    course: "Course" as const,
}
