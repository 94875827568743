/*
 *
 */

import {generatePath} from "react-router-dom";
import {Params} from "react-router";

/**
 * The map of all the available AppRoutes in the system
 */
class AppRoutes {
    /**
     * Public Views
     */
    static public = {
        error: {
            _base: '/error/*',
            _relative: {
                notFound: 'notFound',
                accessDenied: 'accessDenied',
                serverError: 'serverError',
            },
            notFound: '/error/notFound',
            accessDenied: '/error/accessDenied',
            serverError: '/error/serverError',
        },
        auth: {
            _base: '/auth/*',
            _relative: {
                login: 'login/:token/:time',
                logout: 'logout',
                redirect: 'redirect',
            },
            login: {
                base: '/auth/login',
                withParams: '/auth/login/:token/:time',
            },
            logout: '/auth/logout',
            redirect: '/auth/redirect',
        },
    };
    /**
     * Private Views
     */
    static private = {
        landing: '/',
        _base: '/*',
        _relative: {
            home: 'home',
            search: 'search',
            courses: 'courses/*',
            videos: 'videos/*',
        },
        home: '/home',
        search: '/search',
        courses: {
            _base: '/courses/*',
            _relative: {
                list: 'list',
                single: {
                    course: ':courseId',
                    video: ':courseId/video/:id',
                },
            },
            list: '/courses/list',
            single: {
                _base: '/courses/:courseId/*',
                base: '/courses/:courseId',
                video: '/courses/:courseId/video/:id',
            },
        },
        videos: {
            _base: '/videos/*',
            _relative: {
                list: 'list',
                single: ':id',
            },
            list: '/videos/list',
            single: '/videos/:id',
        },
    };

    /**
     * Generates the path of the given AppRoutes in the system that include route parameters.
     * @param {string} route
     * @param {Record<string, string | number>} params
     * @return {string} the generated path
     */
    static generateRoute(route: string, params?: Params | undefined): string {
        return generatePath(route, params)
    }
}


export default AppRoutes;

