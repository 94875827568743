/*
 *
 */

import React from "react";
import classnames from "classnames";
import {Checkbox as MuiCheckbox} from '@mui/material';
import {Checkboxes as FormCheckbox} from 'mui-rff';

/**
 * @param {boolean} form whether this input is a form input or not.
 * @param {string} classNameProp
 * @param {Partial<import("mui-rff").CheckboxesProps> & Partial<import("@mui/material").CheckboxProps>} props
 */
const Checkbox = ({form = false, classNameProp, ...props}) => {
    const className = classnames(classNameProp, 'checkbox');

    return (
        <>
            {
                form
                    ? <FormCheckbox
                        className={className}
                        {...props}
                    />
                    : <MuiCheckbox
                        className={className}
                        {...props}
                    />
            }
        </>
    );
}

export default Checkbox;
