/*
 *
 */

import React from "react";
import {Button} from "@mui/material";
import useRouter from "../../../../hooks/use-router";

const NotFoundErrorView = () => {
    const {navigate} = useRouter();

    /**
     * Navigates the user to the landing page.
     */
    const back = () => navigate(-1);

    return (
        <>
            <h1>
                <span>404</span>
            </h1>
            <p>
                We searched high and low but couldn't find what you're looking for.
                <br/>
                Lets find a better place for you to go.
            </p>
            <Button
                onClick={back}
                className={'button'}>
                Go Back
            </Button>
        </>
    )
}

export default NotFoundErrorView;
