/*
 *
 */

import React, {useMemo} from "react";
import {Select as FormSelect} from "mui-rff";
import {MenuItem, Select as MuiSelect} from "@mui/material";
import {KeyboardArrowDownOutlined} from "@mui/icons-material";

/**
 * @typedef {(import("mui-rff").SelectData & {key: string})[]} SelectData
 */

/**
 * @param {boolean} form whether this input is a form input or not.
 * @param {boolean} fullWidth
 * @param {SelectData} dataProps
 * @param {import("@mui/material").MenuItemProps} menuItemProps
 * @param props
 */
const Select = ({form = false, fullWidth = true, data: dataProps, menuItemProps = {}, ...props}) => {
    const size = 'medium';
    const variant = 'outlined';

    const data = useMemo(() => dataProps.map((item) => (
        <MenuItem
            value={item.value}
            key={item.key}
            disabled={item.disabled}
            {...(menuItemProps)}>
            {item.label}
        </MenuItem>
    )), [dataProps, menuItemProps]);


    return (
        <>
            {
                form
                    ? <FormSelect
                        size={size}
                        fullWidth={fullWidth}
                        variant={variant}
                        IconComponent={KeyboardArrowDownOutlined}
                        {...props}>
                        {data}
                    </FormSelect>
                    : <MuiSelect
                        variant={variant}
                        size={size}
                        fullWidth={fullWidth}
                        IconComponent={KeyboardArrowDownOutlined}
                        {...props}>
                        {data}
                    </MuiSelect>
            }
        </>
    )
}

export default Select;
