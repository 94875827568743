/*
 *
 */

import React from "react";
import {Button} from "@mui/material";
import useRouter from "../../../../hooks/use-router";

const AccessDeniedErrorView = () => {
    const {navigate} = useRouter();

    /**
     * Navigates the user to the landing page.
     */
    const back = () => navigate(-1);

    return (
        <>
            <h1>
                <span>403</span>
            </h1>
            <p>
                Sorry, but your do not have access to this section!
                <br/>
                Contact our technical support in case of any problems.
            </p>
            <Button
                onClick={back}
                className={'button'}>
                Go Back
            </Button>
        </>
    )
}

export default AccessDeniedErrorView;
