/*
 *
 */

import Utils from "../utils";


/**
 * The interface that is responsible for providing functionality to inject Color properties into a styleSheet in a
 * web-app.
 */
abstract class ColorInjector {
    static readonly important: string = 'Important';
    static readonly prefix: string = '--app-';
    static readonly suffixes: Array<string> = [
        '',
        '--rgb',
        '--rgba',
    ];

    /**
     * Fetches the selector that is used for injecting the css theme.
     */
    protected abstract getThemeCssSelector(): string;

    /**
     * Transforms the given property to a valid css name.
     *
     * the format of the name is as follows:
     * 1.   --app-[property that is snakeCase with -'s instead of _'s]
     * 2.   --app-[property that is snakeCase with -'s instead of _'s]-rgb
     * 3.   --app-[property that is snakeCase with -'s instead of _'s]-rgba
     * @param {string} property the property to be transformed
     * @return {Array<string>} the array of strings for the css names.
     * @private
     */
    private static _transformPropertyNameToCss(property: string): Array<string> {
        // remove the important flag in case it exists.
        property = property.replace(this.important, '');
        const parsed: string = property.replaceAll(new RegExp(/[A-Z]/g), (string: string) => `-${string.charAt(0).toLowerCase()}`);
        return this.suffixes.map(e => this.prefix.concat(parsed).concat(e));
    }

    /**
     * Decomposes the value of the given color to be used in the application.
     *
     * the format of the value is as follows:
     * 1.   rgba(r, g, b, a)
     * 2.   r, g, b, a
     * 3.   r, g, b
     * @param {string} value the value to be decomposed.
     * @return {Array<string>} the array of strings for the css values.
     * @private
     */
    private static _decomposeColorValues(value: string): Array<string> {
        const rgbaValue = value.replaceAll(/(rgba|rgb|\(|\))/g, '');
        const rgbValue = rgbaValue.substring(0, rgbaValue.lastIndexOf(','));
        return [
            value,
            rgbValue,
            rgbaValue,
        ];
    }

    /**
     * Creates the property colors to be used in the generation.
     *
     * For each of the given property, value, creates the property, stripped rgb property amd stripper rgba property.
     * @param {string} property
     * @param {Array<Array<string | boolean>>} propertyValue
     * @private
     */
    private static _createPropertyColors(property: string, propertyValue: string): Array<Array<string | boolean>> {
        const [name, rgbName, rgbaName] = ColorInjector._transformPropertyNameToCss(property);
        const [value, rbaValue, rgbaValue] = ColorInjector._decomposeColorValues(propertyValue);
        const important = property.includes(this.important);
        return [
            [name, value, important],
            [rgbName, rbaValue, important],
            [rgbaName, rgbaValue, important],
        ]
    }

    /**
     * Creates the theme Array to be injected in to the css stylesheet.
     * @return {any[]} the array associated with the theme.
     * @private
     */
    private _createTheme(): Array<any> {
        const properties: any[] = [];
        for (let property in this) {
            if (!this.hasOwnProperty(property)) continue;
            properties.push(...ColorInjector._createPropertyColors(property, `${this[property]}`))
        }
        return [this.getThemeCssSelector(), ...properties];
    }

    /**
     * Injects the colors of this class into the color-themes css file for dynamic color resolving.
     *
     * @param {boolean} noSideEffects whether to skip the injection part and return the theme and scssOutput instead.
     * @return the theme if noSideEffects or undefined.
     */
    injectTheme(noSideEffects: boolean = false): Array<any> | undefined {
        const theme = this._createTheme();
        if (noSideEffects) {
            return theme;
        }
        Utils.addStylesheetRules([theme]);
    }
}

export default ColorInjector;
