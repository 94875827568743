/*
 *
 */

import React, {useMemo} from "react";
import {CoursesQueryParams} from "../../../../core/constants/query-params";
import Form from "../../base/form";
import {Col, Row} from "react-bootstrap";
import Input from "../../base/input";
import {InputTypes} from "../../../../core/constants/enums";
import Select from "../../base/select";
import {LoadingButton} from "@mui/lab";
import {ContentSearchFormKeys} from "../../search-sections/content";

const ContentSearchSectionCoursesForm = ({validate, required, initialValues, submit, platforms}) => {
    /** @type {SelectData}*/
    const platformsData = useMemo(() => platforms?.map(platform => ({
        key: platform.id,
        value: platform.id,
        label: platform.name,
    })), [platforms])

    /**
     * Invokes the "submit" callback with the created query data from the given values.
     *
     * @param {Record<string, any>} values
     * @return {Promise<void>}
     */
    const obSubmit = async (values) => {
        const query = {
            [CoursesQueryParams.keywords]: values[ContentSearchFormKeys.keywords],
            [CoursesQueryParams.platform]: values[ContentSearchFormKeys.platform],
        }
        submit(query);
        return Promise.resolve();
    }

    return (
        <>
            <Form
                onSubmit={obSubmit}
                validate={validate}
                initialValues={initialValues}>
                {({submitting}) => (
                    <Row>
                        <Col xs={12}>
                            <Input
                                form
                                name={ContentSearchFormKeys.keywords}
                                type={InputTypes.text}
                                required={required[ContentSearchFormKeys.keywords]}
                                label={"Keywords"}
                            />
                        </Col>
                        <Col xs={12} className={'mt-3'}>
                            <Select
                                form
                                name={ContentSearchFormKeys.platform}
                                required={!!required[ContentSearchFormKeys.platform]}
                                label={'Platform'}
                                data={platformsData}
                            />
                        </Col>
                        <Col xs={12} className={'mt-4 d-flex flex-column align-items-center'}>
                            <LoadingButton
                                loading={submitting}
                                color={'secondary'}
                                type={'submit'}
                                className={'button m-auto'}
                                loadingIndicator={submitting ? "Searching..." : undefined}>
                                Search
                            </LoadingButton>
                        </Col>
                    </Row>
                )}
            </Form>
        </>
    )
}

export default ContentSearchSectionCoursesForm;
