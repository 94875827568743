/*
 *
 */

import React from "react";
import {Link} from "react-router-dom";
import classnames from "classnames";
import LazyImage from "../../../lazy-lmage";
import {ReactComponent as PlayIcon} from "../../../../../../assets/images/card-play.svg";
import moment from "moment";

const LargeCourseCard = ({loading, data, to}) => {

    return (
        <>
            <Link
                to={to}
                className={classnames('app-card course-card large', {loading})}>
                {
                    loading
                        ? <>
                            <div>
                                <div/>
                            </div>
                            <div>
                                <div/>
                                <div/>

                                <section>
                                    <div/>
                                    <div/>
                                </section>

                                <div/>
                                <div/>
                                <div/>
                                <div/>
                                <div/>
                                <div/>
                                <div/>
                                <div/>

                                <div/>
                            </div>
                        </>
                        : <>
                            <div className={'cover'}>
                                <LazyImage
                                    src={data?.coverImageUrl ?? ''}
                                    alt={data?.title ?? ''}
                                />
                                <div className={'play-icon'}>
                                    <PlayIcon/>
                                </div>
                            </div>
                            <div className={'content'}>
                                <p className={'title'}>
                                    {data?.title ?? ''}
                                </p>
                                <div className={'course-info'}>
                                    <p>
                                        <span>{data?.videoCount ?? 0}</span>
                                        {(data?.videoCount ?? 0) === 1 ? 'Video' : 'Videos'}
                                    </p>
                                    <p>
                                        {
                                            data?.modifiedDateTime
                                                ? moment(data.modifiedDateTime).format('DD MMM YYYY')
                                                : ''
                                        }
                                    </p>
                                </div>
                                <p className={'description'}>
                                    {data?.description ?? ''}
                                </p>
                                <div className={'platform'}>
                                    {data?.application?.name ?? ''}
                                </div>
                            </div>
                        </>
                }
            </Link>
        </>
    );
}

export default LargeCourseCard;
