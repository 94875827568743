/*
 *
 */

import React, {useEffect, useMemo, useState} from "react";
import ContentSearchSection from "../../../../components/search-sections/content";
import useIsMounted from "../../../../hooks/use-is-mounted";
import useRouter from "../../../../hooks/use-router";
import {CoursesQueryParams} from "../../../../../core/constants/query-params";
import Utils from "../../../../../core/services/utils";
import CourseCard from "../../../../components/app-specific/course-card";
import {Col, Container, Row} from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroller";
import {ContentSearchSectionTypes, CourseCardTypes} from "../../../../../core/constants/enums";
import TryAgain from "../../../../components/app-specific/try-again";
import {BizLearnApi} from "../../../../../core/services/api";

const initialPaginationInfo = {
    pageSize: 12,
    currentPage: 0,
    total: 0,
}

const CoursesListView = () => {
    const {query, setQuery} = useRouter();
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState({});
    const [paginationInfo, setPaginationInfo] = useState(initialPaginationInfo);
    const [loading, setLoading] = useState(true);
    const isMounted = useIsMounted();

    const canLoadMore = !loading && (paginationInfo?.pageSize ?? 0) * (paginationInfo?.currentPage ?? 0) < (paginationInfo?.total ?? 0)
    const breakpoints = useMemo(() => ({
        xs: 12,
        md: 6,
        lg: 4,
        xl: 3,
    }), [])

    /**
     * With each change in query:
     * - checks any changes in the filters, and if there are changes, resets the pagination and data so that the new
     * search fills the data again
     */
    useEffect(() => {
        const filters = hasFiltersChanged();
        if (filters) {
            setPaginationInfo(initialPaginationInfo);
            setData([]);
        }
    }, [query])

    /**
     * With each change in paginationInfo's currentPage:
     * - if currentPage is 0, it indicates that there was a reset in paginationInfo, or we are in the initial
     * render, so we just change the currentPage to 1
     * - otherwise we search
     */
    useEffect(() => {
        if (!paginationInfo?.currentPage) {
            return setPaginationInfo(prevState => ({...prevState, currentPage: 1}));
        }
        search().then();
    }, [paginationInfo?.currentPage])

    /**
     * Determines if the filters in the query are different from the saved filters in the state.
     *
     * * in case of any difference, the state is updated and the updated values are then returned.
     * @return {{}}
     */
    const hasFiltersChanged = () => {
        const newFilters = {
            [CoursesQueryParams.keywords]: query[CoursesQueryParams.keywords],
            [CoursesQueryParams.platform]: query[CoursesQueryParams.platform],
        }
        if (!Utils.deepEqual(filters, newFilters)) {
            setFilters(newFilters);
            return newFilters;
        }
    }

    /**
     * Searches among all the courses of this system with the provided filters and paginationInfo.
     *
     * - if the result of the api is successful, sorts the retrieved data based on their date, and appends them to
     * the state list. Also updates the total contents count for pagination purposes.
     */
    const search = async () => {
        /**@type {BizLearnApiRequestModels.Course.Search}*/
        const forApi = {
            keyword: filters[CoursesQueryParams.keywords],
            applicationId: filters[CoursesQueryParams.platform],
            pageIndex: paginationInfo.currentPage,
            pageSize: paginationInfo.pageSize,
        }
        setLoading(true);
        const response = await BizLearnApi.searchCourses(forApi);
        if (!isMounted()) return;
        setLoading(false);
        if (response?.resultFlag) {
            setPaginationInfo(prevState => ({
                ...prevState,
                total: response?.data?.totalCount ?? 0,
            }))
            const data = response?.data.items
                    ?.map(course => ({
                        ...course,
                        coverImageUrl: (response?.configuration?.courseCoverBaseURL ?? "") + course.coverImageUrl ?? "",
                    }))
                    ?.sort((a, b) => Utils.dateComparator(b.modifiedDateTime, a.modifiedDateTime))
                ?? []
            setData(prevState => [...prevState, ...data]);
        }
    }

    /**
     * Increases the paginationInfo's current page to trigger the search function and to load more data.
     */
    const loadMore = () => {
        if (loading) return;
        setLoading(true);
        setPaginationInfo(prevState => ({...prevState, currentPage: prevState.currentPage + 1}));
    }


    /**
     * Resets the paginationInfo and data so that the search is triggered again.
     */
    const searchAgain = () => {
        setPaginationInfo(initialPaginationInfo);
        setData([]);
    }

    /**
     * Removes the filters from the url queries. This ultimately triggers the search function as well.
     */
    const removeFilters = () => {
        setQuery({}, {replace: true});
    }

    /** @type {ReactNode} */
    const loadingItems = useMemo(() => {
        if (!loading) {
            return <></>;
        }
        return (
            <>
                {Array(initialPaginationInfo.pageSize).fill(null).map((_, e) => (
                    <Col key={`loading-${e}`} {...breakpoints}>
                        <CourseCard loading type={CourseCardTypes.medium}/>
                    </Col>
                ))}
                <div className={'mt-5'}/>
            </>
        );
    }, [loading, breakpoints])

    /** @type {ReactNode} */
    const items = useMemo(() => {
        return (
            <>
                {data?.map(course => (
                    <Col key={course.id}
                         className={'item'}
                         {...breakpoints}>
                        <CourseCard
                            type={CourseCardTypes.medium}
                            data={course}
                        />
                    </Col>
                ))}
            </>
        );
    }, [data, breakpoints])

    return (
        <>
            <div className={'courses-list-view'}>
                <ContentSearchSection type={ContentSearchSectionTypes.courses} filters={filters}/>
                <div className={'spacer'}/>
                <Container>
                    {
                        !loading && !data?.length ?
                            (
                                !Object.keys(query).length
                                    ? <TryAgain
                                        text={'There seems to be no courses available'}
                                        buttonText={'Search Again'}
                                        onClick={searchAgain}
                                    />
                                    : <TryAgain
                                        text={'There are no results with the given search filters.'}
                                        buttonText={'Remove Filters'}
                                        onClick={removeFilters}
                                    />
                            )
                            : <>
                                <InfiniteScroll
                                    className='row'
                                    pageStart={1}
                                    loadMore={loadMore}
                                    hasMore={canLoadMore}
                                    useWindow
                                    threshhold={400}>
                                    {items}
                                </InfiniteScroll>
                                <Row>
                                    {loadingItems}
                                </Row>
                            </>
                    }
                </Container>
                <div className={'spacer'}/>
            </div>
        </>
    )
}

export default CoursesListView;
