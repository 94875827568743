/*
 *
 */

import React from "react";
import {Button} from "@mui/material";
import {Col, Row} from "react-bootstrap";
import classnames from "classnames";

const HomeItemsContainer = ({title, onMoreClicked, items, breakpoints = {}, loadingState, component: Component}) => {

    return (
        <>
            <div className={classnames('home-items-container', {loading: loadingState?.loading})}>
                <div className={'header'}>
                    {
                        loadingState?.loading
                            ? <>
                                <div/>
                                <div/>
                            </>
                            : <>
                                <div className={'title'}>
                                    <p>
                                        {title ?? ''}
                                    </p>
                                </div>
                                <Button
                                    color={'primary'}
                                    className={'button'}
                                    onClick={() => onMoreClicked && onMoreClicked()}>
                                    More
                                </Button>
                            </>
                    }
                </div>
                <Row className={classnames('content')}>
                    {
                        [...loadingState?.loading
                            ? Array(loadingState?.count).fill(null)
                            : (items ?? [])
                        ].map((item, index) => (
                            <Col
                                key={item?.key ?? index}
                                className={'item'}
                                {...(breakpoints ?? {})}>
                                {
                                    loadingState?.loading || item
                                        ? <Component
                                            loading={loadingState?.loading}
                                            {...(item?.props ?? {})}
                                        />
                                        : <></>
                                }
                            </Col>
                        ))
                    }
                </Row>
            </div>
        </>
    )
}

export default HomeItemsContainer;
