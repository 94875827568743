/*
 *
 */

import ApiPoolExecutor from "../../../helpers/pool-executor";
import ApiRequestModels, {ApiRequestList} from "../models/requests";

/**
 * The base class mixins that would inject the executor and type safe execution methods into its inheriting classes.
 *
 * This class is only meant to be inherited from and not used directly in the app.
 */
abstract class BaseApiMixin {
    /**
     * The PoolExecutor that runs the api calls. The inheriting classes must instantiate a state executor.
     * @protected
     */
    protected static readonly poolExecutor: ApiPoolExecutor;


    /**
     * Invokes a single request or a series of request synchronously in a sense that one request must be resolved
     * before the next is invoked.
     * @param {ApiRequestModels.ApiRequest | ApiRequestList} requests
     * @return {Promise<any[] | any>}
     */
    static sync<R = any>(requests: ApiRequestModels.ApiRequest): Promise<R>;
    /**
     * Invokes a single request or a series of request synchronously in a sense that one request must be resolved
     * before the next is invoked.
     * @param {ApiRequestModels.ApiRequest | ApiRequestList} requests
     * @return {Promise<any[] | any>}
     */
    static sync<R1 = any, R2 = R1>(...requests: ApiRequestList): Promise<[R1, R2]>;
    /**
     * Invokes a single request or a series of request synchronously in a sense that one request must be resolved
     * before the next is invoked.
     * @param {ApiRequestModels.ApiRequest | ApiRequestList} requests
     * @return {Promise<any[] | any>}
     */
    static sync<R1 = any, R2 = R1, R3 = R2>(...requests: ApiRequestList): Promise<[R1, R2, R3]>;
    /**
     * Invokes a single request or a series of request synchronously in a sense that one request must be resolved
     * before the next is invoked.
     * @param {ApiRequestModels.ApiRequest | ApiRequestList} requests
     * @return {Promise<any[] | any>}
     */
    static sync<R1 = any, R2 = R1, R3 = R2, R4 = R3>(...requests: ApiRequestList): Promise<[R1, R2, R3, R4]>;
    /**
     * Invokes a single request or a series of request synchronously in a sense that one request must be resolved
     * before the next is invoked.
     * @param {ApiRequestModels.ApiRequest | ApiRequestList} requests
     * @return {Promise<any[] | any>}
     */
    static sync<R1 = any, R2 = R1, R3 = R2, R4 = R3, R5 = R4>(...requests: ApiRequestList): Promise<[R1, R2, R3, R4, R5]>;
    /**
     * Invokes a single request or a series of request synchronously in a sense that one request must be resolved
     * before the next is invoked.
     * @param {ApiRequestModels.ApiRequest | ApiRequestList} requests
     * @return {Promise<any[] | any>}
     */
    static sync<R = any>(requests: ApiRequestModels.ApiRequest | ApiRequestList): Promise<R[] | R> {
        return this.poolExecutor.aggregate(requests, false);
    }


    /**
     * Invokes a single request or a series of request asynchronously in a sense that all the requests are
     * invokes at the same time and the result is returned upon all of their completions.
     * @param {ApiRequestModels.ApiRequest | ApiRequestList} requests
     * @return {Promise<any[] | any>}
     */
    static async<R = any>(requests: ApiRequestModels.ApiRequest): Promise<R>;
    /**
     * Invokes a single request or a series of request asynchronously in a sense that all the requests are
     * invokes at the same time and the result is returned upon all of their completions.
     * @param {ApiRequestModels.ApiRequest | ApiRequestList} requests
     * @return {Promise<any[] | any>}
     */
    static async<R1 = any, R2 = R1>(...requests: ApiRequestList): Promise<[R1, R2]>;
    /**
     * Invokes a single request or a series of request asynchronously in a sense that all the requests are
     * invokes at the same time and the result is returned upon all of their completions.
     * @param {ApiRequestModels.ApiRequest | ApiRequestList} requests
     * @return {Promise<any[] | any>}
     */
    static async<R1 = any, R2 = R1, R3 = R2>(...requests: ApiRequestList): Promise<[R1, R2, R3]>;
    /**
     * Invokes a single request or a series of request asynchronously in a sense that all the requests are
     * invokes at the same time and the result is returned upon all of their completions.
     * @param {ApiRequestModels.ApiRequest | ApiRequestList} requests
     * @return {Promise<any[] | any>}
     */
    static async<R1 = any, R2 = R1, R3 = R2, R4 = R3>(...requests: ApiRequestList): Promise<[R1, R2, R3, R4]>;
    /**
     * Invokes a single request or a series of request asynchronously in a sense that all the requests are
     * invokes at the same time and the result is returned upon all of their completions.
     * @param {ApiRequestModels.ApiRequest | ApiRequestList} requests
     * @return {Promise<any[] | any>}
     */
    static async<R1 = any, R2 = R1, R3 = R2, R4 = R3, R5 = R4>(...requests: ApiRequestList): Promise<[R1, R2, R3, R4, R5]>;
    /**
     * Invokes a single request or a series of request asynchronously in a sense that all the requests are
     * invokes at the same time and the result is returned upon all of their completions.
     * @param {ApiRequestModels.ApiRequest | ApiRequestList} requests
     * @return {Promise<any[] | any>}
     */
    static async<R = any>(requests: ApiRequestModels.ApiRequest | ApiRequestList): Promise<R[] | R> {
        return this.poolExecutor.aggregate(requests, true);
    }
}

export default BaseApiMixin;
