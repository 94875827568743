/*
 *
 */

import React from "react";
import {Link} from "react-router-dom";
import classnames from "classnames";
import LazyImage from "../../../lazy-lmage";
import {CircularProgress, IconButton, Tooltip} from "@mui/material";
import {ReactComponent as PlayIcon} from "../../../../../../assets/images/card-play.svg";
import {ReactComponent as SavedIcon} from "../../../../../../assets/images/save/saved.svg";
import {ReactComponent as NotSavedIcon} from "../../../../../../assets/images/save/not-saved.svg";
import {ReactComponent as ModifiedDateIcon} from "../../../../../../assets/images/modified-date.svg";
import {ReactComponent as DurationIcon} from "../../../../../../assets/images/duration.svg";
import moment from "moment";

const SmallVideoCard = ({loading, data, to, toggleSaved, className}) => {

    return (
        <>
            <Link
                to={to}
                className={classnames('app-card video-card small', {loading}, className)}>
                {
                    loading
                        ? <>
                            <div>
                                <div/>
                            </div>
                            <div>
                                <div/>
                                <div/>
                                <span/>
                            </div>
                        </>
                        : <>
                            <div className={'cover'}>
                                <LazyImage
                                    src={data?.coverImageUrl ?? ''}
                                    alt={data?.title ?? ''}
                                />
                                <div className={'play-icon'}>
                                    <PlayIcon/>
                                </div>
                            </div>
                            <div className={'video-info'}>
                                <p className={'title'}>
                                    {data?.title ?? ''}
                                </p>
                                <div className={'flex-fill'}/>
                                <div className={'d-flex'}>
                                    {
                                        data?.lengthMinute &&
                                        <div className={'duration'}>
                                            <DurationIcon className={'d-inline-block icon'}/>
                                            <p>
                                                {(data?.lengthMinute ?? 0)} minutes
                                            </p>
                                        </div>
                                    }
                                    {
                                        data?.modifiedDateTime &&
                                        <div className={classnames('date', {
                                            'ms-3': !!data?.lengthMinute,
                                        })}>
                                            <ModifiedDateIcon className={'d-inline-block icon'}/>
                                            <p>
                                                {moment(data.modifiedDateTime).format('DD-MM-yyyy')}
                                            </p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <Tooltip
                                title={
                                    data?.saving
                                        ? 'saving...'
                                        : !data?.isSaved
                                            ? 'save'
                                            : 'un-save'
                                }>
                                <IconButton
                                    size={'small'}
                                    className={classnames('icon-button save', {'active': data?.isSaved})}
                                    onClick={toggleSaved}>
                                    {
                                        data?.saving
                                            ? <CircularProgress size={14} color={'secondary'}/>
                                            : data?.isSaved
                                                ? <SavedIcon className={'custom'}/>
                                                : <NotSavedIcon className={'custom'}/>
                                    }
                                </IconButton>
                            </Tooltip>
                        </>
                }
            </Link>
        </>
    );
}

export default SmallVideoCard;
