/*
 *
 */

import React from "react";
import {Link} from "react-router-dom";
import classnames from "classnames";
import LazyImage from "../../../lazy-lmage";
import {ReactComponent as PlayIcon} from "../../../../../../assets/images/card-play.svg";
import moment from "moment";

const MediumCourseCard = ({loading, data, to}) => {

    return (
        <>
            <Link
                to={to}
                className={classnames('app-card course-card medium', {loading})}>
                {
                    loading
                        ? <>
                            <div/>
                            <div/>
                            <div/>
                            <section>
                                <div/>
                                <div/>
                            </section>
                            <div/>
                        </>
                        : <>
                            <p className={'title'}>
                                {data?.title ?? ''}
                            </p>
                            <div className={'cover'}>
                                <LazyImage
                                    src={data?.coverImageUrl ?? ''}
                                    alt={data?.title ?? ''}
                                />
                                <div className={'play-icon'}>
                                    <PlayIcon/>
                                </div>
                            </div>
                            <div className={'course-info'}>
                                <p>
                                    <span>{data?.videoCount ?? 0}</span> Videos
                                </p>
                                <p>
                                    {
                                        data?.modifiedDateTime
                                            ? moment(data.modifiedDateTime).format('DD MMM YYYY')
                                            : ''
                                    }
                                </p>
                            </div>
                            <div className={'platform'}>
                                {data?.application?.name ?? ''}
                            </div>
                        </>
                }
            </Link>
        </>
    );
}

export default MediumCourseCard;
