/*
 *
 */

/**
 * the paths of the redux store.
 */
class ReduxStoreRoutes {
    static userInfo: string = 'user-info';
    static config: string = 'config';
    static header: string = 'header';
    static dialogs = {
        base: 'dialogs' as const,
        confirmation: 'auth' as const,
    };
}

export default ReduxStoreRoutes;
