/*
 *
 */

import React from "react";

const VideoInformationViewCaptionSection = ({data}) => {

    return (
        <>
            <div className={'caption'}>
                {data?.caption ?? ""}
            </div>
        </>
    )
}

export default VideoInformationViewCaptionSection;
