/*
 *
 */

import ApiResponseModels, {ApiResponseList} from "../entities/base/models/responses";
import Utils from "../../utils";
import BaseApiExecutor from "../entities/base/executor";
import ApiRequestModels, {ApiRequestList} from "../entities/base/models/requests";

/**
 * This interface is responsible for aggregating certain api requests and executing them altogether.
 *
 * It is customizable with different executes so that the execution process can be used dynamically. The executor
 * can only be injected to a pool executor through its .of() static method.
 */
class ApiPoolExecutor {
    private readonly executor: BaseApiExecutor;

    /**
     * Creates a new ApoPoolExecutor that has the given executor injected into its logic.
     * @param {BaseApiExecutor} executor
     * @return {ApiPoolExecutor} a new instance.
     */
    static of(executor: BaseApiExecutor): ApiPoolExecutor {
        return new ApiPoolExecutor(executor);
    }

    /**
     * Constructs a pool executor with the given executor as its logic handler.
     * @param {BaseApiExecutor} executor
     * @private
     */
    private constructor(executor: BaseApiExecutor) {
        this.executor = executor;
    }

    /**
     * Aggregates the given api requests and executes them based on the given mode.
     *
     * in both async and sync modes, the order of the returned responses remains the same as the order of the given
     * requests.
     * @param {ApiRequestList} requests list of api requests.
     * @param {boolean} async if true, executes all the requests simultaneously and then awaits for their completion
     * otherwise waits for each before executing the next one.
     */
    async aggregate<R>(requests: ApiRequestModels.ApiRequest | ApiRequestList, async: boolean = false): Promise<R[] | R> {
        if (!Array.isArray(requests)) {
            // if only a single request is passed.
            if (!requests) return Promise.resolve(Array<R>());
            return await this.executor.execute<any, any>(requests).then(r => r.response);
        }
        // multiple requests.
        if (!requests.length || !this.executor) {
            return Promise.resolve(Array<R>());
        }
        const sent: Promise<ApiResponseModels.ApiResponse<any>>[] = [];
        const responses: ApiResponseList<R> = [];
        for (let index = 0; index < requests.length; ++index) {
            const request = requests[index];
            if (async) {
                request.orderIndex = index;
                sent.push(this.executor.execute<any, any>(request));
            } else {
                responses.push(await this.executor.execute<any, any>(request));
            }
        }
        if (async) {
            responses.push(...await Promise.all(sent));
        }
        return responses
                .sort((a, b) => Utils.numComparator(a.orderIndex, b.orderIndex))
                .map(response => response.response)
            ?? [];
    }
}


export default ApiPoolExecutor;
