/*
 *
 */

/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useLayoutEffect, useState} from "react";
import ColorThemeService from "../../../core/services/color-theme-service";

/**
 * Hook for the color theme manipulation in the application.
 * @return {[("dark" | "light"), function(): void]} a list that contains the current theme and a callback for
 * chaining the theme.
 */
function useColorTheme() {
    const [theme, setTheme] = useState(ColorThemeService.theme);

    /**
     * As soon as the component mounts:
     * - Attaches an event listeners for the storage and with each dispatch, determines the current theme color.
     */
    useLayoutEffect(() => {
        window.addEventListener('storage', setColorTheme);
        return () => window.removeEventListener('storage', setColorTheme);
    }, [])

    /**
     * Sets the color theme of this component.
     * - for consistency, does not pass the new color theme to setColorTheme of ColorThemeService as it can fetch it
     */
    const setColorTheme = useCallback(() => {
        /**@type {InAppModels.SetColorThemeOptions}*/
        const options = {withDispatch: false, withTransition: true, ignorable: true};
        const newTheme = ColorThemeService.setColorTheme('', options);
        setTheme(newTheme);
    }, [])


    return [theme, setColorTheme];
}

export default useColorTheme;
