/*
 *
 */

/**
 * The sizes used in this application.
 */
class Sizes {

    // **************** APPLICATION BREAKPOINTS ****************

    static xs = 0;
    static sm = 576;
    static md = 768;
    static lg = 992;
    static xl = 1200;
    static xxl = 1400;
}

export default Sizes;

