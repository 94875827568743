/*
 *
 */

import {createSlice} from "@reduxjs/toolkit";
import ReduxStoreRoutes from "../../../core/models/static/routes/redux-store-routes";
import CacheService from "../../../core/services/cache/cache-service";

/**
 * Initial state of the config slice.
 * @type {InAppModels.Redux.Config}
 */
export const reduxStoreConfigInitialState = {
    logoutUrl: null,
    loginUrl: null,
}

/**
 * config slice that exists in the redux store.
 * this slice is used to contain the general configuration of the application.
 */
const reduxStoreConfigSlice = createSlice({
    name: ReduxStoreRoutes.config,
    initialState: reduxStoreConfigInitialState,
    reducers: {
        /**
         * Sets config information.
         * @param state
         * @param action
         */
        setConfig: (state, action) => {
            state = {
                ...state,
                loginUrl: action.payload.loginUrl,
                logoutUrl: action.payload.logoutUrl,
            }
            CacheService.setUserInformation(state);
            return state;
        },
    }
})

export default reduxStoreConfigSlice;
