/*
 *
 */

import React from "react";
import {Button} from "@mui/material";


const TryAgain = ({
                      onClick,
                      text = 'No search results found!',
                      buttonText = 'Search Again',
                  }) => {
    return (
        <div className={'try-again'}>
            <p>
                {text ?? ''}
            </p>
            <div>
                <Button
                    className={'button'}
                    onClick={onClick}>
                    {buttonText ?? ''}
                </Button>
            </div>
        </div>
    );
}

export default TryAgain;
