/*
 *
 */

import {Mixin} from "ts-mixer";
import BizLearnApiComponentsMixin from "./mixins/components";
import BizLearnBaseApiMixin from "./mixins/base";
import ApiRequestModels from "../base/models/requests";
import BizLearnApiRequestModels from "./models/requests";
import BizLearnApiResponseModels from "./models/responses";
import {CrudResponse} from "../../../../models/in-app";
import {ContentSearchTypeValues} from "../../../../constants/enums";

/**
 * The Api service associated with the BizLearn server apis.
 *
 * This server is developed to maintain the functionality of this website and therefore is considered the primary
 * api service of this app.
 */
class BizLearnApi extends Mixin(BizLearnApiComponentsMixin, BizLearnBaseApiMixin) {


    // ################################         Application         ################################

    /**
     * Fetches the list of available applications
     *
     * @param {ApiRequestModels.BaseApiRequest} extra extra params injected into the created api requests.
     * @return {Promise<CrudResponse<BizLearnApiResponseModels.Application.Platform>>}
     */
    static getApplications(extra?: ApiRequestModels.BaseApiRequest)
        : Promise<CrudResponse<BizLearnApiResponseModels.Application.Platform>> {
        return this.sync<CrudResponse<BizLearnApiResponseModels.Application.Platform>>(
            this.injectExtraRequestProperties(this._getApplications(), extra),
        );
    }

    // ################################         Course         ################################

    /**
     * Searches among the courses of the system with the given filters
     *
     * @param {BizLearnApiRequestModels.Course.Search} data search filters
     * @param {ApiRequestModels.BaseApiRequest} extra extra params injected into the created api requests.
     * @return {Promise<CrudResponse<BizLearnApiResponseModels.Course.Search>>}
     * @private
     */
    static searchCourses(data: BizLearnApiRequestModels.Course.Search, extra?: ApiRequestModels.BaseApiRequest)
        : Promise<CrudResponse<BizLearnApiResponseModels.Course.Search>> {
        return this.sync<CrudResponse<BizLearnApiResponseModels.Course.Search>>(
            this.injectExtraRequestProperties(this._searchCourses(data), extra),
        );
    }

    /**
     * Fetches the information of a course provided by its id.
     *
     * @param {number} id the course id to fetch its information
     * @param {ApiRequestModels.BaseApiRequest} extra extra params injected into the created api requests
     * @return {Promise<CrudResponse<BizLearnApiResponseModels.Course.CourseInformation>>}
     */
    static getCourseInformation(id: number, extra?: ApiRequestModels.BaseApiRequest)
        : Promise<CrudResponse<BizLearnApiResponseModels.Course.CourseInformation>> {
        return this.sync<CrudResponse<BizLearnApiResponseModels.Course.CourseInformation>>(
            this.injectExtraRequestProperties(this._getCourseInformation(id), extra),
        );
    }

    // ################################         Main Page         ################################


    /**
     * Fetches the data of the main page of the application.
     *
     * @param {ApiRequestModels.BaseApiRequest} extra extra params injected into the created api requests
     * @return {Promise<CrudResponse<BizLearnApiResponseModels.MainPage.Information>>}
     */
    static getMainPageData(extra?: ApiRequestModels.BaseApiRequest)
        : Promise<CrudResponse<BizLearnApiResponseModels.MainPage.Information>> {
        return this.sync<CrudResponse<BizLearnApiResponseModels.MainPage.Information>>(
            this.injectExtraRequestProperties(this._getMainPageData(), extra),
        );
    }

    /**
     * Searches among all the content of the system with the given search filters
     *
     * @param {BizLearnApiRequestModels.MainPage.Search} data search filters
     * @param {ApiRequestModels.BaseApiRequest} extra extra params injected into the created api requests
     * @return {Promise<CrudResponse<BizLearnApiResponseModels.MainPage.Search>>}
     */
    static searchContent(data: BizLearnApiRequestModels.MainPage.Search, extra?: ApiRequestModels.BaseApiRequest)
        : Promise<CrudResponse<BizLearnApiResponseModels.MainPage.Search>> {
        return this.sync<CrudResponse<BizLearnApiResponseModels.MainPage.Search>>(
            this.injectExtraRequestProperties(this._searchContent(data), extra),
        );
    }

// ################################         Notebook         ################################

    /**
     * Creates a new note for a specific video in the system
     *
     * @param {BizLearnApiRequestModels.Notebook.Create} data note to be created
     * @param {ApiRequestModels.BaseApiRequest} extra extra params injected into the created api requests
     * @return {Promise<CrudResponse<BizLearnApiResponseModels.Notebook.Note>>}
     */
    static createVideoNote(data: BizLearnApiRequestModels.Notebook.Create, extra?: ApiRequestModels.BaseApiRequest)
        : Promise<CrudResponse<BizLearnApiResponseModels.Notebook.Note>> {
        return this.sync<CrudResponse<BizLearnApiResponseModels.Notebook.Note>>(
            this.injectExtraRequestProperties(this._createVideoNote(data), extra),
        );
    }

    /**
     * Updates a specific note for a specific video in the system
     *
     * @param {BizLearnApiResponseModels.Notebook.Note} data note to be updated
     * @param {ApiRequestModels.BaseApiRequest} extra extra params injected into the created api requests
     * @return {Promise<CrudResponse>}
     */
    static updateVideoNote(data: BizLearnApiResponseModels.Notebook.Note, extra?: ApiRequestModels.BaseApiRequest)
        : Promise<CrudResponse> {
        return this.sync<CrudResponse>(
            this.injectExtraRequestProperties(this._updateVideoNote(data), extra),
        );
    }

    /**
     * Removes a specific note from a specific video in the system
     *
     * @param {number} id id of the note to be removed
     * @param {ApiRequestModels.BaseApiRequest} extra extra params injected into the created api requests
     * @return {Promise<CrudResponse>}
     */
    static removeVideoNote(id: number, extra?: ApiRequestModels.BaseApiRequest)
        : Promise<CrudResponse> {
        return this.sync<CrudResponse>(
            this.injectExtraRequestProperties(this._removeVideoNote(id), extra),
        );
    }

    // ################################         Video         ################################

    /**
     * Toggles the isSaved state of a specific video
     *
     * @param {BizLearnApiRequestModels.Video.ToggleIsSaved} data data for toggling is saved state
     * @param {ApiRequestModels.BaseApiRequest} extra extra params injected into the created api requests
     * @return {Promise<CrudResponse>}
     */
    static toggleVideosIsSaved(data: BizLearnApiRequestModels.Video.ToggleIsSaved, extra?: ApiRequestModels.BaseApiRequest)
        : Promise<CrudResponse> {
        return this.sync<CrudResponse>(
            this.injectExtraRequestProperties(this._toggleVideosIsSaved(data), extra),
        );
    }

    /**
     * Searches among all the videos of the users with the given filters.
     *
     * @param {BizLearnApiRequestModels.Video.Search} data search filters
     * @param {ApiRequestModels.BaseApiRequest} extra extra params injected into the created api requests
     * @return {Promise<CrudResponse>}
     */
    static async searchVideos(data: BizLearnApiRequestModels.Video.Search, extra?: ApiRequestModels.BaseApiRequest)
        : Promise<CrudResponse<BizLearnApiResponseModels.Video.Search>> {
        // TODO: change when api is available
        const _data: BizLearnApiRequestModels.MainPage.Search = {
            ...data,
            type: ContentSearchTypeValues.video,
        }
        const response = await this.sync<CrudResponse<BizLearnApiResponseModels.MainPage.Search>>(
            this.injectExtraRequestProperties(this._searchContent(_data), extra),
        );
        if (!response?.resultFlag) {
            // @ts-ignore
            return {...response, data: undefined};
        }
        // @ts-ignore
        return {
            ...response,
            data: (response?.data?.videos ?? {}) as BizLearnApiResponseModels.Video.Search,
        };
    }

    /**
     * Searches among the saved videos of the users with the given filters.
     *
     * @param {BizLearnApiRequestModels.Video.Search} data search filters
     * @param {ApiRequestModels.BaseApiRequest} extra extra params injected into the created api requests
     * @return {Promise<CrudResponse>}
     */
    static searchSavedVideos(data: BizLearnApiRequestModels.Video.Search, extra?: ApiRequestModels.BaseApiRequest)
        : Promise<CrudResponse<BizLearnApiResponseModels.Video.Search>> {
        return this.sync<CrudResponse<BizLearnApiResponseModels.Video.Search>>(
            this.injectExtraRequestProperties(this._searchSavedVideos(data), extra),
        );
    }

    /**
     * Searches among the in progress videos of the users with the given filters.
     *
     * @param {BizLearnApiRequestModels.Video.Search} data search filters
     * @param {ApiRequestModels.BaseApiRequest} extra extra params injected into the created api requests
     * @return {Promise<CrudResponse>}
     */
    static searchInProgressVideos(data: BizLearnApiRequestModels.Video.Search, extra?: ApiRequestModels.BaseApiRequest)
        : Promise<CrudResponse<BizLearnApiResponseModels.Video.Search>> {
        return this.sync<CrudResponse<BizLearnApiResponseModels.Video.Search>>(
            this.injectExtraRequestProperties(this._searchInProgressVideos(data), extra),
        );
    }

    /**
     * Fetches the full information of a video with its playlist and notebook with the given id.
     *
     * @param {number} id id of the video to load.
     * @param {ApiRequestModels.BaseApiRequest} extra extra params injected into the created api requests
     * @return {ApiRequestModels.ApiRequest}
     * @private
     */
    static getVideoInformation(id: number, extra?: ApiRequestModels.BaseApiRequest)
        : Promise<CrudResponse<BizLearnApiResponseModels.Video.VideoInformation>> {
        return this.sync<CrudResponse<BizLearnApiResponseModels.Video.VideoInformation>>(
            this.injectExtraRequestProperties(this._getVideoInformation(id), extra),
        );
    }

}

export default BizLearnApi

