/*
 *
 */

/**
 * The abstract base class for the Endpoints in the system.
 * this interface provides the default constructor used by all of its extending classes.
 */
abstract class BaseApiEndpoints {
    protected baseUrl: string;

    /**
     * Constructs this Endpoint with the given suffix.
     *
     * @param {string} suffix the string to be added to apiUrl as this classes' baseUrl.
     * @param {string} apiUrl the base url of this endpoint.
     * @protected
     */
    protected constructor(apiUrl: string, suffix: string = "") {
        this.baseUrl = apiUrl + suffix;
    }
}

export default BaseApiEndpoints;
