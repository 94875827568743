/*
 *
 */

import React, {useEffect, useMemo, useState} from "react";
import ContentSearchSection from "../../../../components/search-sections/content";
import useIsMounted from "../../../../hooks/use-is-mounted";
import useRouter from "../../../../hooks/use-router";
import {VideosQueryParams} from "../../../../../core/constants/query-params";
import Utils from "../../../../../core/services/utils";
import {Col, Container, Row} from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroller";
import {ContentSearchSectionTypes, VideoCardTypes} from "../../../../../core/constants/enums";
import VideoCard from "../../../../components/app-specific/video-card";
import TryAgain from "../../../../components/app-specific/try-again";
import {BizLearnApi} from "../../../../../core/services/api";

const initialPaginationInfo = {
    pageSize: 12,
    currentPage: 0,
    total: 0,
}

const VideosListView = () => {
    const {query, setQuery} = useRouter();
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState({});
    const [paginationInfo, setPaginationInfo] = useState(initialPaginationInfo);
    const [loading, setLoading] = useState(true);
    const isMounted = useIsMounted();

    const canLoadMore = !loading && (paginationInfo?.pageSize ?? 0) * (paginationInfo?.currentPage ?? 0) < (paginationInfo?.total ?? 0)
    const breakpoints = useMemo(() => ({
        xs: 12,
        lg: 6,
    }), [])

    /**
     * Determines the api seach function to use depending on the provided filters.
     * @type {(data: BizLearnApiRequestModels.Video.Search, extra?: ApiRequestModels.BaseApiRequest) =>
     * Promise<CrudResponse<BizLearnApiResponseModels.Video.Search>>}
     */
    const searchFunction = useMemo(() => {
        if (filters[VideosQueryParams.inProgressOnly]) {
            return (data, extra) => BizLearnApi.searchInProgressVideos(data, extra)
        }
        if (filters[VideosQueryParams.savedOnly]) {
            return (data, extra) => BizLearnApi.searchSavedVideos(data, extra)
        }
        return (data, extra) => BizLearnApi.searchVideos(data, extra)
    }, [filters])

    /**
     * With each change in query:
     * - checks any changes in the filters, and if there are changes, resets the pagination and data so that the new
     * search fills the data again
     */
    useEffect(() => {
        const filters = hasFiltersChanged();
        if (filters) {
            setPaginationInfo(initialPaginationInfo);
            setData([]);
        }
    }, [query])

    /**
     * With each change in paginationInfo's currentPage:
     * - if currentPage is 0, it indicates that there was a reset in paginationInfo, or we are in the initial
     * render, so we just change the currentPage to 1
     * - otherwise we search
     */
    useEffect(() => {
        if (!paginationInfo?.currentPage) {
            return setPaginationInfo(prevState => ({...prevState, currentPage: 1}));
        }
        search().then();
    }, [paginationInfo?.currentPage])

    /**
     * Determines if the filters in the query are different from the saved filters in the state.
     *
     * * in case of any difference, the state is updated and the updated values are then returned.
     * @return {{}}
     */
    const hasFiltersChanged = () => {
        const newFilters = {
            [VideosQueryParams.keywords]: query[VideosQueryParams.keywords],
            [VideosQueryParams.platform]: query[VideosQueryParams.platform],
            [VideosQueryParams.savedOnly]: query[VideosQueryParams.savedOnly],
            [VideosQueryParams.inProgressOnly]: query[VideosQueryParams.inProgressOnly],
        }
        if (!Utils.deepEqual(filters, newFilters)) {
            setFilters(newFilters);
            return newFilters;
        }
    }


    /**
     * Searches among all the videos of this system with the provided filters and paginationInfo.
     *
     * - if the result of the api is successful, sorts the retrieved data based on their date, and appends them to
     * the state list. Also updates the total contents count for pagination purposes.
     */
    const search = async () => {
        /**@type {BizLearnApiRequestModels.Video.Search}*/
        const forApi = {
            keyword: filters[VideosQueryParams.keywords],
            applicationId: filters[VideosQueryParams.platform],
            pageIndex: paginationInfo.currentPage,
            pageSize: paginationInfo.pageSize,
        }
        setLoading(true);
        const response = await searchFunction(forApi);
        if (!isMounted()) return;
        setLoading(false);
        if (response?.resultFlag) {
            setPaginationInfo(prevState => ({
                ...prevState,
                total: response?.data?.totalCount ?? 0,
            }))
            const data = response?.data?.items
                    ?.map(video => ({
                        ...video,
                        coverImageUrl: (response?.configuration?.videoCoverBaseURL ?? "") + video.coverImageUrl ?? "",
                        isSaved: filters[VideosQueryParams.savedOnly] ? true : video?.isSaved,
                    }))
                    ?.sort((a, b) =>
                        Utils.dateComparator(b?.modifiedDateTime ?? Date.now(), a?.modifiedDateTime ?? Date.now()))
                ?? []
            setData(prevState => [...prevState, ...data]);
        }
    }

    /**
     * Increases the paginationInfo's current page to trigger the search function and to load more data.
     */
    const loadMore = () => {
        if (loading) return;
        setLoading(true);
        setPaginationInfo(prevState => ({...prevState, currentPage: prevState.currentPage + 1}));
    }

    /**
     * Resets the paginationInfo and data so that the search is triggered again.
     */
    const searchAgain = () => {
        setPaginationInfo(initialPaginationInfo);
        setData([]);
    }

    /**
     * Removes the filters from the url queries. This ultimately triggers the search function as well.
     *
     * * does not remove inProgressOnly or savedOnly from the query as the mentioned flags must be persistent throughout
     */
    const removeFilters = () => {
        setQuery({
            [VideosQueryParams.inProgressOnly]: filters[VideosQueryParams.inProgressOnly],
            [VideosQueryParams.savedOnly]: filters[VideosQueryParams.savedOnly],
        }, {replace: true});
    }

    /**
     * Removes the selected video from the data list of the state.
     * @param {BizLearnApiResponseModels.Video.Video} video
     */
    const removeVideoFromList = (video) => {
        setData(prevState => prevState?.filter(e => e.id !== video?.id));
    }

    /** @type {ReactNode} */
    const loadingItems = useMemo(() => {
        if (!loading) {
            return <></>;
        }
        return (
            <>
                {Array(initialPaginationInfo.pageSize).fill(null).map((_, e) => (
                    <Col key={`loading-${e}`} {...breakpoints}>
                        <VideoCard loading type={VideoCardTypes.medium}/>
                    </Col>
                ))}
                <div className={'mt-5'}/>
            </>
        );
    }, [loading, breakpoints])

    /** @type {ReactNode} */
    const items = useMemo(() => {
        return (
            <>
                {data?.map(video => (
                    <Col key={video.id}
                         className={'item'}
                         {...breakpoints}>
                        <VideoCard
                            type={VideoCardTypes.medium}
                            data={video}
                            onIsSavedToggled={removeVideoFromList}
                            pure
                        />
                    </Col>
                ))}
            </>
        );
    }, [data, breakpoints])

    return (
        <>
            <div className={'videos-list-view'}>
                <ContentSearchSection type={ContentSearchSectionTypes.videos} filters={filters}/>
                <div className={'spacer'}/>
                <Container>
                    {
                        !loading && !data?.length ?
                            (
                                !Object.keys(query).length ||
                                (
                                    Object.keys(query).length === 1 &&
                                    [
                                        VideosQueryParams.inProgressOnly,
                                        VideosQueryParams.savedOnly,
                                    ].includes(Object.keys(query)[0])
                                )
                                    ? <TryAgain
                                        text={'There seems to be no videos available'}
                                        buttonText={'Search Again'}
                                        onClick={searchAgain}
                                    />
                                    : <TryAgain
                                        text={'There are no results with the given search filters.'}
                                        buttonText={'Remove Filters'}
                                        onClick={removeFilters}
                                    />
                            )
                            : <>
                                <InfiniteScroll
                                    className='row'
                                    pageStart={1}
                                    loadMore={loadMore}
                                    hasMore={canLoadMore}
                                    useWindow
                                    threshhold={400}>
                                    {items}
                                </InfiniteScroll>
                                <Row>
                                    {loadingItems}
                                </Row>
                            </>
                    }
                </Container>
                <div className={'spacer'}/>
            </div>
        </>
    )
}

export default VideosListView;
