/*
 *
 */

import React, {useMemo} from "react";
import AppLogo from "../app-logo";
import {LogoTypes} from "../../../../core/constants/enums";
import moment from "moment";
import EnvService from "../../../../core/services/env-service";

const AppFooter = () => {
    const thisYear = moment().year();
    const copyRight = useMemo(() => {
        if (EnvService.showBiz) {
            return `Copyright ${thisYear} Bizkey Tech | All Rights Reserved | Designed and Developed byBizkey Tech`
        }
        return `Copyright ${thisYear} U-Learn Team | All Rights Reserved | Designed and Developed U-Learn Team`
    }, [])

    return (
        <>
            <div className={'app-footer'}>
                <div className={'copyright'}>
                    <p>
                        {copyRight}
                    </p>
                    <AppLogo type={LogoTypes.secondary}/>
                </div>
            </div>
        </>
    )
}

export default AppFooter;
