/*
 *
 */

import {v4 as UUIDv4} from 'uuid';
import moment from "moment";
import {OSs} from '../constants/enums';
import EnvService from "./env-service";
import $ from 'jquery';
import * as Papa from 'papaparse';

/**
 * Utilities of the application.
 * this interface contains the methods/functions that are used throughout the application.
 */
class Utils {
    private static _isSafari?: boolean = undefined;

    // #################################        COMPARATORS          #################################

    /**
     * Compares two numbers
     * @param a {number}
     * @param b {number}
     */
    static numComparator(a: number, b: number): number {
        if (a === b) return 0;
        if (a < b) return -1;
        return 1
    }

    /**
     * Compares two dates by converting them to moment objects and then comparing them
     * @param a {moment.MomentInput}
     * @param b {moment.MomentInput}
     */
    static dateComparator(a: moment.MomentInput, b: moment.MomentInput): number {
        const _momentComparator = (a: moment.Moment, b: moment.Moment) => {
            if (a.isSame(b, 'ms')) return 0;
            if (a.isAfter(b, 'ms')) return 1;
            return -1;
        }
        return _momentComparator(moment(a), moment(b));
    }

    /**
     * Compares two strings.
     * @param a {string}
     * @param b {string}
     */
    static stringComparator(a: string, b: string): number {
        return a?.localeCompare(b);
    }

    /**
     * Compares two Booleans
     * @param a {boolean}
     * @param b {boolean}
     */
    static booleanComparator(a: boolean, b: boolean): number {
        if (a === b) return 0;
        if (a < b) return -1;
        return 1;
    }

    // #################################         UTILS       #################################

    /**
     * Determines if two objects are equal.
     * @param object1 {any}
     * @param object2 {any}
     * @return {boolean}
     */
    static deepEqual(object1: any, object2: any): boolean {
        // check if the first one is an array
        if (Array.isArray(object1)) {
            if (!Array.isArray(object2) || object1.length !== object2.length) return false;
            for (let i = 0; i < object1.length; i++) {
                if (!this.deepEqual(object1[i], object2[i])) return false;
            }
            return true;
        }
        // check if the first one is an object
        if (typeof object1 === 'object' && object1 !== null && object2 !== null) {
            if (!(typeof object2 === 'object')) return false;
            const keys = Object.keys(object1);
            if (keys.length !== Object.keys(object2).length) return false;
            for (const key in object1) {
                if (!this.deepEqual(object1[key], object2[key])) return false;
            }
            return true;
        }
        // not array and not object, therefore must be primitive
        return object1 === object2;
    }

    /**
     * Deep copy an acyclic *basic* Javascript object.
     *
     * *This only handles basic types (strings, numbers, booleans) and arbitrarily deep arrays and objects
     * containing these.
     * * This does *not* handle instances of other classes.
     * @param obj {any}
     * @return {any} the same object passed to it
     */
    static deepCopy(obj: any): any {
        let ret, key;
        let marker = '__deepCopy';

        if (obj && obj[marker])
            throw (new Error('attempted deep copy of cyclic object'));

        if (obj && obj.constructor == Object) {
            ret = {};
            obj[marker] = true;

            for (key in obj) {
                if (key == marker)
                    continue;
                // @ts-ignore
                ret[key] = this.deepCopy(obj[key]);
            }

            delete (obj[marker]);
            return (ret);
        }

        // eslint-disable-next-line
        if (obj && obj.constructor == Array) {
            ret = [];
            // @ts-ignore
            obj[marker] = true;

            for (key = 0; key < obj.length; key++)
                ret.push(this.deepCopy(obj[key]));

            // @ts-ignore
            delete (obj[marker]);
            return (ret);
        }
        // It must be a primitive type -- just return it.
        return (obj);
    }

    /**
     * Add a stylesheet rule to the document (it may be better practice to dynamically change classes, so style
     * information can be kept in genuine stylesheets and avoid adding extra elements to the DOM).
     *
     * Note that an array is needed for declarations and rules since ECMAScript does not guarantee a predictable
     * object iteration order, and since CSS is
     * order-dependent.
     * @param {Array} rules Accepts an array of JSON-encoded declarations
     * @example
     addStylesheetRules([
     [
     'h2', // Also accepts a second argument as an array of arrays instead
     ['color', 'red'],
     ['background-color', 'green', true] // 'true' for !important rules
     ],
     [
     '.myClass',
     ['background-color', 'yellow']
     ]
     ]);
     */
    static addStylesheetRules(rules: Array<Array<any>>): void {
        const styleEl = document.createElement('style');

        // Append <style> element to <head>
        document.head.appendChild(styleEl);

        // Grab style element's sheet
        const styleSheet = styleEl.sheet;

        for (let i = 0; i < rules.length; i++) {
            let j = 1,
                rule = rules[i],
                selector = rule[0],
                propStr = '';
            // If the second argument of a rule is an array of arrays, correct our variables.
            if (Array.isArray(rule[1][0])) {
                rule = rule[1];
                j = 0;
            }

            for (let pl = rule.length; j < pl; j++) {
                const prop = rule[j];
                propStr += prop[0] + ': ' + prop[1] + (prop[2] ? ' !important' : '') + ';\n';
            }

            // Insert CSS Rule
            styleSheet?.insertRule(selector + '{' + propStr + '}', styleSheet.cssRules.length);
        }
    }

    /**
     * Creates a Unique Identifier in form of a string.
     * @param {boolean} reactKey whether the uuid is for a React key.
     */
    static createUUId(reactKey: boolean = false): string {
        const uuid = UUIDv4();
        if (!reactKey) return uuid;
        return `_${uuid}`
    }

    /**
     * Fetches the os of the user browser.
     * @return {"Mobile" | "Web"}
     */
    static getOS(): string {
        return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
            ? OSs.phone
            : OSs.web
    }

    /**
     * Determines if the currently working browser is safari or not.
     */
    static isSafari(): boolean {
        if (this._isSafari === undefined) {
            this._isSafari = !!(navigator.vendor &&
                navigator.vendor.indexOf('Apple') > -1 &&
                navigator.userAgent &&
                navigator.userAgent.indexOf('CriOS') === -1 &&
                navigator.userAgent.indexOf('FxiOS') === -1);
        }
        return this._isSafari
    }

    /**
     * Find all svg elements with the given attribute, e.g. for `mask`, and appends the baseUrl to the attribute's
     * url value.
     * @param {string} attr the attribute to target in the svg element.
     * @private
     */
    private static _fixForAttribute(attr: string) {
        const baseUrl = window.location.href;

        // Find all svg elements with the given attribute, e.g. for `mask`.
        // See: http://stackoverflow.com/a/23047888/796152
        [].slice.call(document.querySelectorAll(`svg [${attr}]`))
            // filter out all elements whose attribute doesn't start with `url(#`
            .filter((element: SVGElement) => element?.getAttribute(attr)?.indexOf('url(#') === 0)
            // prepend `window.location` to the attr's url() value, in order to make it an absolute IRI
            .forEach((element: SVGElement) => {
                const maskId = element?.getAttribute(attr)?.replace('url(', '').replace(')', '');
                element.setAttribute(attr, `url(${baseUrl + maskId})`);
            });
    }

    /**
     * Fixes references to SVG IDs.
     * Safari won't display SVG masks/fills e.g. referenced with
     * `url(#id)` when the <base> tag is on the page.
     *
     * More info:
     * - http://stackoverflow.com/a/18265336/796152
     * - http://www.w3.org/TR/SVG/linking.html
     */
    static fixSvgUrls() {
        // this fixes the URL IDs for 'fill' and 'mask'; if you need others, add them here
        this._fixForAttribute('fill');
        this._fixForAttribute('mask');
    }

    /**
     * Awaits for the specified time in milliseconds.
     * @param milliseconds
     */
    static async wait(milliseconds: number): Promise<void> {
        await new Promise(r => setTimeout(r, milliseconds));
    }

    /**
     * Sets the application's title and description from the env variables.
     */
    static setAppInfo() {
        document.title = EnvService.title;
        $('meta[name="description"]').attr("content", EnvService.description);
    }

    /**
     * Corrects the url of the video by forcing it to start with https
     * @param {string} url
     * @return {string|*}
     */
    static correctUrl(url: string): string {
        if (!url?.length) return '';
        if (url.startsWith('http://') || url.startsWith('https://')) return url;
        return `https://${url}`;
    }

    /**
     * Converts the given seconds to a timestamp format.
     * @param {number} secs
     * @return {string} the timestamp in the form of "hh:mm:ss"
     */
    static toTimestamp(secs: number): string {
        const sec_num = parseInt(`${secs}`, 10)
        const hours = Math.floor(sec_num / 3600)
        const minutes = Math.floor(sec_num / 60) % 60
        const seconds = sec_num % 60
        return [hours, minutes, seconds]
            .map(v => v < 10 ? "0" + v : v)
            .join(":")
    }

    /**
     * Converts the given timestamp to number of seconds.
     * @param {string} timestamp a string in the form of "hh:mm:ss"
     * @return {number} the number of seconds.
     */
    static toSeconds(timestamp: string): number {
        const [hours, minutes, seconds] = timestamp.split(':');
        return (
            (parseInt(hours ?? '0') * 3600) +
            (parseInt(minutes ?? '0') * 60) +
            parseInt(seconds ?? '0')
        )
    }

    /**
     * Downloads the given blob for the user.
     * @param blob {Blob} the file to be downloaded
     * @param name {string} the file to be downloaded
     */
    static downloadFile(blob: Blob, name: string): void {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = name;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }

    /**
     * Exports a file into csv by the given headers, items and the file title
     * @param headers {any}
     * @param items {any}
     * @param fileTitle {string}
     */
    static exportCSVFile(headers: any, items: any, fileTitle: string): void {
        const csv = Papa.unparse(items, {});
        const exportedFileName = fileTitle + '.csv' || 'export.csv';
        const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
        this.downloadFile(blob, exportedFileName);
    }

    /**
     * Determines the if the given url is from YouTube.
     * @param {string} url
     * @return {boolean} True if from YouTube, False otherwise.
     */
    static isYoutubeUrl(url: string): boolean {
        return (url?.startsWith('https://www.youtube') || url?.startsWith('http://www.youtube')) ?? false;
    }

    /**
     * Fetches the youtube video id from the youtube url.
     * @param url the youtube url
     */
    static getYoutubeVideoId(url: string): string {
        const list = url?.split('/') ?? [];
        if (list.length < 2)
            return "";
        const id = list[list.length - 1] ?? "";
        if (id.includes('?')) {
            return id.split('?')[0];
        }
        return id;
    }
}

export default Utils;
