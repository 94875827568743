/*
 *
 */

import React, {forwardRef} from "react";
import {Dialog as MuiDialog, Slide} from "@mui/material";

/**
 * @param {import("@mui/material").SlideProps} props
 * @param ref
 */
const _SlideTransitionComponent = ({...props}, ref) => {
    return (
        <Slide ref={ref} children={props.children} {...props}/>
    );
};

const SlideTransitionComponent = forwardRef(_SlideTransitionComponent);

/**
 * @param {'left' | 'right' | 'up' | 'down'} slideDirection
 * @param {import("@mui/material").DialogProps} props
 */
const Dialog = ({slideDirection = 'up', ...props}) => {

    return (
        <>
            <MuiDialog
                {...props}
                classes={{
                    container: 'dialog-container',
                    paper: 'dialog-paper',
                }}
                TransitionComponent={SlideTransitionComponent}
                TransitionProps={{direction: slideDirection}}
            />
        </>
    )
}

export default Dialog;
