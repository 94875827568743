/*
 *
 */

import React from "react";
import {Button} from "@mui/material";
import useRouter from "../../../../hooks/use-router";

const ServerErrorErrorView = () => {
    const {navigate} = useRouter();

    /**
     * Navigates the user to the previous page.
     */
    const back = () => navigate(-1);

    return (
        <>
            <h1>
                <span>500</span>
            </h1>
            <p>
                An error has occurred and we're working to fix the problem!
                <br/>
                We'll be up and running shortly.
            </p>
            <Button
                onClick={back}
                className={'button'}>
                Go Back
            </Button>
        </>
    )
}

export default ServerErrorErrorView;
