/*
 *
 */

import React, {useLayoutEffect, useMemo, useRef, useState} from "react";
import {InputTypes, KeyboardEventKeys} from "../../../../../../core/constants/enums";
import Input from "../../../../base/input";
import {IconButton, Tooltip} from "@mui/material";
import {ReactComponent as SearchIcon} from "../../../../../../assets/images/header/search.svg";
import classnames from "classnames";
import useWindowViewportWidth from "../../../../../hooks/use_window-viewport-width";
import useRouter from "../../../../../hooks/use-router";
import AppRoutes from "../../../../../../core/models/static/routes/app-routes";
import {ContentQueryParams} from "../../../../../../core/constants/query-params";

const AppHeaderSearchbar = ({initialValue = '', ...props}) => {
    const {navigate, query} = useRouter();
    const [value, setValue] = useState(initialValue);
    const [_expanded, setExpanded] = useState(false);
    const [focused, setFocused] = useState(false);
    const viewportWidth = useWindowViewportWidth();
    const forceExpanded = useMemo(() => ['xs'].includes(viewportWidth), [viewportWidth]);
    const expanded = useMemo(() => _expanded || forceExpanded, [_expanded, forceExpanded])
    /**@type {React.MutableRefObject<HTMLInputElement>}*/
    const inputRef = useRef();
    /**@type {React.MutableRefObject<HTMLButtonElement>}*/
    const buttonRef = useRef();


    /**
     * With each change in initialValue:
     * - sets expanded and value states.
     */
    useLayoutEffect(() => {
        setExpanded(!!initialValue);
        setValue(initialValue);
    }, [initialValue]);

    /**
     * With each change in focused state:
     * - sets the expanded state in the following way:
     *   * if focused, then it must also expand, if not focused, then only expand if the input contains any value
     */
    useLayoutEffect(() => {
        if (focused) {
            setExpanded(true);
        } else {
            setExpanded(!!value)
        }
    }, [focused])

    /**
     * Performs a magic search in the application with the given value.
     *
     * - this method also retains any currently set queries that are specific for search view.
     * @param {string} value the value to search with.
     */
    const search = (value) => {
        setValue(value);
        if (!value) {
            return;
        }

        const validQueryKeys = Object.keys(ContentQueryParams);
        const validPrevQueries = Object.fromEntries(Object.entries(query ?? {}).filter(([key]) => validQueryKeys.includes(key)))

        navigate(AppRoutes.private.search, undefined, {
            ...validPrevQueries,
            [ContentQueryParams.keywords]: value,
        });
    }


    /**
     * Depending on the pressed key:
     * - if Enter: calls the search function
     *
     * @param {KeyboardEvent} e
     */
    const onKeyPress = (e) => {
        console.log('hey', e.key);
        if (e.key === KeyboardEventKeys.enter) {
            onSearchPressed();
        }
    }

    /**
     * Searches the result with the search callback if the result is a non-empty string
     */
    const onSearchPressed = () => {
        setTimeout(() => {
            inputRef.current.focus();
        }, 100)
        setFocused(true);
        search(value);
    }

    return (
        <>
            <div
                className={classnames('searchbar', {expanded, focused})}
                onKeyUp={onKeyPress}>
                <Input
                    {...props}
                    label={undefined}
                    size={'small'}
                    type={InputTypes.text}
                    value={value}
                    focused={focused}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    onChange={(e) => setValue(e.target.value)}
                    InputProps={{
                        inputRef: inputRef,
                        endAdornment: (
                            <Tooltip title={expanded ? 'Search' : 'Expand'}>
                                <IconButton
                                    ref={buttonRef}
                                    size={"small"}
                                    onClick={() => onSearchPressed()}
                                    className={'icon-button'}>
                                    <SearchIcon className={'custom'}/>
                                </IconButton>
                            </Tooltip>
                        )
                    }}
                />

            </div>
        </>
    )
}

export default AppHeaderSearchbar;
