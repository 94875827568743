/*
 *
 */

import ApiHelperModels from "./models";

/**
 * The Static Messages / Codes used in this application's api responses.
 */
class ApiResponseUtils {

    /**
     * Fetches the Entry that is associated with the provided code.
     * @param {number | undefined} code a status code.
     */
    static getEntryByCode(code: number | undefined): ApiHelperModels.ApiResponseUtilsEntry | undefined {
        switch (code) {
            //    HTTP Codes
            case ApiResponseUtils.badRequest.code:
                return ApiResponseUtils.badRequest;
            case ApiResponseUtils.unauthorized.code:
                return ApiResponseUtils.unauthorized;
            case ApiResponseUtils.forbiddenAccess.code:
                return ApiResponseUtils.forbiddenAccess;
            case ApiResponseUtils.notFound.code:
                return ApiResponseUtils.notFound;
            case ApiResponseUtils.serverError.code:
                return ApiResponseUtils.serverError;

            //    Custom Codes
            case ApiResponseUtils.requestFailed.code:
                return ApiResponseUtils.requestFailed;
            case ApiResponseUtils.aborted.code:
                return ApiResponseUtils.aborted;
            case ApiResponseUtils.apiSuccess.code:
                return ApiResponseUtils.apiSuccess;
            case ApiResponseUtils.apiError.code:
                return ApiResponseUtils.apiError;
            default:
                return;
        }
    }

    //              ########################### HTTP SPECIFIC ###################################

    /**When the given request is incomplete or wrong in the context of the server call*/
    static badRequest: ApiHelperModels.ApiResponseUtilsEntry = {
        code: 400,
        title: 'Bad Request',
        message: "There seems to be a problem with your request. please contact technical support."
    };
    /**When the user should log in to access the data from the server.*/
    static unauthorized: ApiHelperModels.ApiResponseUtilsEntry = {
        code: 401,
        title: "Not Authorized",
        message: 'Please Login / Signup to use this feature.',
    };
    /**When the user does not have access to the requested data.*/
    static forbiddenAccess: ApiHelperModels.ApiResponseUtilsEntry = {
        code: 403,
        title: "Access Denied",
        message: 'You do not have access to the requested information. please contact technical support.',
    };
    /**When server was not found to communicate with*/
    static notFound: ApiHelperModels.ApiResponseUtilsEntry = {
        code: 404,
        title: "Server not Found",
        message: 'The requested endpoint does not exist. please contact technical support.',
    };

    /**When server instance for the api call crashed or was terminated before any response could be given*/
    static serverError: ApiHelperModels.ApiResponseUtilsEntry = {
        code: 500,
        title: "Server Error",
        message: 'Can not communicate with the servers properly, please try again later.',
    };


    //              ########################### CUSTOM ###################################

    /**When the server call successfully was made but response was never returned.*/
    static aborted: ApiHelperModels.ApiResponseUtilsEntry = {
        code: 597,
        title: "Aborted",
        message: '',
    };
    /**When the server call failed due to a problem in request preparation.*/
    static requestFailed: ApiHelperModels.ApiResponseUtilsEntry = {
        code: 599,
        title: 'Bad Request',
        message: "There seems to be a problem with your request. please contact technical support."
    };
    /**When the server call successfully was made but response was never returned.*/
    static serverNotResponded: ApiHelperModels.ApiResponseUtilsEntry = {
        code: 598,
        title: "Server Error",
        message: 'Can not communicate with the servers properly, please try again later.',
    };
    /**When the api call is successful.*/
    static apiSuccess: ApiHelperModels.ApiResponseUtilsEntry = {
        code: 200,
        title: "Success",
        message: 'server responded successfully',
    };
    /**When the api responds erroneous.*/
    static apiError: ApiHelperModels.ApiResponseUtilsEntry = {
        code: 400,
        title: "Api Error",
        message: 'Operation failed due to an error.',
    };
}

export default ApiResponseUtils;
