/*
 *
 */

import React, {useEffect, useMemo, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {ReactComponent as HeroLeftSvg} from "../../../../assets/images/art-like/home-hero-left.svg";
import {ReactComponent as HeroRightSvg} from "../../../../assets/images/art-like/home-hero-right.svg";
import {ReactComponent as FutureLeftSvg} from "../../../../assets/images/art-like/home-future-left.svg";
import {ReactComponent as FutureRightSvg} from "../../../../assets/images/art-like/home-future-right.svg";
import useWindowViewportWidth from "../../../hooks/use_window-viewport-width";
import HomeItemsContainer from "../../../components/app-specific/home-items-container";
import useIsMounted from "../../../hooks/use-is-mounted";
import AppRoutes from "../../../../core/models/static/routes/app-routes";
import {CourseCardTypes, HomeViewItemTypes, VideoCardTypes} from "../../../../core/constants/enums";
import useRouter from "../../../hooks/use-router";
import {VideosQueryParams} from "../../../../core/constants/query-params";
import CourseCard from "../../../components/app-specific/course-card";
import VideoCard from "../../../components/app-specific/video-card";
import {BizLearnApi} from "../../../../core/services/api";

const firstSectionBreakpoints = {
    xs: 12,
}

const coursesBreakpoints = {
    xs: 12,
    md: 6,
    lg: 4,
    xl: 3,
}

const videosBreakpoints = {
    xs: 12,
    lg: 6,
}

const HomeView = () => {
    const {navigate} = useRouter();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const viewportWidth = useWindowViewportWidth();
    const isMounted = useIsMounted();

    const showFutureRightSvg = useMemo(() => !['xs', 'sm', 'md'].includes(viewportWidth), [viewportWidth]);

    /**
     * As soon as the component mounts:
     * - fetches the home page data from the server.
     */
    useEffect(() => {
        getHomePageData().then();
    }, [])

    /**
     * Fetches the home page data from the server.
     *
     * * if the api call is successful, sets the data in the inner state.
     * @return {Promise<void>}
     */
    const getHomePageData = async () => {
        setLoading(true);
        const response = await BizLearnApi.getMainPageData();
        if (!isMounted()) return;
        if (response?.resultFlag) {
            setData({
                courses: response?.data?.courses.map(course => ({
                    ...course,
                    coverImageUrl: (response?.configuration?.courseCoverBaseURL ?? "") + course.coverImageUrl ?? "",
                })),
                videos: response?.data?.videos.map(video => ({
                    ...video,
                    coverImageUrl: (response?.configuration?.videoCoverBaseURL ?? "") + video.coverImageUrl ?? "",
                })),
                savedVideos: response?.data?.savedVideos.map(video => ({
                    ...video,
                    coverImageUrl: (response?.configuration?.videoCoverBaseURL ?? "") + video.coverImageUrl ?? "",
                })),
                inProgressVideos: response?.data?.inProgressVideos.map(video => ({
                    ...video,
                    coverImageUrl: (response?.configuration?.videoCoverBaseURL ?? "") + video.coverImageUrl ?? "",
                })),
            });
        }
        setLoading(false);
    }

    /**
     * Navigates the user to the videos list view with in-progress-only filter
     */
    const onMoreInProgressVideosClicked = () => {
        const query = {
            [VideosQueryParams.inProgressOnly]: true,
        }
        onMoreVideosClicked(query);
    }

    /**
     * Navigates the user to the videos list view with saved-only filter
     */
    const onMoreSavedVideosClicked = () => {
        const query = {
            [VideosQueryParams.savedOnly]: true,
        }
        onMoreVideosClicked(query);
    }

    /**
     * Navigates the user to the videos list view.
     * @param {Record<string, any> | undefined} queries
     */
    const onMoreVideosClicked = (queries = {}) => {
        navigate(AppRoutes.private.videos.list, undefined, queries);
    }

    /**
     * Navigates the user to the courses list view.
     * @param {Record<string, any> | undefined} queries
     */
    const onMoreCoursesClicked = (queries = {}) => {
        navigate(AppRoutes.private.courses.list, undefined, queries);
    }

    /**
     * Based on the new saved state of the changed video:
     * - removes / adds the video into the savedVideos list
     * - updates the saved state of the video in all the other 3 lists.
     *
     * @param {BizLearnApiResponseModels.Video.Video} video
     */
    const onVideosIsSavedToggled = (video) => {
        if (!isMounted()) return;
        if (!video?.isSaved) {
            setData(prevState => ({
                ...prevState,
                savedVideos: prevState?.savedVideos?.filter(e => e.id !== video.id) ?? [],
                inProgressVideos: prevState?.inProgressVideos?.map(e => e.id === video.id
                    ? {...e, isSaved: false}
                    : e
                ) ?? [],
                videos: prevState?.videos?.map(e => e.id === video.id
                    ? {...e, isSaved: false}
                    : e
                ) ?? [],
            }))
        } else {
            setData(prevState => {
                const alreadySaved = prevState.savedVideos?.find(e => e.id === video.id);
                const savedVideos = prevState?.savedVideos ?? [];
                if (!alreadySaved) {
                    savedVideos.push(video);
                }
                return ({
                    ...prevState,
                    savedVideos: savedVideos,
                    inProgressVideos: prevState?.inProgressVideos?.map(e => e.id === video.id
                        ? {...e, isSaved: true}
                        : e
                    ) ?? [],
                    videos: prevState?.videos?.map(e => e.id === video.id
                        ? {...e, isSaved: true}
                        : e
                    ) ?? [],
                });
            })
        }
    }

    /**
     * The items of this view. Each item will be fed into a home-items-container
     *
     * * the position of the items can be exchanged.
     * - if loading, all items are present and in loading state.
     * - if any of the types are not present in the data, then their counterpart index will be undefined
     * - if in only progress or saved are not present, the other will assume full width of the section
     * - if both of the in progress and saved are not present, their counterpart index will be removed
     */
    const items = useMemo(() => {
        const result = [
            {
                title: 'In Progress',
                type: HomeViewItemTypes.inProgress,
                loadingState: {
                    count: 4,
                    loading: loading,
                },
                breakpoints: firstSectionBreakpoints,
                component: VideoCard,
                onMoreClicked: () => onMoreInProgressVideosClicked(),
                items: data?.inProgressVideos?.map(item => ({
                    key: item.id,
                    props: {
                        data: item,
                        type: VideoCardTypes.medium,
                        onIsSavedToggled: onVideosIsSavedToggled,
                        pure: true,
                    },
                }))
            },
            {
                title: 'Saved',
                type: HomeViewItemTypes.saved,
                loadingState: {
                    count: 4,
                    loading: loading,
                },
                breakpoints: firstSectionBreakpoints,
                component: VideoCard,
                onMoreClicked: () => onMoreSavedVideosClicked(),
                items: data?.savedVideos?.map(item => ({
                    key: item.id,
                    props: {
                        data: item,
                        type: VideoCardTypes.medium,
                        onIsSavedToggled: onVideosIsSavedToggled,
                        pure: true,
                    },
                }))
            },
            {
                title: 'New Courses',
                type: HomeViewItemTypes.courses,
                loadingState: {
                    count: 8,
                    loading: loading,
                },
                breakpoints: coursesBreakpoints,
                component: CourseCard,
                onMoreClicked: () => onMoreCoursesClicked(),
                items: data?.courses?.map(item => ({
                    key: item.id,
                    props: {
                        data: item,
                        type: CourseCardTypes.medium,
                    },
                }))
            },
            {
                title: 'Videos',
                type: HomeViewItemTypes.videos,
                loadingState: {
                    count: 8,
                    loading: loading,
                },
                breakpoints: videosBreakpoints,
                component: VideoCard,
                onMoreClicked: () => onMoreVideosClicked(),
                items: data?.videos?.map(item => ({
                    key: item.id,
                    props: {
                        data: item,
                        type: VideoCardTypes.medium,
                        onIsSavedToggled: onVideosIsSavedToggled,
                        pure: true,
                    },
                }))
            }
        ];
        if (loading) {
            return result;
        }
        if (!data.inProgressVideos?.length) {
            result[0] = undefined;
        }
        if (!data.savedVideos?.length) {
            result[1] = undefined;
        }
        if ((!result[0] || !result[1]) && (result[0] || result[1])) {
            result[0] = result[0] ?? result[1];
            result[1] = null;
        }
        if (!data.courses?.length) {
            result[2] = undefined;
        }
        if (!data.videos?.length) {
            result[3] = undefined;
        }
        if ([HomeViewItemTypes.saved, HomeViewItemTypes.inProgress].includes(result[0]?.type) &&
            ![HomeViewItemTypes.saved, HomeViewItemTypes.inProgress].includes(result[1]?.type)) {
            result[0].breakpoints = videosBreakpoints;
        }
        return result?.filter(e => typeof e !== 'undefined');
    }, [data, loading])

    /**@type {ReactNode} */
    const content = useMemo(() => (
        // TODO: content inside Future.
        <p className={'content'}>
            Let no one discourage you from chasing and realizing your goals with their cynicism.
            <br/>
            <br/>
            This web-app stands as a clear example that everything is possible with hard work and enough dedication.
        </p>
    ), [])

    const showBothColumns = useMemo(() => (items?.length >= 2 && items[1]?.type === HomeViewItemTypes.saved), [items]);

    return (
        <>
            <div className={'home-view'}>
                <Container className={'hero'}>
                    <div className={'spacer'}/>
                    <Row>
                        <Col xs={12} lg={6}
                             className={'d-flex align-items-end'}>
                            <HeroLeftSvg className={'left-svg'}/>
                        </Col>
                        <Col xs={12} lg={6}
                             className={'mt-lg-5 pt-lg-4 d-flex flex-column align-items-center align-items-lg-start'}>
                            <p className={'title'}>
                                Become the master of
                                <br/>
                                <span>your platform</span>
                            </p>
                            <div className={'d-flex w-100 justify-content-end justify-content-md-end' +
                                ' justify-content-lg-center'}>
                                <HeroRightSvg className={'right-svg'}/>
                            </div>
                        </Col>
                    </Row>
                    <div className={'spacer-bottom'}/>
                    {
                        loading || showBothColumns
                            ? <Row className={'justify-content-between'}>
                                <Col xs={12} lg={6} xl={5}>
                                    <HomeItemsContainer {...items[0]} />
                                </Col>
                                <Col xs={12} lg={6} xl={5}>
                                    <HomeItemsContainer {...items[1]} />
                                </Col>
                            </Row>
                            : <Row>
                                <Col xs={12}>
                                    {items[0] && <HomeItemsContainer {...items[0]} />}
                                </Col>
                            </Row>
                    }
                </Container>
                <div className={'future'}>
                    <Container>
                        {
                            showFutureRightSvg
                                ? <>
                                    <Row>
                                        <div className={'spacer'}/>
                                        <Col xs={12} className={'content-container'}>
                                            <p className={'title'}>
                                                Learn to build the
                                                <br/>
                                                future
                                            </p>
                                            <FutureRightSvg className={'right-svg'}/>
                                            {content}
                                        </Col>
                                    </Row>
                                </>
                                : <>
                                    <Row>
                                        <div className={'spacer'}/>
                                        <Col xs={12}>
                                            <p className={'title'}>
                                                Learn to build the future
                                            </p>
                                        </Col>
                                        <Col xs={12}>
                                            {content}
                                        </Col>
                                        <Col xs={12}>
                                            <FutureLeftSvg className={'left-svg'}/>
                                        </Col>
                                    </Row>
                                </>
                        }
                        <div className={'spacer-bottom'}/>
                        <Row>
                            <Col xs={12}>
                                {
                                    items?.length > 2 && items[2] &&
                                    <HomeItemsContainer {...items[2]} />
                                }
                                {
                                    items?.length === 2 && items[1] &&
                                    <HomeItemsContainer {...items[1]} />
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>
                {
                    ((items?.length > 3 && items[3]) || (items?.length === 3 && items[2])) &&
                    <Container className={'entries'}>
                        <Row>
                            <Col xs={12}>
                                {
                                    items?.length > 3 && items[3] &&
                                    <HomeItemsContainer {...items[3]} />
                                }
                                {
                                    items?.length === 3 && items[2] &&
                                    <HomeItemsContainer {...items[2]} />
                                }
                            </Col>
                        </Row>
                    </Container>
                }
            </div>
        </>
    )
}

export default HomeView;
