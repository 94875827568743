/*
 *
 */

import React, {useEffect} from "react";
import EnvService from "../../../../../core/services/env-service";
import useRouter from "../../../../hooks/use-router";
import AppRoutes from "../../../../../core/models/static/routes/app-routes";
import useIsMounted from "../../../../hooks/use-is-mounted";
import CacheService from "../../../../../core/services/cache/cache-service";

const AuthLoginView = () => {
    const {navigate, params} = useRouter();
    const isMounted = useIsMounted();

    /**
     *  With each change in params:
     * - logs the user in Biz Learns' server with the given params of this view.
     */
    useEffect(() => {
        login().then();
    }, [params])

    /**
     * Logs the user into the system with given the token received form the core system.
     * @return {Promise<void>}
     */
    const login = async () => {
        /** @type {BizLearnApiRequestModels.Auth.Login}*/
        const forApi = {
            token: params.token,
        }
        const forApiExtra = {
            showSuccessToast: true,
        }
        // TODO: call api for logging into the system
        const response = {
            resultFlag: true,
            data: {
                token: params.token,
                tokenLifeSpanMinutes: parseInt(params.time),
                firstName: "Dear",
                lastName: "Operator",
                email: "operator@bizkeytech.com",
                profileImageUrl: undefined,
            },
        }
        if (!isMounted()) return;
        if (!response?.resultFlag) {
            return navigate(AppRoutes.public.error.accessDenied, {replace: true})
        }
        cacheLoginInformation(response.data);
        // TODO: notification registration but async ??
        redirectUser();
    }


    /**
     * Caches the logged-in user information from the login response.
     * @param {Partial<BizLearnApiResponseModels.Auth.Login>} data
     */
    const cacheLoginInformation = (data) => {
        /**@type {InAppModels.UserInfo}*/
        const userInfo = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            profileImageUrl: data.profileImageUrl,
        }
        CacheService.setLoggedInUserInfo(data.token, data.tokenLifeSpanMinutes, !EnvService.showBiz ? undefined : userInfo);

    }

    /**
     * Redirects the user either to the cached url of the application, or the home page.
     */
    const redirectUser = () => {
        const url = CacheService.getCachedUrl(true) ?? AppRoutes.private.home;
        navigate(url, {replace: true});
    }

    return (
        <>
            <p>
                Welcome back to <span>{EnvService.title}</span>
            </p>
            <p>
                Finishing up you login process
            </p>
        </>
    )
}

export default AuthLoginView;
