/*
 *
 */

import React, {useEffect} from "react";
import EnvService from "../../../../../core/services/env-service";
import useRouter from "../../../../hooks/use-router";
import {useSelector} from "react-redux";
import ReduxStoreRoutes from "../../../../../core/models/static/routes/redux-store-routes";
import Utils from "../../../../../core/services/utils";
import AppRoutes from "../../../../../core/models/static/routes/app-routes";

const AuthRedirectView = () => {
    const {navigate} = useRouter();
    /** @type {InAppModels.Redux.Config}*/
    const config = useSelector(state => state[ReduxStoreRoutes.config])

    /**
     * As soon as the component mounts:
     * - if loginUrl is not filled, redirects the user to the error view, otherwise redirects them to the login view
     */
    useEffect(() => {
        if (!config.loginUrl) {
            return navigate(AppRoutes.public.error.serverError, {replace: true});
        }
        redirectToLogin().then();
    }, [])

    /**
     * Redirects the user to the login view specified in the loginUrl.
     * @return {Promise<void>}
     */
    const redirectToLogin = async () => {
        await Utils.wait(1000);
        window.location.replace(`${config.loginUrl}/${encodeURIComponent(window.location.origin + AppRoutes.public.auth.login.base)}`);
    }

    return (
        <>
            <p>
                Welcome back to <span>{EnvService.title}</span>
                <br/>
            </p>
            <p>
                Redirecting you to the Login page
            </p>
        </>
    )
}

export default AuthRedirectView;
