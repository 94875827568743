/*
 *
 */

import React, {forwardRef, useImperativeHandle, useMemo, useRef} from "react";
import YoutubeVideoPlayer from "./youtube";
import NativeVideoPlayer from "./native";
import Utils from "../../../../core/services/utils";

const VideoPlayer = ({id, url: urlProps, title, type}, ref) => {
    const idRef = useRef(id ?? Utils.createUUId(true));
    const innerRef = useRef();

    const url = useMemo(() => Utils.correctUrl(urlProps), [urlProps])
    const isVideoFromYoutube = useMemo(() => Utils.isYoutubeUrl(url), [url]);

    const youtubeVideoId = useMemo(() => Utils.getYoutubeVideoId(url), [url]);

    /**
     * Injects the properties obtained from the inner ref to the given ref.
     * * The properties are identical from those injected in the youtube players.
     */
    useImperativeHandle(ref, () => ({
        ...(innerRef.current ?? {})
    }), [innerRef])

    return (
        <>
            {
                isVideoFromYoutube
                    ? <YoutubeVideoPlayer
                        key={idRef.current}
                        ref={innerRef}
                        id={`video-${idRef.current}`}
                        videoId={youtubeVideoId}
                        title={title}
                        type={type}
                    />
                    : <NativeVideoPlayer
                        key={idRef.current}
                        ref={innerRef}
                        id={`video-${idRef.current}`}
                        src={url}
                        title={title}
                        type={type}
                    />
            }
        </>
    )
}

export default forwardRef(VideoPlayer);
