/*
 *
 */

import {createSlice} from "@reduxjs/toolkit";
import ReduxStoreRoutes from "../../../core/models/static/routes/redux-store-routes";

/**
 * Initial state of the header slice.
 * @type {InAppModels.Redux.Header}
 */
export const reduxStoreHeaderInitialState = {
    backgroundColor: 'defaultBackgroundColor',
}

/**
 * config slice that exists in the redux store.
 * this slice is used to contain the general configuration of the application.
 */
const reduxStoreHeaderSlice = createSlice({
    name: ReduxStoreRoutes.header,
    initialState: reduxStoreHeaderInitialState,
    reducers: {
        /**
         * Sets header background color.
         * @param state
         * @param action
         */
        setBackgroundColor: (state, action) => {
            state = {
                ...state,
                backgroundColor: action.payload,
            }
            return state;
        },
    }
})

export default reduxStoreHeaderSlice;
