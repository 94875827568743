/*
 *
 */

import ApiRequestModels from "../models/requests";

/**
 * The base class for all the api components in the application. This class is responsible for injecting the
 * properties necessary for inheriting classes to create their components. Each component of the inheriting
 * classes represent the decomposed and componentLike apis calls.
 *
 * This class is only meant to be inherited from and not used directly in the app.
 */
class BaseApiComponentsMixin {
    /**
     * The Endpoints that determine the route of the api calls for this interface. The inheriting classes must
     * instantiate these endpoints.
     * @protected
     */
    protected static readonly endpoints: object;

    /**
     * Injects the properties of the extra to the request.
     *
     * @param {ApiRequestModels.ApiRequest} request the request to be expanded with the injected props.
     * @param {ApiRequestModels.BaseApiRequest} extra extra params given to it
     */
    static injectExtraRequestProperties(request: ApiRequestModels.ApiRequest, extra?: ApiRequestModels.BaseApiRequest)
        : ApiRequestModels.ApiRequest {
        return {
            ...request,
            ...(extra ?? {}),
        }
    }
}

export default BaseApiComponentsMixin
