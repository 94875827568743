/*
 *
 */

import reduxStoreHeaderSlice from "./slice";

const _actions = reduxStoreHeaderSlice.actions;

/**
 * Sets the config information of the application.
 *
 * @param {string} backgroundColor
 */
export const setHeaderBackgroundColorRedux = (backgroundColor) => (dispatch) => dispatch(_actions.setBackgroundColor(backgroundColor));
