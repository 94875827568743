/*
 *
 */

/**
 * This interface is responsible for generating scss modules based on the given themes.
 */
class ScssGenerator {

    /**
     * Generates the color variables map for dynamic color class creations.
     *
     * @param {string} prefix  used as an identifier as the color name of the app in :root
     * @param {Array<string>} suffixes the suffixes used to add different variations of the colors
     * @param {Set<string>} colors the color names of the app.
     * @return {string} the generated scss formatted variables.
     * @private
     */
    private static _generateColorVariablesMap(prefix: string, suffixes: Array<string>, colors: Set<string>) {
        let output =
            "\n// **************** APPLICATION DYNAMIC COLORS ****************\n" +
            "// This output is autogenerated. It is strongly recommended not to change the following declarations.\n\n"

        output += `$color-names: (\n`;
        let index = -2;
        colors.forEach((color: string) => {
            output += `\t\t'${color.slice(prefix.length)}': var(${color}),\n`
            if (!(index++ % suffixes.length)) {
                output += `\n`
            }
        })
        output += `);\n`;
        return output;
    }

    /**
     * Generates the color variables for scss files referencing.
     *
     * @param {string} prefix  used as an identifier as the color name of the app in :root
     * @param {Array<string>} suffixes the suffixes used to add different variations of the colors
     * @param {Set<string>} colors the color names of the app.
     * @return {string} the generated scss formatted variables.
     * @private
     */
    private static _generateColorVariables(prefix: string, suffixes: Array<string>, colors: Set<string>) {
        let output =
            "\n// **************** APPLICATION COLORS ****************\n" +
            "// This output is autogenerated. It is strongly recommended not to change the following declarations.\n\n"

        let index = -2;
        colors.forEach((color: string) => {
            output += `$${color.slice(prefix.length)}: var(${color});\n`
            if (!(index++ % suffixes.length)) {
                output += `\n`
            }
        })
        return output;
    }

    /**
     * Generates color theme variables from the given color themes.
     *
     * @param {string} prefix  used as an identifier as the color name of the app in :root
     * @param {Array<string>} suffixes the suffixes used to add different variations of the colors
     * @param {Array<any>} themes the color themes to generate the variables from.
     * @return {string} the generated scss formatted variables.
     */
    static generateColorThemeVariables(prefix: string, suffixes: Array<string>, themes: Array<any>): string {
        const colors = new Set<string>();
        for (const _theme of themes) {
            const theme = [..._theme.slice(1)];
            for (const [colorName] of theme) {
                colors.add(colorName);
            }
        }
        return ''
            .concat(this._generateColorVariablesMap(prefix, suffixes, colors))
            .concat(this._generateColorVariables(prefix, suffixes, colors))
    }
}

export default ScssGenerator;
