/*
 *
 */

import React, {useEffect, useLayoutEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ReduxStoreRoutes from "../../../../core/models/static/routes/redux-store-routes";
import useRouter from "../../../hooks/use-router";
import AppRoutes from "../../../../core/models/static/routes/app-routes";
import useIsMounted from "../../../hooks/use-is-mounted";
import {setConfigRedux} from "../../../../redux/slices/config/actions";
import {Outlet} from "react-router-dom";
import LoadingGif from "../../../../assets/images/loading.gif";
import LazyImage from "../../../components/app-specific/lazy-lmage";
import {AppElementId} from "../../../../core/constants/ids";

const AuthViews = () => {
    const {navigate} = useRouter();
    const [loading, setLoading] = useState(true);
    /** @type {InAppModels.Redux.Config}*/
    const config = useSelector(state => state[ReduxStoreRoutes.config])
    const dispatch = useDispatch();
    const isMounted = useIsMounted();

    /**
     * As soon as the component mounts:
     * - adds the centered className to the app element and remove it after unmount.
     */
    useLayoutEffect(() => {
        const appElement = document.getElementById(AppElementId);
        if (!appElement) return;
        appElement.classList.add('centered');
        return () => {
            appElement.classList.remove('centered')
        };
    }, [])

    /**
     * As soon as the component mounts:
     * - if both login and logout urls are available in the redux slice (not the first time visiting this view) does
     * nothing, otherwise, fetches them in the server.
     *
     */
    useEffect(() => {
        if (config.loginUrl && config.logoutUrl) {
            setLoading(false);
            return;
        }
        getApplicationConfig().then();
    }, [])

    /**
     * Fetches the application configurations from the server.
     *
     * - populates the redux config slice if the response is successful
     * - navigates the user to serverError view if response is not successful
     * @return {Promise<void>}
     */
    const getApplicationConfig = async () => {
        setLoading(true);
        // TODO: call api for getting application config.
        const response = {
            resultFlag: true,
            data: {
                loginUrl: 'https://bizcore.bizkeytech.com/login',
                logoutUrl: 'https://bizcore.bizkeytech.com/logout',
            },
        }
        if (!isMounted()) return;
        if (!response?.resultFlag) {
            // if we can not fetch the login / logout urls in the auth views, we can not do anything in this view.
            return navigate(AppRoutes.public.error.serverError);
        }
        saveConfiguration(response.data);
        setLoading(false);
    }

    /**
     * Saves the application configuration in its redux slice.
     * @param {BizLearnApiResponseModels.Auth.Config} data
     */
    const saveConfiguration = (data) => {
        /**@type {InAppModels.Redux.Config}*/
        const config = {
            loginUrl: data.loginUrl,
            logoutUrl: data.logoutUrl,
        }
        dispatch(setConfigRedux(config));
    }

    return (
        <>
            <div className={'auth-view'}>
                <div className={'loading-container'}>
                    <LazyImage src={LoadingGif} alt="loading"/>
                </div>
                {
                    !loading &&
                    <Outlet/>
                }
            </div>
        </>
    )
}

export default AuthViews;
