/*
 *
 */

import React, {useMemo} from "react";
import {LogoTypes} from "../../../../core/constants/enums";
import {Container, Nav, Navbar} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import classnames from "classnames";
import useColorTheme from "../../../hooks/use-color-theme";
import useWindowViewportWidth from "../../../hooks/use_window-viewport-width";
import AppLogo from "../app-logo";
import AppRoutes from "../../../../core/models/static/routes/app-routes";
import useRouter from "../../../hooks/use-router";
import {matchPath, matchRoutes} from "react-router-dom";
import {IconButton, Tooltip} from "@mui/material";
import {ReactComponent as LogoutIcon} from "../../../../assets/images/header/logout.svg";
import {ReactComponent as UserIcon} from "../../../../assets/images/header/user.svg";
import AppHeaderAppsSection from "./sections/apps";
import AppHeaderSearchbar from "./sections/searchbar";
import {Menu} from "@mui/icons-material";
import {useSelector} from "react-redux";
import ReduxStoreRoutes from "../../../../core/models/static/routes/redux-store-routes";
import ColorThemeService from "../../../../core/services/color-theme-service";
import {ContentQueryParams} from "../../../../core/constants/query-params";
import EnvService from "../../../../core/services/env-service";

const AppHeader = () => {
    const {location, navigate, query} = useRouter();
    const viewportWidth = useWindowViewportWidth();
    const [theme] = useColorTheme();
    /** @type {InAppModels.Redux.Header} */
    const headerRedux = useSelector(state => state[ReduxStoreRoutes.header]);

    const actionsStickToRight = ['xs'].includes(viewportWidth);
    const headerExpand = 'md';
    const inputFullWidth = ['xs'].includes(viewportWidth);
    const inputStickToRight = ['sm'].includes(viewportWidth);
    const showNavLinksLast = ['xs', 'sm'].includes(viewportWidth);

    /**
     * The search bar's initial value.
     *
     * this value is only filled if we are in the search view
     * @type {unknown}
     */
    const searchbarInitialValue = useMemo(() => {
        const searchRoutes = [
            AppRoutes.private.search,
        ]
        const isInSearchRoutes = !!matchRoutes(searchRoutes.map(e => ({path: e})), location);
        if (isInSearchRoutes) {
            return query[ContentQueryParams.keywords]
        }
        return '';
    }, [location, query])

    /**
     * Determines if the nav link should be activated.
     * @param {string} path NavLink's path.
     * @return {boolean}
     */
    const isNavLinkActive = (path) => {
        return !!matchPath(location.pathname, path);
    }

    /**
     * Navigates the user to profile view.
     */
    const navigateToUserProfile = () => {
        if (!EnvService.showBiz) {
            return window.location = EnvService.AdminLoginUrl;
        }
        // TODO: navigate to user profile page.
    }

    /**
     * Navigates the user to the logout view.
     */
    const navigateToLogout = () => {
        navigate(AppRoutes.public.auth.logout);
    }

    return (
        <>
            <Navbar
                variant={theme}
                style={{
                    '--background-color': ColorThemeService[headerRedux.backgroundColor],
                }}
                className={classnames('app-header')}
                collapseOnSelect
                expand={headerExpand}>
                <Container>
                    <LinkContainer to={AppRoutes.private.home}>
                        <Navbar.Brand className={'order-1'}>
                            <AppLogo type={LogoTypes.primary}/>
                        </Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle
                        aria-controls="responsive-navbar-nav"
                        className={classnames(
                            'order-3 order-sm-5 order-md-2',
                            'MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeSmall icon-button'
                        )}>
                        <Menu className={'mui'}/>
                    </Navbar.Toggle>
                    <Navbar.Collapse
                        id="responsive-navbar-nav"
                        className={classnames('order-md-2', {
                            'order-last': showNavLinksLast,
                            'mt-3': inputFullWidth
                        })}>
                        <Nav className="me-auto">
                            <LinkContainer to={AppRoutes.private.courses.list}>
                                <Nav.Link active={isNavLinkActive(AppRoutes.private.courses._base)}>
                                    Courses
                                </Nav.Link>
                            </LinkContainer>
                            <LinkContainer to={AppRoutes.private.videos.list}>
                                <Nav.Link active={isNavLinkActive(AppRoutes.private.videos._base)}>
                                    Videos
                                </Nav.Link>
                            </LinkContainer>
                        </Nav>
                    </Navbar.Collapse>
                    <>
                        <div className={classnames('order-4 order-sm-2 order-md-3', {
                            'ms-auto': inputStickToRight,
                            'mt-3 w-100': inputFullWidth,
                        })}>
                            <AppHeaderSearchbar initialValue={searchbarInitialValue}/>
                        </div>
                        <div className={classnames('actions order-2 order-md-5', {
                            'ms-auto': actionsStickToRight,
                        })}>
                            {/*<AppHeaderAppsSection/>*/}
                            {/*<Tooltip title={!EnvService.showBiz ? "Admin Page" : 'User Profile'}>*/}
                            {/*    <IconButton*/}
                            {/*        size={"small"}*/}
                            {/*        onClick={navigateToUserProfile}*/}
                            {/*        className={'icon-button'}>*/}
                            {/*        <UserIcon className={'custom'}/>*/}
                            {/*    </IconButton>*/}
                            {/*</Tooltip>*/}
                            <Tooltip title={'Logout'}>
                                <IconButton
                                    size={"small"}
                                    onClick={navigateToLogout}
                                    className={'icon-button'}>
                                    <LogoutIcon className={'custom'}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    </>
                </Container>
            </Navbar>
        </>
    )
}

export default AppHeader;
