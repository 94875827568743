/*
 *
 */

import ColorInjector from "./color-injector";
import {ColorThemes} from "../../constants/enums";


/**
 * The Interface for holding the available colors of the application.
 *
 * Note: the name of the color properties are exactly reflected in the css files so name them properly.
 */
export abstract class _Colors extends ColorInjector {
    readonly primaryColor = 'rgba(88, 107, 158, 1)';
    readonly primaryColorDark = 'rgba(79, 96, 142, 1)';
    readonly secondaryColor = 'rgba(249, 111, 93, 1)';

    readonly red = 'rgba(235, 87, 87, 1)';
    readonly redDark = 'rgba(165, 20, 20, 1)';
    readonly green = 'rgba(111, 255, 9, 1)';
    readonly yellow = 'rgba(248, 163, 13, 1)';

    readonly white = 'rgba(255, 255, 255, 1)';
    readonly black = 'rgba(0, 0, 0, 1)';

    readonly disabledButtonBackgroundColor = 'rgba(128,128,128, 1)';

    abstract readonly primaryTextColor: string;
    abstract readonly primaryTextContrastColor: string;
    abstract readonly secondaryTextColor: string;
    abstract readonly disabledTextColor: string;
    abstract readonly hintTextColor: string;

    abstract readonly defaultBackgroundColor: string;
    abstract readonly videoPlaylistBackgroundColor: string;
    abstract readonly searchBackgroundColor: string;

    abstract readonly homeViewFutureBackgroundColor: string;
    abstract readonly homeViewFutureColor: string;

    abstract readonly footerBackgroundColor: string;
    abstract readonly footerColor: string;

    abstract readonly inputBackgroundColor: string;

    abstract readonly cardBackgroundColor: string;

    abstract readonly defaultBoxShadowColor: string;
    abstract readonly hoveredCardBoxShadowColor: string;
    abstract readonly containedButtonBoxShadowColor: string;

    abstract readonly iconButtonSvgColor: string;
    abstract readonly iconButtonSvgHoverColor: string;
    abstract readonly buttonPrimaryBackgroundColor: string;
    abstract readonly buttonPrimaryColor: string;
    abstract readonly buttonSecondaryBackgroundColor: string;
    abstract readonly buttonSecondaryColor: string;

    abstract readonly videoPlayerButtonColor: string;
    abstract readonly videoPlayerButtonBackgroundColor: string;
    abstract readonly videoPlayerButtonHoverColor: string;
    abstract readonly videoPlayerButtonHoverBackgroundColor: string;
    abstract readonly videoPlayerProgressValueColor: string;
    abstract readonly videoPlayerProgressbarColor: string;

    abstract readonly scrollbarTrackColor: string;
    abstract readonly scrollbarThumbColor: string;
}

/**
 * The Light Colors that are used in the application.
 */
export class LightColors extends _Colors {
    readonly primaryTextColor = "rgba(0, 0, 0, 0.87)";
    readonly primaryTextContrastColor = "rgba(255, 255, 255, 1)";
    readonly secondaryTextColor = "rgba(0, 0, 0, 0.6)";
    readonly disabledTextColor = "rgba(0, 0, 0, 0.38)";
    readonly hintTextColor = "rgba(0, 0, 0, 0.6)";


    readonly defaultBackgroundColor = 'rgba(250, 251, 252, 1)';
    readonly videoPlaylistBackgroundColor = this.white;
    readonly searchBackgroundColor = 'rgba(242, 244, 247, 1)';

    readonly homeViewFutureBackgroundColor = this.primaryColor;
    readonly homeViewFutureColor = this.white;

    readonly footerBackgroundColor = this.primaryColor;
    readonly footerColor = this.white;

    readonly inputBackgroundColor = this.white;

    readonly cardBackgroundColor = this.white;

    readonly defaultBoxShadowColor = 'rgba(0, 0, 0, 0.08)';
    readonly hoveredCardBoxShadowColor = this.defaultBoxShadowColor;
    readonly containedButtonBoxShadowColor = this.defaultBoxShadowColor;

    readonly iconButtonSvgColor = 'rgba(0, 0, 0, 1)';
    readonly iconButtonSvgHoverColor = this.secondaryColor;

    readonly buttonPrimaryBackgroundColor = this.white;
    readonly buttonPrimaryColor = this.secondaryColor;

    readonly buttonSecondaryBackgroundColor = this.secondaryColor;
    readonly buttonSecondaryColor = this.white;

    readonly videoPlayerButtonColor = this.white;
    readonly videoPlayerButtonBackgroundColor = 'rgba(88, 107, 158, 0.5)';
    readonly videoPlayerButtonHoverColor = this.white;
    readonly videoPlayerButtonHoverBackgroundColor = this.primaryColor;
    readonly videoPlayerProgressValueColor = this.white;
    readonly videoPlayerProgressbarColor = 'rgba(88, 107, 158, 0.4)';

    readonly scrollbarTrackColor = 'rgba(231, 232, 237, 1)';
    readonly scrollbarThumbColor = 'rgba(139, 154, 187, 1)';

    /**
     * Fetches the selector that is used for injecting the css theme.
     */
    protected getThemeCssSelector(): string {
        return ":root"
    };


}

/**
 * The Dark Colors that are used in the application.
 */
export class DarkColors extends _Colors {
    readonly primaryTextColor = this.white;
    readonly primaryTextContrastColor = "rgba(0, 0, 0, 0.87)";
    readonly secondaryTextColor = "rgba(255, 255, 255, 0.7)";
    readonly disabledTextColor = "rgba(255, 255, 255, 0.5)";
    readonly hintTextColor = "rgba(255, 255, 255, 0.7)";

    readonly defaultBackgroundColor = 'rgba(18, 18, 18, 1)';
    readonly videoPlaylistBackgroundColor = this.white;
    readonly searchBackgroundColor = 'rgba(242, 244, 247, 1)';

    readonly homeViewFutureBackgroundColor = this.primaryColor;
    readonly homeViewFutureColor = this.white;

    readonly footerBackgroundColor = this.primaryColor;
    readonly footerColor = this.white;

    readonly inputBackgroundColor = this.white;

    readonly cardBackgroundColor = this.white;

    readonly defaultBoxShadowColor = 'rgba(0, 0, 0, 0.08)';
    readonly hoveredCardBoxShadowColor = this.defaultBoxShadowColor;
    readonly containedButtonBoxShadowColor = this.defaultBoxShadowColor;

    readonly iconButtonSvgColor = 'rgba(0, 0, 0, 1)';
    readonly iconButtonSvgHoverColor = this.secondaryColor;

    readonly buttonPrimaryBackgroundColor = this.white;
    readonly buttonPrimaryColor = this.secondaryColor;

    readonly buttonSecondaryBackgroundColor = this.secondaryColor;
    readonly buttonSecondaryColor = this.white;

    readonly videoPlayerButtonColor = this.white;
    readonly videoPlayerButtonBackgroundColor = 'rgba(88, 107, 158, 0.5)';
    readonly videoPlayerButtonHoverColor = this.white;
    readonly videoPlayerButtonHoverBackgroundColor = this.primaryColor;
    readonly videoPlayerProgressValueColor = this.white;
    readonly videoPlayerProgressbarColor = 'rgba(88, 107, 158, 0.4)';

    readonly scrollbarTrackColor = 'rgba(231, 232, 237, 1)';
    readonly scrollbarThumbColor = 'rgba(139, 154, 187, 1)';

    /**
     * Fetches the selector that is used for injecting the css theme.
     */
    protected getThemeCssSelector(): string {
        return `[data-theme="${ColorThemes.dark}"]`
    };
}
