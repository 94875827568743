/*
 *
 */

import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ReduxStoreRoutes from "../../../../core/models/static/routes/redux-store-routes";
import {setReduxDialogState} from "../../../../redux/slices/dialogs/actions";
import Dialog from "../../base/dialog";
import {Button, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

export const ConfirmationDialog = () => {
    /** @type {InAppModels.Redux.ConfirmationDialogState}*/
    const state = useSelector(state => state[ReduxStoreRoutes.dialogs.base][ReduxStoreRoutes.dialogs.confirmation]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    /**
     * With each change in open state of the dialog:
     * - if the dialog is closed, then sets the loading to false too
     */
    useEffect(() => {
        if (!state?.open) {
            setLoading(false);
        }
    }, [state?.open])

    /**
     * Closes the dialog.
     *
     * * calls the callback if the user has confirmed the operation otherwise does nothing.
     * if the callback is called, it is awaited for its response and only closes the dialog if the resultFlag is true.
     * @param {boolean} accepted
     * @param {boolean} auto
     */
    const closeDialog = async (accepted = false, auto = false) => {
        if (!accepted && auto) {
            return dispatch(setReduxDialogState(ReduxStoreRoutes.dialogs.confirmation, {
                open: false
            }))
        }
        if (typeof state?.callback === 'function') {
            setLoading(true);
            const resultFlag = await state?.callback(accepted);
            if (resultFlag) {
                dispatch(setReduxDialogState(ReduxStoreRoutes.dialogs.confirmation, {
                    open: false
                }))
            }
            setLoading(false);
        }
    }

    return (
        <Dialog
            open={state?.open}
            onClose={() => closeDialog(false, true)}
            className={'app-dialog confirmation-dialog'}
            maxWidth={'sm'}
            aria-labelledby={'aria-label'}>
            <DialogTitle id={'aria-label'} className={'title'}>
                {state?.title ?? ''}
            </DialogTitle>
            <DialogContent className={'content'}>
                <DialogContentText className={'message'}>
                    {state?.description ?? ""}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={'d-flex align-items-center justify-content-end'}>
                <Button
                    color={'secondary'}
                    onClick={() => closeDialog(false)}
                    className={'mx-2'}
                    disabled={loading}>
                    {
                        loading
                            ? "waiting...."
                            : state?.cancelText ?? ''
                    }
                </Button>
                <Button
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={() => closeDialog(true)}
                    className={'mx-2'}
                    disabled={loading}>
                    {
                        loading
                            ? "Processing...."
                            : state?.proceedText ?? ''
                    }
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmationDialog;
