/*
 *
 */

import reduxStoreUserInfoSlice from "./slice";

const _actions = reduxStoreUserInfoSlice.actions;

/**
 * Sets the profile information of the user.
 *
 * the given info could be partial as whatever is given is merged with the existing data to preserve as much as data
 * as possible. In case of removing a property, set it as null explicitly.
 * @param {InAppModels.UserInfo} info
 * @param {boolean} cache whether to cache the updated info or not.
 */
export const setUserInfoRedux = (info, cache = false) => (dispatch) => dispatch(_actions.setInfo({
    info: info,
    cache: cache
}));

/**
 * Removes the user information by restoring the initial state of the userInfo slice.
 */
export const removeUserInfoRedux = () => (dispatch) => dispatch(_actions.removeInfo());
