/*
 *
 */

import BizLearnApiEndpoints from "../endpoints";
import {apiMethods} from "../../../../../constants/enums";
import ApiRequestModels from "../../base/models/requests";
import BaseApiComponentsMixin from "../../base/mixins/components";
import BizLearnApiRequestModels from "../models/requests";
import BizLearnApiResponseModels from "../models/responses";

/**
 * This interface provides the decomposed and componentLike apis that are available in the BizLearn server.
 */
class BizLearnApiComponentsMixin extends BaseApiComponentsMixin {
    /**
     * The Teacher Assessment Api Endpoints that are to be used in api component creation process.
     * @protected
     */
    protected static readonly endpoints = BizLearnApiEndpoints;


    // ################################         Application         ################################

    /**
     * Fetches the list of available applications
     *
     * @return {ApiRequestModels.ApiRequest}
     * @private
     */
    static _getApplications()
        : ApiRequestModels.ApiRequest {
        return {
            url: this.endpoints.applicationEndpoints.list,
            method: apiMethods.get,
        }
    }

    // ################################         Course         ################################

    /**
     * Searches among the courses of the system with the given filters
     *
     * @param {BizLearnApiRequestModels.Course.Search} data search filters
     * @return {ApiRequestModels.ApiRequest<BizLearnApiRequestModels.Course.Search>}
     * @private
     */
    static _searchCourses(data: BizLearnApiRequestModels.Course.Search)
        : ApiRequestModels.ApiRequest<BizLearnApiRequestModels.Course.Search> {
        return {
            url: this.endpoints.courseEndpoints.search,
            method: apiMethods.post,
            data: data,
        }
    }

    /**
     * Fetches the information of a course provided by its id.
     *
     * @param {number} id the course id to fetch its information
     * @return {ApiRequestModels.ApiRequest}
     * @private
     */
    static _getCourseInformation(id: number)
        : ApiRequestModels.ApiRequest {
        return {
            url: this.endpoints.courseEndpoints.getById(id),
            method: apiMethods.get,
        }
    }

    // ################################         Main Page         ################################

    /**
     * Fetches the data of the main page of the application.
     *
     * @return {ApiRequestModels.ApiRequest}
     * @private
     */
    static _getMainPageData()
        : ApiRequestModels.ApiRequest {
        return {
            url: this.endpoints.mainPageEndpoints.get,
            method: apiMethods.get,
        }
    }

    /**
     * Searches among all the content of the system with the given search filters
     *
     * @param {BizLearnApiRequestModels.MainPage.Search} data search filters
     * @return {ApiRequestModels.ApiRequest<BizLearnApiRequestModels.MainPage.Search>}
     * @private
     */
    static _searchContent(data: BizLearnApiRequestModels.MainPage.Search)
        : ApiRequestModels.ApiRequest<BizLearnApiRequestModels.MainPage.Search> {
        return {
            url: this.endpoints.mainPageEndpoints.search,
            method: apiMethods.post,
            data: data,
        }
    }

    // ################################         Notebook         ################################

    /**
     * Creates a new note for a specific video in the system
     *
     * @param {BizLearnApiRequestModels.Notebook.Create} data note to be created
     * @return {ApiRequestModels.ApiRequest<BizLearnApiRequestModels.Notebook.Create>}
     * @private
     */
    static _createVideoNote(data: BizLearnApiRequestModels.Notebook.Create)
        : ApiRequestModels.ApiRequest<BizLearnApiRequestModels.Notebook.Create> {
        return {
            url: this.endpoints.notebookEndpoints.create,
            method: apiMethods.post,
            data: data,
        }
    }

    /**
     * Updates a specific note for a specific video in the system
     *
     * @param {BizLearnApiResponseModels.Notebook.Note} data note to be updated
     * @return {ApiRequestModels.ApiRequest<BizLearnApiResponseModels.Notebook.Note>}
     * @private
     */
    static _updateVideoNote(data: BizLearnApiResponseModels.Notebook.Note)
        : ApiRequestModels.ApiRequest<BizLearnApiResponseModels.Notebook.Note> {
        return {
            url: this.endpoints.notebookEndpoints.update,
            method: apiMethods.put,
            data: data,
        }
    }

    /**
     * Removes a specific note from a specific video in the system
     *
     * @param {number} id id of the note to be removed
     * @return {ApiRequestModels.ApiRequest}
     * @private
     */
    static _removeVideoNote(id: number)
        : ApiRequestModels.ApiRequest {
        return {
            url: this.endpoints.notebookEndpoints.remove(id),
            method: apiMethods.delete,
        }
    }

    // ################################         Video         ################################

    /**
     * Toggles the isSaved state of a specific video.
     *
     * @param {BizLearnApiRequestModels.Video.ToggleIsSaved} data data for toggling is saved state
     * @return {ApiRequestModels.ApiRequest<BizLearnApiRequestModels.Video.ToggleIsSaved>}
     * @private
     */
    static _toggleVideosIsSaved(data: BizLearnApiRequestModels.Video.ToggleIsSaved)
        : ApiRequestModels.ApiRequest<BizLearnApiRequestModels.Video.ToggleIsSaved> {
        return {
            url: this.endpoints.videoEndpoints.toggleIsSaved,
            method: apiMethods.put,
            data: data,
        }
    }

    /**
     * Searches among the saved videos of the users with the given filters.
     *
     * @param {BizLearnApiRequestModels.Video.Search} data search filters
     * @return {ApiRequestModels.ApiRequest}
     * @private
     */
    static _searchSavedVideos(data: BizLearnApiRequestModels.Video.Search)
        : ApiRequestModels.ApiRequest<BizLearnApiRequestModels.Video.Search> {
        return {
            url: this.endpoints.videoEndpoints.searchSavedOnly,
            method: apiMethods.post,
            data: data,
        }
    }

    /**
     * Searches among the in progress videos of the users with the given filters.
     *
     * @param {BizLearnApiRequestModels.Video.Search} data search filters
     * @return {ApiRequestModels.ApiRequest}
     * @private
     */
    static _searchInProgressVideos(data: BizLearnApiRequestModels.Video.Search)
        : ApiRequestModels.ApiRequest<BizLearnApiRequestModels.Video.Search> {
        return {
            url: this.endpoints.videoEndpoints.searchInProgressOnly,
            method: apiMethods.post,
            data: data,
        }
    }

    /**
     * Fetches the full information of a video with its playlist and notebook with the given id.
     *
     * @param {number} id id of the video to load.
     * @return {ApiRequestModels.ApiRequest}
     * @private
     */
    static _getVideoInformation(id: number)
        : ApiRequestModels.ApiRequest {
        return {
            url: this.endpoints.videoEndpoints.getById(id),
            method: apiMethods.get,
        }
    }
}

export default BizLearnApiComponentsMixin;
