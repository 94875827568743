/*
 *
 */

import React, {useEffect, useRef, useState} from "react";
import useIsMounted from "../../../hooks/use-is-mounted";
import {useDispatch} from "react-redux";
import {setReduxDialogState} from "../../../../redux/slices/dialogs/actions";
import ReduxStoreRoutes from "../../../../core/models/static/routes/redux-store-routes";
import {Button, CircularProgress, Fade, IconButton, Tooltip} from "@mui/material";
import Utils from "../../../../core/services/utils";
import {ReactComponent as EditIcon} from "../../../../assets/images/edit.svg";
import {ReactComponent as RemoveIcon} from "../../../../assets/images/remove.svg";
import {CheckRounded, CloseRounded} from "@mui/icons-material";
import Form from "../../base/form";
import * as Yup from "yup";
import ValidateMessages from "../../../../core/models/static/validate-messages";
import {makeRequired, makeValidate} from "mui-rff";
import {Col} from "react-bootstrap";
import Input from "../../base/input";
import {InputTypes} from "../../../../core/constants/enums";
import {CreateVideoNoteFormKeys} from "../../forms/create-video-note";
import classnames from "classnames";

const formKeys = {
    content: 'content',
}

const schema = Yup.object().shape({
    [formKeys.content]: Yup.string().nullable().required(ValidateMessages.required).min(3, ValidateMessages.min('3')),
})

const validate = makeValidate(schema);
const required = makeRequired(schema);

const VideoNoteCard = ({data, remove, update, seekVideoToTimestamp}) => {
    const [removing, setRemoving] = useState(false);
    const [inEditMode, setInEditMode] = useState(false);
    const [editing, setEditing] = useState(false);
    const [initialFormValues, setInitialFormValues] = useState({});
    const isMounted = useIsMounted();
    const dispatch = useDispatch();
    const formId = useRef(Utils.createUUId(true));
    /**@type {React.MutableRefObject<formApi>}*/
    const formApi = useRef();

    const intractable = !removing && !editing;

    /**
     * With each change in data:
     * - sets the initial form values from the data object.
     */
    useEffect(() => {
        setInitialFormValues({
            [formKeys.content]: data?.content ?? '',
        })
    }, [data])

    /**
     * Prepares the new note object from the given values and invokes the "update" callback.
     *
     * - if the content are only spaces, then returns the required error object.
     * - if the content of the note is not changed, then exists the edit mode
     * - if the api response is successful, restarts the form and exists the edit mote, otherwise returns the error
     * object to the form.
     *
     * @param {Record<string, any>} values
     * @param  {formApi} form
     * @return {Promise<Record<string, any> | void>}
     */
    const updateNote = async (values, form) => {
        if (!values[CreateVideoNoteFormKeys.content].trim()) {
            // if the content only includes spaces, still show required error
            return Promise.resolve({
                [CreateVideoNoteFormKeys.content]: ValidateMessages.required,
            })
        }
        if (Utils.deepEqual(values[CreateVideoNoteFormKeys.content], initialFormValues[CreateVideoNoteFormKeys.content])) {
            // if the content have not changed, then exit edit mode
            setInEditMode(false);
            return Promise.resolve();
        }
        setEditing(true);
        const newNote = {
            ...data,
            content: values[formKeys.content],
        }
        const errors = await update(newNote);
        if (!isMounted()) return;
        setEditing(false);
        if (!errors) {
            // if no errors, then restart the form and exit edit mode
            form.restart();
            setInEditMode(false);
            return Promise.resolve();
        }
        return Promise.resolve(errors);
    }

    /**
     * Removes the note by setting the removing state and calling the remove callback.
     * @return {Promise<boolean>}
     */
    const removeNote = async () => {
        setRemoving(true);
        await remove(data);
        if (!isMounted()) return true;
        setRemoving(false);
        return true;
    }

    /**
     * Awaits for the user confirmation to confirm removing the selected note. Only if they confirm, then we would
     * remove the note.
     */
    const getRemoveConfirmation = () => {
        dispatch(setReduxDialogState(ReduxStoreRoutes.dialogs.confirmation, {
            open: true,
            callback: (confirmed) => ((confirmed && removeNote()) || true),
            title: "Remove Note",
            description: "Are you sure you would want to remove the selected note?",
            proceedText: 'Remove',
        }));
    }

    /**
     * Submits the update form to invoke the "updateNote" function.
     */
    const submitChanges = () => {
        formApi.current.submit().then();
    }

    /**
     * Restarts the form with its initial values and exists the edit mode.
     */
    const revertChanges = () => {
        formApi.current.restart();
        setInEditMode(false);
    }

    return (
        <>
            <div className={'app-card video-note-card'}>
                <div className={'actions'}>
                    <Fade in={!inEditMode}>
                        <Tooltip
                            title={'Seek to timestamp'}>
                            <Button
                                className={'button timestamp'}
                                variant={'text'}
                                onClick={() => intractable && seekVideoToTimestamp(Utils.toSeconds(data?.videoMark ?? ""))}>
                                {data?.videoMark ?? ''}
                            </Button>
                        </Tooltip>

                    </Fade>
                    <div>
                        <Tooltip
                            title={
                                inEditMode
                                    ? editing
                                        ? "Updating..."
                                        : "Save Changes"
                                    : 'Update'
                            }>
                            <IconButton
                                onClick={() => intractable && (
                                    inEditMode
                                        ? submitChanges()
                                        : setInEditMode(true)
                                )}
                                className={'icon-button'}>
                                {
                                    inEditMode
                                        ? !intractable
                                            ? <CircularProgress size={10}/>
                                            : <CheckRounded className={'mui submit'}/>
                                        : <EditIcon className={'custom'}/>
                                }
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title={
                                inEditMode
                                    ? removing
                                        ? "Removing"
                                        : "Revert Changes"
                                    : 'Remove'
                            }>
                            <IconButton
                                onClick={() => intractable && (
                                    inEditMode
                                        ? revertChanges()
                                        : getRemoveConfirmation()
                                )}
                                className={'icon-button'}>
                                {
                                    inEditMode
                                        ? !intractable
                                            ? <CircularProgress size={10}/>
                                            : <CloseRounded className={'mui revert'}/>
                                        : <RemoveIcon className={'custom'}/>
                                }
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <Form
                    className={'row content'}
                    id={formId.current}
                    onSubmit={updateNote}
                    validate={validate}
                    initialValues={initialFormValues}>
                    {({form}) => {
                        if (!formApi.current) {
                            formApi.current = form
                        }
                        return (
                            <Col xs={12}>
                                <Input
                                    form
                                    name={formKeys.content}
                                    type={InputTypes.text}
                                    multiline
                                    spellCheck={inEditMode}
                                    required={required[formKeys.content]}
                                    className={classnames('input', {'view-mode': !inEditMode})}
                                />
                            </Col>
                        );
                    }}
                </Form>
            </div>
        </>
    )
}

export default VideoNoteCard;
