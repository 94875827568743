/*
 *
 */

import React, {useEffect, useLayoutEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import LazyImage from "../../../../components/app-specific/lazy-lmage";
import useRouter from "../../../../hooks/use-router";
import useIsMounted from "../../../../hooks/use-is-mounted";
import {Button} from "@mui/material";
import AppRoutes from "../../../../../core/models/static/routes/app-routes";
import {CoursesQueryParams} from "../../../../../core/constants/query-params";
import VideoCard from "../../../../components/app-specific/video-card";
import {VideoCardTypes} from "../../../../../core/constants/enums";
import classnames from "classnames";
import BizLearn from "../../../../../core/services/api/entities/biz-learn";
import Utils from "../../../../../core/services/utils";
import TryAgain from "../../../../components/app-specific/try-again";

const CourseInformationView = () => {
    const {navigate, params} = useRouter();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const isMounted = useIsMounted();

    /**
     * With each change in data:
     * - renders the underlined title of the course.
     */
    useLayoutEffect(() => {
        renderTitleUnderline();
    }, [data])

    /**
     * With each change in params:
     * - fetches the course information from the server.
     */
    useEffect(() => {
        getCourseInformation().then();
    }, [params])

    /**
     * Fetches the course information from the server with the provided courseId in the url params.
     *
     * * if the api result is successful, sets the data in the internal state.
     * otherwise, navigates to the previous page.
     */
    const getCourseInformation = async () => {
        setLoading(true);
        const response = await BizLearn.getCourseInformation(parseInt(params.courseId));
        if (!isMounted()) return;
        if (!response?.resultFlag) {
            return navigate(-1);
        }
        setLoading(false);
        setData({
            ...(response?.data ?? {}),
            coverImageUrl: (response?.configuration?.courseCoverBaseURL ?? "") + response?.data?.coverImageUrl ?? "",
            videos: response?.data?.videos
                ?.map(video => ({
                    ...video,
                    coverImageUrl: (response?.configuration?.videoCoverBaseURL ?? "") + video.coverImageUrl ?? "",
                    application: response?.data?.application,
                    courseId: response?.data?.id,
                }))
                ?.sort((a, b) => Utils.numComparator(a.playlistIndex, b.playlistIndex)),
            videoCount: response?.data?.videos?.length ?? 0,
            lengthMinute: [...(response?.data?.videos ?? []), {lengthMinute: 0}, {lengthMinute: 0}].reduce((p, c) => p + c.lengthMinute, 0)
        });
    }

    /**
     * Navigates the user to the courses list view with the data's platform as its selected platform's search filter.
     */
    const searchCoursesWithPlatform = () => {
        navigate(AppRoutes.private.courses.list, undefined, {
            [CoursesQueryParams.platform]: data?.application?.id,
        })
    }

    /**
     * Renders the underlined title of the course.
     */
    const renderTitleUnderline = () => {
        const titleElement = document.getElementById('title');
        if (!titleElement) {
            return null;
        }

        const words = data?.title?.split(' ') ?? [];
        if (words.length < 2) {
            titleElement.innerHTML = data?.title ?? "";
            return null;
        }

        titleElement.innerHTML = "";
        let [lastWord, remainingWords] = [words.pop(), words]
        if (['?', '!', '.', ',', ')', ':', ";", '*'].includes(lastWord)) {
            lastWord = remainingWords.pop() + lastWord;
        }

        const inner = document.createElement('p');
        inner.innerHTML = remainingWords.join(' ') +
            `<div class='underline'>
                ${lastWord}
                <div style="background-image: url(${data?.coverImageUrl})" class="inner"></div>
                <div class="inner-bg"></div>
            </p>`;

        titleElement.appendChild(inner);
    }

    return (
        <>
            <div className={classnames('course-information-view', {loading})}>
                <Container>
                    {
                        loading
                            ? <>
                                <div className={'cover'}>
                                    <div/>
                                </div>
                                <div className={'content'}>
                                    {Array(20).fill(null).map((_, e) => (
                                        <div key={e}/>
                                    ))}
                                </div>
                                <div className={'video-title'}>
                                    <div/>
                                </div>
                                <Row>
                                    {
                                        Array(8).fill(null).map((_, e) => (
                                            <Col key={e} xs={12} lg={6}>
                                                <VideoCard loading type={VideoCardTypes.medium}/>
                                            </Col>
                                        ))
                                    }
                                </Row>

                            </>
                            : <>
                                <Row>
                                    <Col xs={12}>
                                        <div className={'cover'}>
                                            <LazyImage
                                                src={data?.coverImageUrl}
                                                alt={data?.title ?? ''}
                                            />
                                            <Container className={'overlay'}>
                                                <div id={'title'} className={'title'}/>
                                                <div className={'spacer'}/>
                                                <div className={'course-info'}>
                                                    <div>
                                            <span>
                                                {data?.videoCount ?? 0}
                                            </span>
                                                        <p>
                                                            {(data?.videoCount ?? 0) === 1 ? 'Video' : "Videos"}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <span>{data?.lengthMinute ?? 0}</span>
                                                        <p>
                                                            {data?.lengthMinute ?? 0 === 1 ? 'Minute' : "Minutes"}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <Button
                                                            className={'button'}
                                                            onClick={searchCoursesWithPlatform}>
                                                            {data?.application?.name ?? ''}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Container>
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <div className={'content'}>
                                            {data?.description ?? ''}
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <p className={'videos-title'}>
                                            Videos
                                        </p>
                                    </Col>
                                    {
                                        !data?.videos?.length
                                            ? <TryAgain
                                                onClick={getCourseInformation}
                                                text={'There are currently no videos in this course. Be sure to' +
                                                    ' check back again.'}
                                                buttonText={'Load again'}
                                            />
                                            : data?.videos?.map(video => (
                                                <Col key={video.id} xs={12} lg={6}>
                                                    <VideoCard data={video} type={VideoCardTypes.medium}/>
                                                </Col>
                                            ))
                                    }
                                </Row>
                            </>
                    }
                    <div className={'spacer'}/>
                </Container>
            </div>
        </>
    )
}

export default CourseInformationView;
