/*
 *
 */

import React, {useMemo} from 'react';
import {BrowserRouter} from "react-router-dom"
import AppMiddlewares from "./ui/middleswares";
import AppViews from "./ui/views";
import {Slide, toast, ToastContainer} from "react-toastify";
import {Provider as ReduxProvider} from "react-redux";
import ReduxStore from "./redux/store";
import useColorTheme from "./ui/hooks/use-color-theme";
import {createTheme, ThemeProvider} from "@mui/material";
import createMuiTheme from "./assets/js/mui-theme";
import {LocalizationProvider} from "@mui/lab";
import DateAdapter from '@mui/lab/AdapterMoment';


const App = () => {
    const [theme] = useColorTheme();
    const muiTheme = useMemo(() => createTheme(createMuiTheme(theme)), [theme])

    return (
        <>
            <ReduxProvider store={ReduxStore}>
                <ThemeProvider theme={muiTheme}>
                    <LocalizationProvider dateAdapter={DateAdapter}>
                        <BrowserRouter>
                            <AppMiddlewares/>
                            <AppViews/>
                        </BrowserRouter>
                    </LocalizationProvider>
                </ThemeProvider>
            </ReduxProvider>
            <ToastContainer
                position={toast.POSITION.TOP_CENTER}
                autoClose={5000}
                theme={theme}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                pauseOnFocusLoss
                draggable
                pauseOnHover
                transition={Slide}
            />
        </>
    )
}

export default App;
