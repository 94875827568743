/*
 *
 */

import {configureStore as CStore} from '@reduxjs/toolkit';
import reducers from './reducers';
import reduxStoreDialogsSlice from "./slices/dialogs/slice";
import ReduxStoreRoutes from "../core/models/static/routes/redux-store-routes";

/**
 * @typedef {import('@reduxjs/toolkit').PayloadAction} PayloadAction
 */

/**
 * Enables Webpack hot module replacement for reducers of the redux store.
 */
const enableWebpackHotModuleForRedux = () => {
    if (!module.hot) return;
    module.hot.accept('./reducers', () => {
        const nextRootReducer = require('./reducers');
        ReduxStore.replaceReducer(nextRootReducer);
    });
}

/**
 * Configures the redux store and makes it ready for injection in react.
 */
const configureStore = (initialState) => {
    // noinspection JSUnresolvedVariable
    const store = CStore({
        reducer: reducers,
        preloadedState: initialState,
        devTools: true,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [
                        reduxStoreDialogsSlice.actions.setDialogState.type,
                    ],
                    ignoredPaths: [
                        ReduxStoreRoutes.dialogs.base,
                    ],
                }
            }).concat([]),
    })
    enableWebpackHotModuleForRedux();
    return store;
}

/**
 * The Singleton State of the redux store
 */
const ReduxStore = configureStore();

export default ReduxStore;
