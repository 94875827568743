/*
 *
 */

import {ColorThemes} from "../../constants/enums";
import {_Colors, DarkColors, LightColors} from "./colors";
import Utils from "../utils";
import CacheService from "../cache/cache-service";
import InAppModels from "../../models/in-app";
import EnvService from "../env-service";
import ScssGenerator from "./scss-generator";
import ColorInjector from "./color-injector";

/**
 * The Theme Proxy handler that static properties are given based on the current theme value.
 */
class _ColorThemeService {
    public theme: string;
    private readonly light: _Colors;
    private readonly dark: _Colors;
    private changeState: InAppModels.ColorThemeChangeState;

    /**
     * Creates the Theme object that is to be used as the Proxy handler of the color-theme service.
     */
    constructor() {
        this.changeState = {changing: false, theme: null};
        // prevents the color transition of the view on the initial render.
        _ColorThemeService._toggleColorTransition(false);
        this.theme = this.getColorTheme();
        this.light = new LightColors();
        this.dark = new DarkColors();
    }

    /**
     * Toggles the color transition property of the document.
     *
     * if set to true, it will still return to false after the specified delay. It is undesirable to have
     * set the transition properties to colors for all the elements which in turn interferes with their actual
     * transition property.
     * @param {boolean} value
     * @param {number} delay
     * @private
     */
    private static _toggleColorTransition(value: boolean = true, delay = 500) {
        const _remove = () => document.documentElement.removeAttribute('transition');
        if (value) {
            document.documentElement.setAttribute('transition', "none");
            return setTimeout(_remove, delay)
        }
        _remove();
    }

    /**
     * Determines if the theme of this entity is in the process of changing.
     * @param {string} theme the new theme to be checked against the changeState.
     * @return {boolean} True if changing else False
     * @private
     */
    private _isChangingStateTheme(theme: string): boolean {
        return this.changeState.changing && this.changeState.theme === theme;
    }

    /**
     * Gets the property of the target.
     *
     * This method is overloaded so that the color name is fetched from the appropriate class based on the selected
     * theme.
     * @param target
     * @param {string} property
     * @return {*}
     */
    get(target: any, property: string): any {
        switch (property) {
            case "prototype":
            case "name":
                // @ts-ignore
                return this.light[property] || this.dark[property];
            case "theme":
                return this.theme;
            case "injectColorThemes":
                return this.injectColorThemes.bind(this);
            case "getColorTheme":
                return this.getColorTheme.bind(this);
            case "setColorTheme":
                return this.setColorTheme.bind(this);
            default:
                const entity = this.theme === ColorThemes.dark ? this.dark : this.light;
                // @ts-ignore
                return entity[property];
        }
    }

    /**
     * Injects the color themes of the application into the app to be used in the scss files.
     *
     * if [EnvValues.OutputScssVariables] is set to true, then also generated the color theme variables and outputs
     * them in the info section of the console for accessibility.
     */
    injectColorThemes(): void {
        const lightTheme = this.light.injectTheme(true) ?? [];
        const darkTheme = this.dark.injectTheme(true) ?? [];
        const theme = [lightTheme, darkTheme];
        if (EnvService.OutputScssVariables) {
            console.log(ScssGenerator.generateColorThemeVariables(ColorInjector.prefix, ColorInjector.suffixes, theme))
        }
        Utils.addStylesheetRules(theme);
    }

    /**
     * Fetches the current color theme of the website form the local storage.
     *
     * - if the current color theme does not exist in LocalStorage, then fetches it from the matchMedia of the window
     * and then invokes setColorTheme method.
     * @return {string} color theme.
     */
    getColorTheme(): string {
        let theme = CacheService.getColorTheme();
        if (theme !== this.theme) {
            theme = this.setColorTheme(theme);
        } else {
            document.documentElement.setAttribute('data-theme', theme);
            document.documentElement.style.setProperty("color-scheme", theme);
        }
        return theme;
    }

    /**
     * Fetches the current color theme of the website form the local storage.
     *
     * @param {string} theme the new theme.
     * @param {boolean} withDispatch whether to dispatch an event or not.
     * @param {boolean} withTransition whether to arable documentation transition property. Only needs to be true
     * when the mode changes inside the app.
     * @param {boolean} ignorable whether it can be ignored since another party is already setting the same color theme.
     * @return {boolean} whether the set operation was successful.
     */
    setColorTheme(theme: string = '',
                  {
                      withDispatch = false,
                      withTransition = false,
                      ignorable = false,
                  }: InAppModels.SetColorThemeOptions | Record<string, any> = {}): string {
        theme = CacheService.setColorTheme(theme);
        // if already applying color and can be ignored just return the same theme since it is going to be applied
        // anyway.
        if (this._isChangingStateTheme(theme) && ignorable) {
            return theme;
        }
        this.changeState = {theme: theme, changing: true};
        document.documentElement.style.setProperty("color-scheme", theme)
        document.documentElement.setAttribute('data-theme', theme);
        // return if the theme is the same because of multi use-color-theme hook calls
        if (theme === this.theme) {
            return theme;
        }
        // to re-render the components using use-color-theme hook;
        if (withDispatch) {
            window.dispatchEvent(new Event('storage'))
        }
        // to enable color transition of elements
        if (withTransition) {
            _ColorThemeService._toggleColorTransition(true);
        }
        this.theme = theme;
        this.changeState = {theme: null, changing: false};
        return theme;
    }
}

const ColorThemeService: _Colors & _ColorThemeService = new Proxy({}, new _ColorThemeService())

export default ColorThemeService;
